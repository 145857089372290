import { UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import { fetchUser } from 'store/user'
import { setSubroute } from 'store/navbar'
import { setPageTitle } from 'utils/misc'
import { fetchStudy } from '../../../../../CreateStudy/modules/CreateStudy'
import EmbeddedInsights from './container/EmbeddedInsightsContainer'

// Sync route definition
export default store => ({
  path: '/studies/:studyID/compliance/iframe/:embed_code/:token',
  exact: true,
  getIndexRoute(location, cb) {
    store.dispatch(setSubroute('iframe'))
    cb(null, {
      getComponent(nextState, _cb) {
        require.ensure(
          [],
          () => {
            const { params } = nextState
            const { studyID } = params
            const { currentStudy } = store.getState().study
            const { config } = currentStudy
            store.dispatch(fetchStudy(studyID))

            store.dispatch(setSubroute(config?.insights ? 'insights' : 'compliance'))
            store.dispatch(fetchUser())
            setPageTitle(`Embedded Insights | Study ${studyID} | ${currentStudy.platform_name}`)

            _cb(
              null,
              UserIsAuthenticated(
                UserHasValidPassword(UserHasScope(['ra', 'rp', 'rd'], EmbeddedInsights, false, studyID)),
              ),
            )
          },
          'studies/:studyID/compliance/iframe/:embed_code/:token',
        )
      },
    })
  },
})
