import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Popup, Radio } from 'components/UIElements'
import { arraysContainSameElements } from 'utils/misc'
import { USER_ROLE_ID_MAP, CLINICIAN_ROLES_ID_LIST } from 'utils/constants'

/* TODO: in the future, when role permission setting UI is finalized
 * (potentially with the autocompleteseach component), we will utilize
 * the userRoles slice of instrument state to populate the available roles
 * that to which we can set instrument permissions
 */

const { clinRoII, clinRoIII, clinicalCoordinator, impartialWitness } = USER_ROLE_ID_MAP

const InstrumentPermissionSelector = ({ updateInstrumentRoles, instrumentRoles }) => {
  useEffect(() => {
    if (!instrumentRoles) updateInstrumentRoles(CLINICIAN_ROLES_ID_LIST)
    else if (!instrumentRoles.includes(clinicalCoordinator)) {
      updateInstrumentRoles([...instrumentRoles, clinicalCoordinator])
    }
  }, [])
  return (
    <div className='permission-selector'>
      <div className='flexed start-justified'>
        <span>Allow these clinicians to complete instrument</span>
        <Popup hover noPointer dark position='right' trigger={<i className='fas fa-info-circle' />}>
          <p>
            This instrument will be visible to all clinicians, but it will only be accessible to clinicians with the
            specified permission level.
          </p>
        </Popup>
      </div>
      <div className='flexed role-radios'>
        <Radio
          selected={arraysContainSameElements(instrumentRoles, CLINICIAN_ROLES_ID_LIST)}
          onClick={() => updateInstrumentRoles(CLINICIAN_ROLES_ID_LIST)}
          content='All clinicians (Level III, II & I)'
        />
        <Radio
          selected={arraysContainSameElements(instrumentRoles, [
            clinRoII,
            clinRoIII,
            clinicalCoordinator,
            impartialWitness,
          ])}
          onClick={() => updateInstrumentRoles([clinRoII, clinRoIII, clinicalCoordinator, impartialWitness])}
          content='Clinician Level III & II'
        />
        <Radio
          selected={arraysContainSameElements(instrumentRoles, [clinRoIII, clinicalCoordinator, impartialWitness])}
          onClick={() => updateInstrumentRoles([clinRoIII, clinicalCoordinator, impartialWitness])}
          content='Clinician Level III only'
        />
      </div>
    </div>
  )
}

InstrumentPermissionSelector.propTypes = {
  updateInstrumentRoles: PropTypes.func,
  instrumentRoles: PropTypes.arrayOf(PropTypes.number),
}

export default InstrumentPermissionSelector
