import React from 'react'
import PropTypes from 'prop-types'
import { DocumentList, Loader, SearchInput } from 'components/UIElements'
import { getStrictRegexFromTerm } from 'utils/search'
import STRINGS from 'utils/strings'

const SEARCHABLE_KEYS = ['id']

const DataInboxPage = props => {
  const { loading, dataInboxMaterial = {}, fetchDataInboxUrl } = props
  const { data_inbox: dataInbox = [] } = dataInboxMaterial

  const getFilteredList = list => {
    const { searchTerm } = props
    const searchKeys = SEARCHABLE_KEYS
    if (!searchTerm) return list
    const regex = getStrictRegexFromTerm(searchTerm)
    return list.filter(item => {
      for (let i = 0; i < searchKeys.length; i++) {
        const key = searchKeys[i]
        const value = String(item?.[key])
        if (value?.match(regex)) {
          return true
        }
      }
      return false
    })
  }

  return (
    <div className='training data-inbox page'>
      <div className='flexed-header end-justified'>
        <SearchInput placeholder={STRINGS.searchEllipsis} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className='bold'>{STRINGS.dataInbox}</h4>
          <DocumentList
            list={getFilteredList(dataInbox)}
            onDownload={item => {
              fetchDataInboxUrl(item?.id)
            }}
            onOpen={item => {
              fetchDataInboxUrl(item?.id)
            }}
            noList={!dataInbox.length}
            emptyText={STRINGS.noDataInbox}
            showId
          />
        </>
      )}
    </div>
  )
}

DataInboxPage.propTypes = {
  canWrite: PropTypes.bool,
  deleteTrainingMaterial: PropTypes.func,
  loading: PropTypes.bool,
  minimizeOverlay: PropTypes.func,
  openOverlay: PropTypes.func,
  resetTraining: PropTypes.func,
  searchTerm: PropTypes.string,
  studyId: PropTypes.number,
  dataInboxMaterial: PropTypes.shape({ data_inbox: PropTypes.array }),
}

export default DataInboxPage
