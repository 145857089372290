import React from 'react'
import { connect } from 'react-redux'

import EmbeddedInsights from '../components/EmbeddedInsights'

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps
  const { studyID: study_id, token, embed_code } = params
  const studyID = Number(study_id)
  const src = `https://us.holistics.io/embed/${embed_code}?_token=${token}`

  return {
    studyID,
    src,
  }
}

export default connect(mapStateToProps)(props => {
  return <EmbeddedInsights {...props} />
})
