import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Container,
  Input,
  DatePicker,
  Dropdown,
  Button,
  Checkbox,
  AutocompleteSearch,
  PhoneNumberInput,
} from 'components/UIElements'
import {
  MODAL_CONTENT_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  DATE_FORMAT_MAP,
  INPUT_DEBOUNCE,
} from 'utils/constants'
import STRINGS from 'utils/strings'
import { FORM_FIELDS as formFields } from '../modules/CreateParticipant'
import SubjectIdInput from './SubjectIdInput'

const ParticipantForm = ({
  checkedSites,
  closeModal,
  disabled,
  editable,
  hasSubjectIdValidation,
  isCreate,
  leafSites,
  locales,
  openModal,
  participant,
  resetCheckedSites,
  siteSelectionList = [],
  toggleSite,
  triggerEditable,
  triggerPasswordReset,
  validateSubjectId,
  updateField,
  needToReassign,
  canWrite,
  participantDataFields,
  sitesLanguages,
  studyId,
  isSubjectIdAvailable,
  resetSubjectIdStatus,
  participantId,
}) => {
  useEffect(() => {
    updateField('default_locale', null)
  }, [])

  const [canUpdateMap, setCanUpdateMap] = useState()
  const [subjectId, setSubjectId] = useState()

  useEffect(() => {
    if (participantDataFields) {
      const { mandatory, optional, disabled: disabledFields } = participantDataFields
      const newMap = {}
      const fieldsArr = [...mandatory, ...optional, ...disabledFields]
      fieldsArr.forEach(field => {
        newMap[field.metadata.internal_key] = field.metadata.can_update
      })
      setCanUpdateMap(newMap)
    }
  }, [])

  const onToggleSite = (siteID, pathName, treeLevel) => {
    resetCheckedSites()
    toggleSite(siteID, pathName, treeLevel)
    updateField('default_locale', null)
  }
  const onPasswordReset = () => {
    const { username, id } = participant
    openModal({
      content: MODAL_CONTENT_MAP.participantNewPassword,
      confirmButton: MODAL_BUTTONS_MAP.confirm,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm: () => {
        triggerPasswordReset({ username, id })
        closeModal()
      },
    })
  }

  const list = siteSelectionList.map(row => {
    const item = { key: row[0].value, text: row[1].value, treeLevel: row[2].value, path: row[3].value }
    return item
  })

  useEffect(() => {
    if (hasSubjectIdValidation) {
      const timeoutId = setTimeout(() => {
        if (studyId && subjectId) {
          validateSubjectId({ studyId, subjectId, participantId })
        }
      }, INPUT_DEBOUNCE)
      return () => clearTimeout(timeoutId)
    }
    resetSubjectIdStatus()
  }, [subjectId, hasSubjectIdValidation])

  const getFormattedHint = (key, fieldHint = '') => {
    if (participantDataFields) {
      const { optional: optionalPtpFields } = participantDataFields
      const isOptional = !!optionalPtpFields.find(field => field.metadata.internal_key === key)
      const isLocale = key === 'default_locale'
      /**
       * Default locale will never be optional even if it can be set as optional in a study config.
       * As a result, we will not display the "optional" text for the locale field.
       */
      if (isOptional && !isLocale) {
        return fieldHint ? `${fieldHint} (${STRINGS.optional})` : STRINGS.optional
      }
    }
    return fieldHint
  }

  const renderField = fieldProps => {
    const { type, key, label, options, placeholder, canUpdate = true, hint: fieldHint } = fieldProps
    const value = participant[key]
    const isSiteDisabled = disabledFields
      ? disabledFields.map(data => data.metadata.internal_key).includes('site_id')
      : false
    const notEditableAfterCreation = !isCreate && !canUpdate
    const fieldDisabled = !editable || disabled || notEditableAfterCreation
    const hint = getFormattedHint(key, fieldHint)

    switch (type) {
      case 'number':
      case 'password':
      case 'text':
        return (
          <>
            {key === 'subject_id' ? (
              <SubjectIdInput
                inputKey={key}
                type={type}
                label={label}
                value={value}
                updateField={updateField}
                placeholder={placeholder}
                setSubjectId={setSubjectId}
                fieldDisabled={fieldDisabled}
                isSubjectIdAvailable={isSubjectIdAvailable}
                resetSubjectIdStatus={resetSubjectIdStatus}
              />
            ) : (
              <Input
                disabled={fieldDisabled}
                label={label}
                type={type}
                onChange={val => updateField(key, val)}
                value={type === 'password' && !isCreate ? 'XXXXXX' : value}
                id={`participant-form-${key}`}
                placeholder={placeholder}
              />
            )}
            {hint && <span className='hint'>{hint}</span>}
          </>
        )
      case 'check':
        return (
          <div className='flexed column start-justified start-aligned'>
            <Checkbox
              toggle
              label={label}
              disabled={fieldDisabled}
              checked={value}
              id={`participant-form-${key}`}
              onClick={() => updateField(key, !value)}
            />
            {hint && <span className='hint'>{hint}</span>}
          </div>
        )
      case 'date':
        return (
          <>
            <label className='label-small'>{label}</label>
            <DatePicker
              disabledDays={{ after: new Date() }}
              disabled={fieldDisabled}
              initialDate={value ? moment(value, DATE_FORMAT_MAP.datePicker) : value}
              onDayChange={(date, options) =>
                updateField(key, date ? date.format(DATE_FORMAT_MAP.datePicker) : options.state.typedValue)
              }
              id={`participant-form-${key}`}
            />
            {hint && <span className='hint'>{hint}</span>}
          </>
        )
      case 'select': {
        const isLocale = key === 'default_locale'

        if (isLocale) {
          const selectedSites = Object.keys(checkedSites)
          const siteIsSelected = selectedSites.length
          const selectedSitesLanguages = selectedSites
            .filter(siteId => sitesLanguages[siteId])
            .map(siteId => sitesLanguages[siteId]?.enforced_language?.languages)
            .filter(item => item)
            .flat()

          // Options will default to locales options if no options are provided from module/CreateParticipant.js
          const dropdownOptions = selectedSitesLanguages.length ? selectedSitesLanguages : locales
          return (
            <div className='site-locale-wrapper'>
              {!isSiteDisabled && (
                <>
                  <p className='label-small sites flexed center-aligned start-justified'>
                    {STRINGS.site}
                    {needToReassign && (
                      <span className='flexed'>
                        <i className='fas fa-exclamation-circle' />
                        {STRINGS.reassignToLeafSite}
                      </span>
                    )}
                  </p>
                  <AutocompleteSearch
                    checked={checkedSites}
                    disabled={!editable || disabled || notEditableAfterCreation}
                    filter={leafSites}
                    initialValue={checkedSiteName}
                    list={list}
                    id='participant-site'
                    noInitialValue={isCreate}
                    placeholder={STRINGS.selectSite}
                    toggleItem={onToggleSite}
                  />
                </>
              )}
              <label className='label-small'>{label}</label>
              <Dropdown
                disabled={fieldDisabled || !siteIsSelected}
                className='select-dropdown'
                selected={siteIsSelected ? value : undefined}
                options={dropdownOptions}
                onSelect={val => updateField(key, val.id)}
                id={`participant-form-${key}`}
                placeholder={siteIsSelected ? STRINGS.selectLocale : STRINGS.selectSiteFirst}
              />
              {hint && <span className='hint'>{hint}</span>}
            </div>
          )
        }

        return (
          <>
            <label className='label-small'>{label}</label>
            <Dropdown
              disabled={fieldDisabled}
              className='select-dropdown'
              selected={value}
              options={options}
              onSelect={val => updateField(key, val.value)}
              id={`participant-form-${key}`}
            />
            {hint && <span className='hint'>{hint}</span>}
          </>
        )
      }
      case 'phone':
        return (
          <div className='phone'>
            <p className='label-small'>{label}</p>
            <PhoneNumberInput
              disabled={fieldDisabled}
              onChange={val => updateField(key, val)}
              onCountryChange={() => updateField(key, '')}
              value={value}
              id='participant-phone-number'
            />
            {hint && <span className='hint'>{hint}</span>}
            <p className='disclaimer'>
              By entering this mobile phone number, I verify that the participant has agreed to use this mobile phone
              number for the purpose of the study and that the participant has given me permission to enter this mobile
              phone number.
            </p>
          </div>
        )
      default:
        return null
    }
  }

  const checkedSiteInfo = siteSelectionList.filter(site => site[0].value == Object.keys(checkedSites)[0])[0]
  const checkedSiteName = checkedSiteInfo && checkedSiteInfo.length ? checkedSiteInfo[1].value : ''
  const hasLoggedIn = !!participant.first_login_timestamp

  const disabledFields = participantDataFields?.disabled

  const fields = disabledFields
    ? formFields.filter(field => !disabledFields.map(data => data.metadata.internal_key).includes(field.key))
    : formFields

  return (
    <Container className='participant-form' centered flex column>
      <div className='title'>Participant Details</div>
      <span className='hint'>All fields are required unless marked as optional.</span>
      {participant.username && <h5>{participant.username}</h5>}
      {fields.map(field => {
        const { type, key, text, options, placeholder, hint } = field
        return (
          <div key={field.key}>
            {renderField({ type, key, label: text, options, placeholder, canUpdate: canUpdateMap?.[field.key], hint })}
          </div>
        )
      })}
      {!isCreate && editable && canWrite && hasLoggedIn && (
        <div className='reset-password flexed'>
          <span>{`Last password reset: ${moment(participant.last_updated).format(DATE_FORMAT_MAP.main)}`}</span>
          <Button link onClick={onPasswordReset}>
            Reset Password
          </Button>
        </div>
      )}
      {!editable && !isCreate && disabled && (
        <Button link content='Edit' icon='fas fa-pencil-alt' className='edit-form-button' onClick={triggerEditable} />
      )}
    </Container>
  )
}

ParticipantForm.propTypes = {
  checkedSites: PropTypes.object,
  closeModal: PropTypes.func,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  isCreate: PropTypes.bool,
  canWrite: PropTypes.bool,
  hasSubjectIdValidation: PropTypes.bool,
  leafSites: PropTypes.arrayOf(PropTypes.number),
  locales: PropTypes.array,
  openModal: PropTypes.func,
  participant: PropTypes.object,
  resetCheckedSites: PropTypes.func,
  siteSelectionList: PropTypes.arrayOf(PropTypes.array),
  toggleSite: PropTypes.func,
  triggerEditable: PropTypes.func,
  triggerPasswordReset: PropTypes.func,
  updateField: PropTypes.func,
  needToReassign: PropTypes.bool,
  participantDataFields: PropTypes.object,
}

export default ParticipantForm
