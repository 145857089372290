import React from 'react'
import { connect } from 'react-redux'
import { Checkbox } from 'components/UIElements'
import { itemActions } from '../modules/Survey'
import STRINGS from 'utils/strings'
import NutritionalValueReview from './FoodEntry/NutritionalValueReview'

const FoodEntryView = props => {
  const { item, updateItem, itemId, errors, clearQuestionError } = props
  const foodDataReview = item?.attributes?.food_data_review
  const foodDataFields = item?.attributes?.food_data_fields

  const updateFoodDataFieldValue = field => {
    const newItem = {
      ...item,
      attributes: {
        ...item.attributes,
        food_data_fields: {
          ...item?.attributes?.food_data_fields,
          [field]: !item?.attributes?.food_data_fields?.[field],
        },
      },
    }
    updateItem(itemId, newItem)

    if (errors.blankFoodDataFields) {
      clearQuestionError(itemId)
    }
  }

  return (
    <div className='food-entry'>
      <div className='food-entry-subtitle'>{STRINGS.pleaseConfigureVisualRepresentation}</div>
      <NutritionalValueReview {...props} />
      <div className='food-entry-options'>
        <div
          className={`food-entry-option ${!foodDataReview ? 'disabled' : ''} ${
            errors?.blankFoodDataFields ? 'error' : ''
          }`}>
          <Checkbox
            disabled={!foodDataReview}
            checked={!!foodDataFields?.carbohydrates}
            onClick={() => updateFoodDataFieldValue('carbohydrates')}
          />
          <span>{STRINGS.carbohydrates}</span>
        </div>

        <div
          className={`food-entry-option ${!foodDataReview ? 'disabled' : ''} ${
            errors?.blankFoodDataFields ? 'error' : ''
          }`}>
          <Checkbox
            disabled={!foodDataReview}
            checked={!!foodDataFields?.fats}
            onClick={() => updateFoodDataFieldValue('fats')}
          />
          <span>{STRINGS.fats}</span>
        </div>

        <div
          className={`food-entry-option ${!foodDataReview ? 'disabled' : ''} ${
            errors?.blankFoodDataFields ? 'error' : ''
          }`}>
          <Checkbox
            disabled={!foodDataReview}
            checked={!!foodDataFields?.fiber}
            onClick={() => updateFoodDataFieldValue('fiber')}
          />
          <span>{STRINGS.fiber}</span>
        </div>

        <div
          className={`food-entry-option ${!foodDataReview ? 'disabled' : ''} ${
            errors?.blankFoodDataFields ? 'error' : ''
          }`}>
          <Checkbox
            disabled={!foodDataReview}
            checked={!!foodDataFields?.proteins}
            onClick={() => updateFoodDataFieldValue('proteins')}
          />
          <span>{STRINGS.proteins}</span>
        </div>

        <div
          className={`food-entry-option ${!foodDataReview ? 'disabled' : ''} ${
            errors?.blankFoodDataFields ? 'error' : ''
          }`}>
          <Checkbox
            disabled={!foodDataReview}
            checked={!!foodDataFields?.calories}
            onClick={() => updateFoodDataFieldValue('calories')}
          />
          <span>{STRINGS.calories}</span>
        </div>
      </div>
    </div>
  )
}

export default connect(null, itemActions)(FoodEntryView)
