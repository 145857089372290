import { useEffect, useState } from 'react'

const WIDGET_KEY_MAP = {
  study: 'study_widget_id',
  site: 'site_widget_id',
}

const SISENSE_LOADING_ERR = 'An error has occurred loading the Insights widget, please reload the page.'

const removeInsightsScript = () => {
  const oldEle = document.getElementById('SISENSE_SCRIPT')
  if (oldEle) oldEle.remove()
}

function loadSisenseEmbedSDK({ sisenseUrl, async = true, setSisenseLoadingErr }) {
  return new Promise((resolve, reject) => {
    try {
      removeInsightsScript()

      const embedSdkScriptEle = document.createElement('script')

      embedSdkScriptEle.setAttribute('src', sisenseUrl)
      embedSdkScriptEle.setAttribute('type', 'text/javascript')
      embedSdkScriptEle.setAttribute('async', async)
      embedSdkScriptEle.setAttribute('id', 'SISENSE_SCRIPT')

      document.body.appendChild(embedSdkScriptEle)

      embedSdkScriptEle.addEventListener('load', () => {
        resolve({ loaded: true })
      })

      embedSdkScriptEle.addEventListener('error', () => {
        setSisenseLoadingErr(SISENSE_LOADING_ERR)
        return reject(new Error(`Failed to load the script ${sisenseUrl}`))
      })
    } catch (e) {
      // reject(e)
    }
  })
}

async function loadSisense({ setSisenseLoadingErr, sisenseUrl }, setSisenseFrame) {
  const sisenseLoaded = await loadSisenseEmbedSDK({ sisenseUrl, setSisenseLoadingErr })
  if (sisenseLoaded.loaded) {
    setSisenseFrame(true)
  }
}

export const loadFrame = ({ url, insightsModule, isStudyAdmin, frameKey, setFrameLoading, studyID }) => {
  const widgetKey = isStudyAdmin ? WIDGET_KEY_MAP.study : WIDGET_KEY_MAP.site
  const { SisenseFrame, enums } = window['sisense.embed']

  const sisenseFrame = new SisenseFrame({
    url,
    dashboard: insightsModule.dashboard_id,
    widget: insightsModule[widgetKey],
    settings: {
      showToolbar: false,
      showLeftPane: false,
      showRightPane: false,
    },
    element: document.getElementById(`insights-iframe-${frameKey}`),
  })

  const dashboardLoadedHandler = () => {
    if (!studyID) sisenseFrame.dashboard.clearFilters(true)
    else {
      const newFilters = [
        {
          disabled: false,
          jaql: {
            datatype: 'text',
            dim: '[dim_studies.id_name]',
            title: 'id_name',
            filter: {
              contains: `${studyID}`,
            },
          },
        },
      ]
      sisenseFrame.dashboard.applyFilters(newFilters, true)
    }
  }

  // Calling render() will apply the above configuration to the existing iFrame element
  sisenseFrame
    .render()
    .then(() => {
      if (__DEV__) console.log(`Insights Frame ${frameKey} ready!`)
      setFrameLoading(false)
      // Handle dashboard load event
      sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, dashboardLoadedHandler)
    })
    .catch(e => {
      setFrameLoading(false)
      if (__DEV__) console.log(e)
    })
}

const useSisense = props => {
  const [sisenseLoaded, setSisenseLoaded] = useState(false)
  useEffect(() => {
    loadSisense(props, setSisenseLoaded)
    return () => {
      removeInsightsScript()
    }
  }, [])
  return sisenseLoaded
}

export default useSisense
