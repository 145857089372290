import React from 'react'
import { Container } from 'components/UIElements'

import '../stylesheets/EmbeddedInsights.scss'

const EmbeddedInsights = ({ src }) => {
  return (
    <div className='embedded-insights page'>
      <Container className='embedded-insights-container'>
        <h1>Dashboard name</h1>
        <iframe className='embedded-iframe' src={src} id='embedded-iframe' allowFullScreen />
      </Container>
    </div>
  )
}

export default EmbeddedInsights
