import React from 'react'
import PropTypes from 'prop-types'
import { Popup, Checkbox } from 'components/UIElements'
import STRINGS from 'utils/strings'

function NutritionalValueReview(props) {
  const { disabled, item, itemId, updateItem, errors, clearQuestionError } = props

  const updateNutritionalValue = () => {
    if (item.attributes.food_data_review && errors.blankFoodDataFields) {
      clearQuestionError(itemId)
    }

    const itemAttributes = item.attributes

    const newItem = {
      ...item,
      attributes: {
        ...item.attributes,
        food_data_review: !itemAttributes.food_data_review,
        food_data_fields: itemAttributes.food_data_review
          ? {
              carbohydrates: false,
              fats: false,
              fiber: false,
              proteins: false,
              calories: false,
            }
          : itemAttributes.food_data_fields,
      },
    }
    updateItem(itemId, newItem)
  }

  return (
    <div className='nutritional-value'>
      <div className='nutritional-value-toggle'>
        <Checkbox
          disabled={disabled}
          toggle
          checked={!!item.attributes.food_data_review}
          onClick={updateNutritionalValue}
        />
        <span>{STRINGS.foodNutritionalReview}</span>
        <Popup
          className='nutritional-value-popup'
          align={'left'}
          position='bottom'
          hover
          noPointer
          dark
          trigger={<i className='fas fa-info-circle' />}>
          {<p>{STRINGS.foodNutritionalReviewPopup}</p>}
        </Popup>
      </div>
    </div>
  )
}

NutritionalValueReview.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    attributes: PropTypes.shape({
      food_data_review: PropTypes.bool,
    }),
  }),
  itemId: PropTypes.string,
  updateItem: PropTypes.func,
}

export default NutritionalValueReview
