import React from 'react'
import PropTypes from 'prop-types'
import { Button, SortableTable, Popup, PercentMeter, SearchInput, StickyHeader } from 'components/UIElements'
import { browserHistory } from 'react-router'
import { calculateOffsets, capitalize } from 'utils/misc'
import { INSTRUMENT_TYPE_MAP } from 'utils/constants'
import UploadTranslationInput from './UploadTranslationInput'
import '../stylesheets/Instruments.scss'
import InstrumentPreview from '../../Instrument/routes/InstrumentPreview/containers/PreviewContainer'
import DataDownloadPopup from './DataDownloadPopup'
import { getIdxOfData } from '../../../../../../../../../utils/instrument'
import hasInstrumentFeatures from '../../../utils/hasInstrumentFeatures'
import STRINGS from 'utils/strings'
import InstrumentsViewButtonList from './InstrumentsViewButtonList'

class InstrumentsView extends React.Component {
  constructor(props) {
    super(props)
    this.headerOptions = [
      { key: 'name', sortable: true, component: 'Instrument Name' },
      { key: 'type', sortable: true, component: 'Type' },
      { key: 'receipt', sortable: true, component: 'Number Received/Sent' },
      { key: 'lastEdited', sortable: true, component: 'Last Edited' },
      { key: 'scheduleStatus', sortable: true, component: 'Date Scheduled' },
      { key: 'action', component: 'Action' },
    ]
    this.renderFunctions = {
      name: this.renderName.bind(this),
      receipt: this.renderReceipt.bind(this),
      action: this.renderAction.bind(this),
      type: this.renderType.bind(this),
      scheduleStatus: this.renderScheduleStatus.bind(this),
    }
    this.state = {
      checked: {},
      offsets: null,
      deleting: {},
    }
  }

  componentDidMount() {
    const {
      resetInstrument,
      hasVisitSupport,
      canViewVisits,
      fetchVisitTemplates,
      studyID,
      instrumentAdvancedOptions,
      resetAdvancedOptions,
    } = this.props
    this.setState({ offsets: calculateOffsets('sticky') })
    resetInstrument('TASK')
    if (hasVisitSupport && canViewVisits) fetchVisitTemplates(studyID)

    // Clear persisted instrumentAdvancedOptions from redux store when Instruments table page is opened
    if (!!Object.keys(instrumentAdvancedOptions).length) {
      resetAdvancedOptions()
    }
  }

  onDownloadTranslations = translation => {
    const { downloadTranslations, studyID } = this.props
    let { checked } = this.state
    checked = Object.keys(checked).length ? checked : translation
    downloadTranslations(studyID, checked)
  }

  onDelete = id => {
    const { deleteInstrument, studyID } = this.props
    const { checked } = this.state

    const clearChecked = () => {
      this.setState(prev => {
        const prevState = {
          ...prev,
          deleting: { ...checked },
        }
        if (!prevState.deleting[id]) prevState.deleting[id] = true
        return prevState
      })
    }
    return e => {
      e.stopPropagation()
      deleteInstrument(studyID, id || Object.keys(checked), clearChecked, () => {
        this.onUpdateChecked({})
        this.onUpdateDeleting({})
      })
    }
  }

  onUpdateChecked = checked => {
    this.setState({ checked })
  }

  onUpdateDeleting = deleting => {
    this.setState({ deleting })
  }

  onRowClick = row => {
    const { resetInstrument, openDrawer, closeDrawer, studyID } = this.props
    const receiptIdx = getIdxOfData(row, 'receipt')
    const receiptValue = receiptIdx !== null ? row[receiptIdx].value : {}
    const { num_received, num_sent } = receiptValue
    const responseRate = Math.floor((num_received / num_sent) * 100)
    const typeIdx = getIdxOfData(row, 'type')
    const type = typeIdx !== null ? row[typeIdx].value : ''
    const isDiary = type === INSTRUMENT_TYPE_MAP.diary
    const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
    const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
    const isArticle = type === INSTRUMENT_TYPE_MAP.article

    const getButtonContent = () => {
      if (isClinro) {
        return 'instrument'
      }
      if (isSurvey || isDiary) {
        return STRINGS.participantInstrument
      }
      if (isDiary) {
        return 'diary'
      }
      return type.toLowerCase()
    }

    const getType = () => {
      if (isDiary) {
        return 'diary'
      }
      if (isSurvey) {
        return 'instrument'
      }
      return type.toLowerCase()
    }

    const buttonContent = getButtonContent()

    openDrawer({
      closeOnBackgroundClick: true,
      onCancel: () => {
        resetInstrument()
        closeDrawer()
      },
      content: (
        <InstrumentPreview
          {...this.props}
          studyID={studyID}
          instrumentID={row.id}
          inDrawer
          isSurvey={isSurvey}
          isArticle={isArticle}
          isClinro={isClinro}
          isDiary={isDiary}
          link={`/studies/${studyID}/instruments/${row.id}/edit${getType()}`}
          responseRate={responseRate}
          numSent={num_sent}
          buttonContent={capitalize(buttonContent)}
        />
      ),
    })
  }

  renderType(row, idx) {
    const getValue = () => {
      if (row.value === INSTRUMENT_TYPE_MAP.survey || row.value === INSTRUMENT_TYPE_MAP.diary) {
        return 'linkt'
      }
      return row.value.toLowerCase()
    }

    return (
      <td className='type-cell' key={`row_${idx}`}>
        <p>{getValue()}</p>
      </td>
    )
  }

  renderReceipt(row, idx) {
    return (
      <td className='receipt-cell' key={`row_${idx}`}>
        <PercentMeter invariableColor hideNum ratio={row.sortValue} />
        <p>{`${row.value.num_received}/${row.value.num_sent} (${Math.floor(row.sortValue * 100)}%)`}</p>
      </td>
    )
  }

  renderScheduleStatus(row, idx) {
    const { value, visitId } = row
    const { visitNames } = this.props
    const visitName = visitNames?.[visitId]
    const text = `${value}${visitId ? ` (${visitName || visitId})` : ''}`
    return (
      <td className='schedule-cell' key={`row_${idx}`}>
        <p>
          {row.isConditionalDeployment ? (
            <span className='deployed conditional-deployment'>
              <i className='fas fa-folder-tree' />
              Conditional Deployment
            </span>
          ) : row.isDraft ? (
            <span id='draft-icon' className='draft'>
              Draft
            </span>
          ) : (
            <span className='deployed'>
              <i className='fas fa-check' />
              Sent
            </span>
          )}
          {!row.isConditionalDeployment ? <span className='date'>{text}</span> : null}
        </p>
      </td>
    )
  }

  onOpenUploadModal = () => {
    const { closeModal, openModal, studyID, uploadTranslation } = this.props
    const { checked } = this.state
    const instrument = {}
    instrument.id = Object.keys(checked)[0]
    Object.assign(instrument, checked[instrument.id])
    openModal({
      noButtons: true,
      closeOnBackgroundClick: true,
      children: (
        <UploadTranslationInput
          closeModal={closeModal}
          studyID={studyID}
          uploadTranslation={uploadTranslation}
          instrument={instrument}
        />
      ),
    })
  }

  onDownloadJSONs = (id, title, type) => {
    const { downloadInstrument, studyID } = this.props
    const { checked: _checked } = this.state
    return e => {
      const checked = id === undefined ? _checked : { [id]: { title, type } }
      e.stopPropagation()
      downloadInstrument(studyID, checked)
    }
  }

  renderName = (row, idx) => {
    const { isTriggered, generation } = row

    let languages = ['EN']
    if (row.otherLanguages) languages = [...languages, ...row.otherLanguages]
    const languageTags = languages.map(language => {
      return (
        <div className='tag' key={`surv-${language}`}>
          {language.toUpperCase()}
        </div>
      )
    })

    return (
      <td className='name-col' key={`row_${idx}`}>
        <div className='flexed start-justified start-aligned'>
          <div className='instrument-names'>
            <div className='name'>{row.value}</div>
            <p className='display-name'>{row.displayName}</p>
            <div className='languages flexed start-justified'>{languageTags}</div>
          </div>
        </div>
      </td>
    )
  }

  onDownloadTaskCSV(id, title) {
    return e => {
      const { downloadInstrumentData, studyID, siteID } = this.props
      e.stopPropagation()
      downloadInstrumentData(studyID, siteID, id, title)
    }
  }

  onEdit(id, redirect) {
    const { studyID } = this.props
    return e => {
      e.stopPropagation()
      browserHistory.push(`/studies/${studyID}/instruments/${id}/${redirect}`)
    }
  }

  renderAction({ id, type, title }) {
    const {
      canWrite,
      studyLock,
      currentStudy: { config },
    } = this.props
    const isTask = type === INSTRUMENT_TYPE_MAP.task
    const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
    const isDiary = type === INSTRUMENT_TYPE_MAP.diary
    const isArticle = type === INSTRUMENT_TYPE_MAP.article
    let typeString = capitalize(type.toLowerCase())
    if (isDiary) typeString = 'Diary'
    const instrumentAccess = hasInstrumentFeatures(config, type)

    const getEditButtonContent = () => {
      if (isSurvey || isDiary) {
        return STRINGS.participantInstrument
      }
      if (isDiary) {
        return capitalize('diary')
      }
      return capitalize(type.toLowerCase())
    }

    const getEditButtonId = () => {
      if (isDiary) {
        return 'diary'
      }
      if (isSurvey) {
        return 'instrument'
      }
      return type.toLowerCase()
    }

    return (
      <td className='action-cell' key={`row_${id}`}>
        <div className='flexed start-justified'>
          <Popup
            dark
            position='left'
            className={`download${isSurvey && studyLock ? ' locked' : ''}`}
            trigger={<Button disabled={!instrumentAccess} link icon='fas fa-cloud-download-alt' />}>
            {!isTask && !isArticle && (
              <Button
                disabled={!instrumentAccess}
                link
                onClick={this.onDownloadJSONs(id, title, type)}
                content={`Download ${typeString} JSON`}
              />
            )}
            {isTask && (
              <Button
                disabled={!instrumentAccess}
                link
                content='Download Task Data'
                onClick={this.onDownloadTaskCSV(id, title)}
              />
            )}
            {(isSurvey || isDiary || isArticle) && (
              <Button
                disabled={!instrumentAccess}
                link
                content='CSV Translation Template'
                onClick={() =>
                  this.onDownloadTranslations({
                    [id]: {
                      type,
                      title,
                    },
                  })
                }
              />
            )}
          </Popup>
          {canWrite && !studyLock && (
            <Popup
              dark
              position='left'
              className='edit'
              trigger={<Button disabled={!instrumentAccess} link id='edit-instrument' icon='fas fa-pencil-alt' />}>
              <Button
                link
                onClick={this.onEdit(id, `edit${getEditButtonId()}`)}
                content={`Edit ${getEditButtonContent()}`}
                disabled={!instrumentAccess}
              />
              <Button
                disabled={!instrumentAccess}
                link
                onClick={this.onEdit(id, 'properties')}
                content='Edit Metadata'
              />
              <Button disabled={!instrumentAccess} link onClick={this.onEdit(id, 'schedule')} content='Edit Schedule' />
            </Popup>
          )}
          {canWrite && !studyLock && (
            <Button
              id='trash-icon'
              className='fit-content-width-button'
              disabled={!instrumentAccess}
              onClick={this.onDelete(id)}
              link
              icon='fas fa-trash'
            />
          )}
        </div>
      </td>
    )
  }
  render() {
    const {
      list,
      searchTerm,
      studyLock,
      canWrite,
      studyID,
      hasClinro,
      hasDiary,
      openModal,
      wizardDisplayName,
      wizardTitle,
      updateWizardDisplayName,
      updateWizardTitle,
      study,
      userId,
    } = this.props
    const { offsets, checked, deleting } = this.state
    const ids = Object.keys(checked)

    return (
      <div className='instruments page'>
        <StickyHeader offset={offsets ? offsets[0] : 0} className='contains-popup end-justified'>
          <InstrumentsViewButtonList
            canWrite={canWrite}
            ids={ids}
            onDelete={this.onDelete}
            onOpenUploadModal={this.onOpenUploadModal}
            onDownloadTranslations={this.onDownloadTranslations}
            onDownloadJSONs={this.onDownloadJSONs}
            hasClinro={hasClinro}
            hasDiary={hasDiary}
            openModal={openModal}
            studyID={studyID}
            studyLock={studyLock}
            updateWizardTitle={updateWizardTitle}
            updateWizardDisplayName={updateWizardDisplayName}
            wizardTitle={wizardTitle}
            wizardDisplayName={wizardDisplayName}
            checked={checked}
            study={study}
            userId={userId}
          />
        </StickyHeader>
        <StickyHeader offset={offsets ? offsets[1] : 0} className='search'>
          <DataDownloadPopup {...this.props} />
          <SearchInput />
        </StickyHeader>
        <SortableTable
          offset={offsets ? offsets[2] : 0}
          onUpdateChecked={this.onUpdateChecked}
          checked={studyLock ? null : checked}
          onCheck={this.onCheck}
          searchTerm={searchTerm}
          searchKeys={['name']}
          sortingBy='lastEdited'
          initialOrder='descending'
          className='instruments-list'
          onRowClick={this.onRowClick}
          renderFunctions={this.renderFunctions}
          headerOptions={this.headerOptions}
          rowList={list}
          deleting={deleting}
          emptyText='No Matching Instruments'
        />
      </div>
    )
  }
}

export default InstrumentsView

InstrumentsView.propTypes = {
  canViewVisits: PropTypes.bool,
  canWrite: PropTypes.bool,
  closeDrawer: PropTypes.func,
  closeModal: PropTypes.func,
  componentLoading: PropTypes.object,
  currentStudy: PropTypes.object,
  deleteInstrument: PropTypes.func,
  downloadData: PropTypes.func,
  downloadInstrument: PropTypes.func,
  downloadInstrumentData: PropTypes.func,
  downloadTranslations: PropTypes.func,
  fetchVisitTemplates: PropTypes.func,
  hasActiveDiary: PropTypes.bool,
  hasClinro: PropTypes.bool,
  hasDiary: PropTypes.bool,
  hasMedAdherence: PropTypes.bool,
  hasVisitSupport: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.array),
  modules: PropTypes.object,
  openDrawer: PropTypes.func,
  openModal: PropTypes.func,
  resetInstrument: PropTypes.func,
  searchTerm: PropTypes.string,
  siteID: PropTypes.number,
  studyID: PropTypes.number,
  studyLock: PropTypes.bool,
  updateWizardDisplayName: PropTypes.func,
  updateWizardTitle: PropTypes.func,
  uploadTranslation: PropTypes.func,
  visitNames: PropTypes.objectOf(PropTypes.string),
}
