import { combineReducers } from 'redux'
import moment from 'moment'
import { loadingActions } from 'store/loader'
import { modalActions } from 'store/modal'
import { actions as notyActions } from 'layouts/ErrorBox'
import { patchStudy } from 'routes/Studies/routes/CreateStudy/modules/CreateStudy'
import request, { generateNotyMessage } from 'utils/request'
import { downloadBlob, generateUniqArr } from 'utils/misc'
import { today } from 'utils/time'
import {
  DEFAULT_NA_STRING_SORT_VAL,
  MODAL_CONTENT_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  DATE_FORMAT_MAP,
} from 'utils/constants'

// CONSTANTS

const ERROR_MESSAGES = {
  not_authenticated: 'Internal error.',
  participant_not_found:
    'Bank account for this email address is not found. Please check the participant’s email address.',
  amount_too_large: 'The transaction failed as it exceeds the maximum transferrable amount',
  amount_too_small: 'The transaction failed as it does not reach the minimum transferable amount',
  expired: null,
  timestamp_malformed: null,
}

const ERROR_NAMES = {
  not_authenticated: 'Not authenticated',
  participant_not_found: 'Bank account not found',
  amount_too_large: 'Transaction exceeds maximum',
  amount_too_small: 'Transaction minimum not met',
  expired: 'System error',
  timestamp_malformed: 'Internal error',
}

const STATUS_TYPE_STRING_MAP = {
  completed: 'Sent',
  declined: 'Declined',
  error: 'Error',
  pending: 'Pending Approval',
  scheduled: 'Scheduled',
}
const STATUS_TYPE_MAP = {
  completed: 'completed',
  declined: 'declined',
  error: 'error',
  failed: 'failed',
  pending: 'pending',
  scheduled: 'scheduled',
}

const PAYMENT_TYPES = {
  amazon_giftcard: 'Amazon gift card',
  AmazonGiftCard: 'Amazon gift Card',
  CYCLE_COMPLETE: 'Monthly Cycle',
  donations: 'Donation',
  gift_cards: 'Gift card',
  ITC_COMPLETE: 'ITP',
  ORP_COMPLETE: 'ORP',
}

const DETAIL_TYPES = {
  now: 'Instant Payment',
  future: 'Future Payment',
  self: 'Payment to Self',
  other: 'Payment to Other',
}

const TREASURE_CHEST_PAYMENT_TYPE_MAP = {
  giftCard: 'gift_cards',
  donation: 'donations',
}

const PENDING_PAYMENT_TYPES = ['amazon_giftcard', 'gift_cards', 'donations']
const PENDING_PAYMENT_STATUSES = [STATUS_TYPE_MAP.pending, STATUS_TYPE_MAP.error, STATUS_TYPE_MAP.failed]

// ACTION CONSTANTS

const SET_PAYMENTS = 'SET_PAYMENTS'
const SET_INCENTIVES_TAB = 'SET_INCENTIVES_TAB'
const SET_PENDING_PAYMENTS_NOTIFICATION = 'SET_PENDING_PAYMENTS_NOTIFICATION'
const UNSET_PENDING_PAYMENTS_NOTIFICATION = 'UNSET_PENDING_PAYMENTS_NOTIFICATION'
const SET_PENDING_GIFTCARDS = 'SET_PENDING_GIFTCARDS'
const SET_PENDING_DONATIONS = 'SET_PENDING_DONATIONS'
const SET_CHARITY_INFO = 'SET_CHARITY_INFO'
const SET_SUPER_GEM_HISTORY = 'SET_SUPER_GEM_HISTORY'
const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS'

const setIncentivesTab = incentivesTab => {
  return {
    type: SET_INCENTIVES_TAB,
    incentivesTab,
  }
}

const unsetPendingPaymentsNotification = () => ({
  type: UNSET_PENDING_PAYMENTS_NOTIFICATION,
})

export const clearPayments = () => {
  return {
    type: CLEAR_PAYMENTS,
  }
}

// API Calls

export const _fetchPayments = (studyID, hasGeneralLoader) => {
  return dispatch => {
    if (!hasGeneralLoader) dispatch(loadingActions.startLoader(true, 'pendingPayments'))
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/payouts`,
        errorMessage: 'Error retrieving payments',
        success: json => {
          return json
        },
        hasLoader: hasGeneralLoader,
      }),
    )
  }
}
const _fetchPendingGiftcards = (studyID, hasGeneralLoader) => {
  return dispatch => {
    if (!hasGeneralLoader) dispatch(loadingActions.startLoader(true, 'pendingPayments'))
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/super_gem_pending_gift_cards`,
        errorMessage: 'Error retrieving pending gift cards',
        success: json => {
          return json
        },
        hasLoader: hasGeneralLoader,
      }),
    )
  }
}

const _fetchPendingDonations = (studyID, hasGeneralLoader) => {
  return dispatch => {
    if (!hasGeneralLoader) dispatch(loadingActions.startLoader(true, 'pendingPayments'))
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/super_gem_pending_donations`,
        errorMessage: 'Error retrieving pending donations',
        success: json => {
          return json
        },
        hasLoader: hasGeneralLoader,
      }),
    )
  }
}

export const _fetchTreasureChestPayoutHistory = (studyID, hasGeneralLoader) => {
  return dispatch => {
    if (!hasGeneralLoader) dispatch(loadingActions.startLoader(true, 'pendingPayments'))
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/super_gem_payout_history`,
        errorMessage: 'Error retrieving payment history',
        success: json => {
          return json
        },
        hasLoader: hasGeneralLoader,
      }),
    )
  }
}

const downloadPayments = (studyId, type = '') => dispatch => {
  let _fileName = `study_${studyId}_payments_history_${today(true)}.csv`
  let _endpointUrl = `/control/studies/${studyId}/payouts/export`

  if (type === 'donations') {
    _fileName = `study_${studyId}_pending_donations_${today(true)}.csv`
    _endpointUrl = `/control/studies/${studyId}/super_gem_pending_donations/export`
  } else if (type === 'giftcards') {
    _fileName = `study_${studyId}_pending_giftcards_${today(true)}.csv`
    _endpointUrl = `/control/studies/${studyId}/super_gem_pending_gift_cards/export`
  }

  const success = (blob, fileName) => {
    downloadBlob(blob, _fileName, fileName)
  }
  return dispatch(
    request({
      url: _endpointUrl,
      resType: 'blob',
      success,
    }),
  )
}

const returnParsedPayments = ({ isDonations = false, isGiftcards = false, paymentsReducer, json = {} }) => {
  let pendingPaymentsStateKey = 'pendingPayments'
  let pendingPaymentsKey = 'payout_info'
  if (isGiftcards) {
    pendingPaymentsStateKey = 'pendingGiftcards'
    pendingPaymentsKey = 'pending_gift_cards'
  } else if (isDonations) {
    pendingPaymentsStateKey = 'pendingDonations'
    pendingPaymentsKey = 'pending_donations'
  }

  const oldPendingPayments = paymentsReducer[pendingPaymentsStateKey]

  const oldPendingPaymentsIds = oldPendingPayments.map(payment => {
    const [{ paymentId }] = payment.filter(property => property.key === 'action')
    return paymentId
  })

  const formattedPayments = getFormattedPayments(json[pendingPaymentsKey], false, isGiftcards, isDonations)

  const { pendingList: pendingPayments } = formattedPayments
  const pendingPaymentIds = pendingPayments.map(payment => {
    const [{ paymentId }] = payment.filter(property => property.key === 'action')
    return paymentId
  })

  const diffPendingPayments = pendingPaymentIds.filter(paymentId => !oldPendingPaymentsIds.includes(paymentId))
  return { pendingPayments, diffPendingPayments, formattedPayments }
}

const setPaymentsAndPendingNotification = ({
  diffPendingPayments,
  formattedPayments,
  isDonations = false,
  isGiftcards = false,
  isPaymentsPage,
  pendingPayments,
}) => dispatch => {
  let actionType = isGiftcards ? SET_PENDING_GIFTCARDS : SET_PAYMENTS
  if (isDonations) actionType = SET_PENDING_DONATIONS

  dispatch({
    type: actionType,
    payload: formattedPayments,
  })

  if (!isPaymentsPage && pendingPayments.length > 0 && diffPendingPayments.length > 0) {
    dispatch({
      type: SET_PENDING_PAYMENTS_NOTIFICATION,
    })
  } else {
    dispatch(unsetPendingPaymentsNotification())
  }
}

const setSuperGemPaymentsAndPendingNotification = ({
  diffPendingGiftcards,
  formattedGiftcards,
  pendingGiftcards,
  diffDonations,
  formattedDonations,
  pendingDonations,
  isPaymentsPage,
}) => dispatch => {
  dispatch({
    type: SET_PENDING_GIFTCARDS,
    payload: formattedGiftcards,
  })
  dispatch({
    type: SET_PENDING_DONATIONS,
    payload: formattedDonations,
  })
  const hasDiffInPendingGiftcards = pendingGiftcards.length > 0 && diffPendingGiftcards.length > 0
  const hasDiffInPendingDonations = pendingDonations.length > 0 && diffDonations.length > 0
  if (!isPaymentsPage && (hasDiffInPendingDonations || hasDiffInPendingGiftcards)) {
    dispatch({
      type: SET_PENDING_PAYMENTS_NOTIFICATION,
    })
  } else {
    dispatch(unsetPendingPaymentsNotification())
  }
}

const fetchPayments = (studyID, hasGeneralLoader = true) => {
  return (dispatch, getState) => {
    dispatch(_fetchPayments(studyID, hasGeneralLoader)).then(json => {
      const { paymentsReducer, subroute } = getState()
      const { pendingPayments, diffPendingPayments, formattedPayments } = returnParsedPayments({
        paymentsReducer,
        json,
      })

      const isPaymentsPage = subroute === 'payments'

      dispatch(
        setPaymentsAndPendingNotification({
          diffPendingPayments,
          formattedPayments,
          isPaymentsPage,
          pendingPayments,
        }),
      )

      if (!hasGeneralLoader) dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
    })
  }
}

const fetchPendingGiftcards = (studyID, hasGeneralLoader = true) => {
  return (dispatch, getState) => {
    dispatch(_fetchPendingGiftcards(studyID, hasGeneralLoader)).then(json => {
      const { paymentsReducer, subroute } = getState()

      const { pendingPayments, diffPendingPayments, formattedPayments } = returnParsedPayments({
        paymentsReducer,
        json,
        isGiftcards: true,
      })

      const isPaymentsPage = subroute === 'payments'

      dispatch(
        setPaymentsAndPendingNotification({
          diffPendingPayments,
          formattedPayments,
          isGiftcards: true,
          isPaymentsPage,
          pendingPayments,
        }),
      )

      if (!hasGeneralLoader) dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
    })
  }
}

const fetchPendingDonations = (studyID, hasGeneralLoader = true) => {
  return (dispatch, getState) => {
    dispatch(_fetchPendingDonations(studyID, hasGeneralLoader)).then(json => {
      const { paymentsReducer, subroute } = getState()

      const { pendingPayments, diffPendingPayments, formattedPayments } = returnParsedPayments({
        paymentsReducer,
        json,
        isDonations: true,
      })

      const isPaymentsPage = subroute === 'payments'

      dispatch({
        type: SET_CHARITY_INFO,
        charityInfo: json.donations_metadata,
      })

      dispatch(
        setPaymentsAndPendingNotification({
          diffPendingPayments,
          formattedPayments,
          isDonations: true,
          isGiftcards: false,
          isPaymentsPage,
          pendingPayments,
        }),
      )

      if (!hasGeneralLoader) dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
    })
  }
}

const fetchPendingGiftcardsAndDonations = (studyID, hasGeneralLoader = true) => {
  return (dispatch, getState) => {
    dispatch(_fetchPendingGiftcards(studyID, hasGeneralLoader)).then(json => {
      const { paymentsReducer, subroute } = getState()

      const {
        diffPendingPayments: diffPendingGiftcards,
        formattedPayments: formattedGiftcards,
        pendingPayments: pendingGiftcards,
      } = returnParsedPayments({
        paymentsReducer,
        json,
        isGiftcards: true,
      })
      dispatch(_fetchPendingDonations(studyID, hasGeneralLoader)).then(pendingJson => {
        const {
          diffPendingPayments: diffDonations,
          formattedPayments: formattedDonations,
          pendingPayments: pendingDonations,
        } = returnParsedPayments({
          paymentsReducer,
          json: pendingJson,
          isDonations: true,
        })

        const isPaymentsPage = subroute === 'payments'

        dispatch(
          setSuperGemPaymentsAndPendingNotification({
            diffPendingGiftcards,
            formattedGiftcards,
            pendingGiftcards,
            diffDonations,
            formattedDonations,
            pendingDonations,
            isPaymentsPage,
          }),
        )

        if (!hasGeneralLoader) dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
      })
    })
  }
}

const fetchTreasureChestPayoutHistory = (studyID, hasGeneralLoader = true) => {
  return dispatch => {
    dispatch(_fetchTreasureChestPayoutHistory(studyID, hasGeneralLoader)).then(json => {
      dispatch({
        type: SET_SUPER_GEM_HISTORY,
        payload: getFormattedPayments(json.completed_super_gem_payouts, false, true, true),
      })
      if (!hasGeneralLoader) dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
    })
  }
}

const confirmPayment = (
  studyId,
  payoutId,
  cycleNo,
  isApprove = true,
  batched,
  isGiftcard = false,
  isDonation = false,
) => dispatch => {
  const confirmBody = {
    status: isApprove ? 'approve' : 'decline',
    cycle_number: cycleNo,
  }
  const singleSuccessFunc = () => {
    if (isGiftcard) {
      dispatch(fetchPendingGiftcards(studyId, false))
    } else if (isDonation) {
      dispatch(fetchPendingDonations(studyId, false))
    } else dispatch(fetchPayments(studyId, false))
  }
  dispatch(loadingActions.startLoader(true, 'pendingPayments'))

  let requestUrl = `/control/studies/${studyId}/${isGiftcard ? 'super_gem_pending_gift_cards' : 'payouts'}/${payoutId}`
  if (isGiftcard) requestUrl = `/control/studies/${studyId}/super_gem_pending_gift_cards/${payoutId}`
  if (isDonation) requestUrl = `/control/studies/${studyId}/super_gem_donations/${payoutId}`

  return dispatch(
    request({
      method: 'PATCH',
      body: JSON.stringify(confirmBody),
      url: requestUrl,
      fail: () => {
        dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
        dispatch(
          notyActions.showError({
            text: generateNotyMessage(`Error ${isApprove ? 'approving' : 'declining'} payment`, false),
          }),
        )
      },
      success: () => (batched ? Promise.resolve() : singleSuccessFunc()),
      successMessage: batched
        ? ''
        : `${isGiftcard ? 'Gift card' : 'Payment'} successfully ${isApprove ? 'approved' : 'declined'}`,
    }),
  )
}

const approveAllPayments = (studyId, pendingPayoutsMap, isGiftcards = false, isDonations = false) => dispatch => {
  const payoutIdArr = Object.keys(pendingPayoutsMap)
  const onConfirm = () => {
    const promises = payoutIdArr.map(payoutId => {
      const cycleNo = pendingPayoutsMap[payoutId]
      return dispatch(confirmPayment(studyId, payoutId, cycleNo, true, true, isGiftcards, isDonations))
    })
    Promise.allSettled(promises).then(() => {
      if (isGiftcards) {
        dispatch(fetchPendingGiftcards(studyId, false))
      } else if (isDonations) {
        dispatch(notyActions.showSuccess({ text: generateNotyMessage('Successfully marked all donations as done') }))
        dispatch(fetchPendingDonations(studyId, false))
      } else {
        dispatch(fetchPayments(studyId, false))
      }
    })
  }
  return dispatch(
    modalActions.openModal({
      content: isDonations ? MODAL_CONTENT_MAP.markAllDonations : MODAL_CONTENT_MAP.approveAll,
      confirmButton: isDonations ? MODAL_BUTTONS_MAP.markAll : MODAL_BUTTONS_MAP.proceed,
      cancelButton: MODAL_BUTTONS_MAP.cancel,
      className: MODAL_CLASSES_MAP.confirmation,
      onConfirm,
    }),
  )
}

const toggleAutoApprove = (studyId, state) => dispatch => {
  const studyDiff = {
    config: state,
  }
  return dispatch(patchStudy({ studyId, studyDiff, diffIsConfig: true })) // last param represents the studyDiff is config difference
}

//
// Util functions
//

const _getPaymentDetails = (num, isTreasureChestUnlocked, isTreasureChestHistory) => {
  if (isTreasureChestUnlocked) {
    return `Chapter ${num} Treasure Chest`
  }
  if (isTreasureChestHistory) {
    return `Chapter ${num}`
  }
  return `Chapter ${num} complete`
}

const getPendingDate = (completionTimestamp, pendingTimestamp) => {
  if (completionTimestamp) return moment(completionTimestamp).format(DATE_FORMAT_MAP.mainWithDateTime)
  if (pendingTimestamp) return moment(pendingTimestamp).format(DATE_FORMAT_MAP.mainWithDateTime)
  return '---'
}

const convertTotalsToDollarAmounts = totals => {
  Object.keys(totals).forEach(key => {
    const valueIsNum = !!Number(totals[key])
    if (valueIsNum) {
      totals[key] /= 100.0
    } else {
      convertTotalsToDollarAmounts(totals[key])
    }
  })
}
const getFormattedLists = (
  _completedList,
  _pendingList,
  hideName = false,
  isGiftcards = false,
  isDonations = false,
) => {
  /**
   * hideName bool signifies to hide the ptp name, ptp id, subject id for when the
   * table is displayed in participant page or drawer
   */
  const totals = {
    scheduled: 0,
    pending: 0,
    declined: 0,
    sent: 0,
    treasureChest: {},
  }

  if (isGiftcards) {
    totals.treasureChest.giftcards = {
      declined: 0,
      sent: 0,
    }
  }
  if (isDonations) {
    totals.treasureChest.donations = {
      sent: 0,
    }
  }

  let pendingTotal = 0
  let pendingDonationsTotal = 0
  const pendingParticipantsIdArr = []

  const completedList = _completedList.map(pmt => {
    const { payment_type: paymentType } = pmt
    // increment totals
    switch (pmt.status.type) {
      case STATUS_TYPE_MAP.scheduled:
        totals.scheduled += pmt.amount
        break
      case STATUS_TYPE_MAP.completed:
        if (paymentType === TREASURE_CHEST_PAYMENT_TYPE_MAP.giftCard) {
          totals.treasureChest.giftcards.sent += pmt.amount
        } else if (paymentType === TREASURE_CHEST_PAYMENT_TYPE_MAP.donation) {
          totals.treasureChest.donations.sent += pmt.amount
        }
        totals.sent += pmt.amount
        break
      case STATUS_TYPE_MAP.pending:
        totals.pending += pmt.amount
        break
      case STATUS_TYPE_MAP.declined:
        if (paymentType === TREASURE_CHEST_PAYMENT_TYPE_MAP.giftCard) {
          totals.treasureChest.giftcards.declined += pmt.amount
        }
        totals.declined += pmt.amount
        break
      case STATUS_TYPE_MAP.error:
        totals.error += pmt.amount
        break
      default:
        totals.sent += 0
    }
    const ptpName = `${pmt.participant.fname} ${pmt.participant.lname}`
    const completionDate = pmt.created_timestamp
      ? moment(pmt.created_timestamp).format(DATE_FORMAT_MAP.mainWithDateTime)
      : '--'
    const hasError = pmt.status.type === 'error'
    const details = DETAIL_TYPES[pmt.details] || pmt.details
    const detailsIsCycleNo = !!Number(details)

    const generateStatusDetails = () => {
      let statusDetails
      if (pmt.payment_type === 'AmazonGiftCard' || pmt.payment_type === 'gift_cards') {
        statusDetails = hasError ? { name: pmt.status.details.message, content: '' } : pmt.status.details
      } else {
        statusDetails = hasError
          ? { name: ERROR_NAMES[pmt.status.details], content: ERROR_MESSAGES[pmt.status.details] }
          : pmt.status.details
      }
      return statusDetails
    }

    const isTreasureChestHistory = [
      TREASURE_CHEST_PAYMENT_TYPE_MAP.donation,
      TREASURE_CHEST_PAYMENT_TYPE_MAP.giftCard,
    ].includes(pmt.payment_type)

    const row = [
      {
        key: 'ptpName',
        value: ptpName,
        sortValue: ptpName,
        hasError,
        ptpID: pmt.participant.id,
        ptpSiteID: pmt.participant.site_id,
      },
      {
        key: 'ptpId',
        value: pmt.participant.id,
        sortValue: pmt.participant.id,
      },
      {
        key: 'subjectId',
        value: pmt.participant.subject_id || '--',
        sortValue: pmt.participant.subject_id || DEFAULT_NA_STRING_SORT_VAL,
      },
      { key: 'paymentType', value: PAYMENT_TYPES[pmt.payment_type], sortValue: pmt.payment_type },
      {
        key: 'details',
        value: detailsIsCycleNo ? _getPaymentDetails(details, false, isTreasureChestHistory) : details,
        sortValue: details,
      },
      { key: 'completionDate', value: completionDate, sortValue: moment(pmt.created_timestamp || undefined) },
      {
        key: 'status',
        value: STATUS_TYPE_STRING_MAP[pmt.status.type],
        sortValue: pmt.status.type,
        timestamp: pmt.status.completed_timestamp
          ? moment(pmt.status.completed_timestamp).format(DATE_FORMAT_MAP.mainWithDateTime)
          : null,
        details: generateStatusDetails(),
        hasError,
      },
      // the amount will be in cents, so only need to divide by 100 and not worry about slicing any extra decimal places
      { key: 'amount', value: Number(pmt.amount) / 100.0, sortValue: Number(pmt.amount), className: 'amount' },
    ]
    if (hideName) row.splice(0, 3)
    return row
  })

  // util func akes the cents and converts the amounts in totals object to dollars (x/100.0)
  convertTotalsToDollarAmounts(totals)

  const pendingList = _pendingList.map(pmt => {
    if (isDonations) {
      pendingDonationsTotal += pmt.amount
    } else {
      switch (pmt.status.type) {
        case STATUS_TYPE_MAP.scheduled:
        case STATUS_TYPE_MAP.pending:
        case STATUS_TYPE_MAP.error:
          pendingTotal += pmt.amount
          break
        default:
          pendingTotal += 0
      }
    }
    pendingParticipantsIdArr.push(pmt.participant.id)
    const ptpName = `${pmt.participant.fname} ${pmt.participant.lname}`

    const date = getPendingDate(pmt.created_timestamp)
    const hasError = pmt.status?.type === 'error'
    const details = DETAIL_TYPES[pmt.details] || pmt.details
    const detailsIsCycleNo = !!Number(details)
    const isTreasureChestPending = [
      TREASURE_CHEST_PAYMENT_TYPE_MAP.giftCard,
      TREASURE_CHEST_PAYMENT_TYPE_MAP.donation,
    ].includes(pmt.payment_type)

    const generateStatusDetails = () => {
      let statusDetails
      if (pmt.payment_type === 'AmazonGiftCard' || pmt.payment_type === 'gift_cards') {
        statusDetails = hasError ? { name: pmt.status.details.message, content: '' } : pmt.status.details
      } else {
        statusDetails = hasError
          ? { name: ERROR_NAMES[pmt.status.details], content: ERROR_MESSAGES[pmt.status.details] }
          : pmt.status?.details
      }
      return statusDetails
    }

    const row = [
      {
        key: 'ptpName',
        value: ptpName,
        sortValue: ptpName,
        hasError,
        ptpID: pmt.participant.id,
        ptpSiteID: pmt.participant.site_id,
      },
      {
        key: 'ptpId',
        value: pmt.participant.id,
        sortValue: pmt.participant.id,
      },
      {
        key: 'subjectId',
        value: pmt.participant.subject_id || '--',
        sortValue: pmt.participant.subject_id || DEFAULT_NA_STRING_SORT_VAL,
      },
      {
        key: 'details',
        value: detailsIsCycleNo ? _getPaymentDetails(details, isTreasureChestPending) : details,
        sortValue: details,
      },
      { key: 'date', value: date, sortValue: moment(pmt.created_timestamp) },
      {
        key: 'status',
        value: STATUS_TYPE_STRING_MAP[pmt.status?.type],
        sortValue: hasError ? `${pmt.status?.type} ${pmt.status?.details.errorType}` : pmt.status?.type,
        details: generateStatusDetails(),
        hasError,
      },
      { key: 'action', hasError, paymentId: pmt.id, cycleNo: detailsIsCycleNo ? details : null },
      { key: 'amount', value: Number(pmt.amount) / 100.0, sortValue: Number(pmt.amount), className: 'amount' }, // the amount will be in cents, so only need to divide by 100 and not worry about slicing any extra decimal places
    ]
    if (isDonations) row.splice(5, 1)
    if (hideName) row.splice(0, 1)
    return row
  })

  const _numPendingParticipants = generateUniqArr(pendingParticipantsIdArr).length
  return {
    completedList,
    numPendingParticipants: _numPendingParticipants,
    pendingDonationsTotal,
    pendingList,
    pendingTotal,
    totals,
  }
}

export const getFormattedPayments = (list, hideName = false, isGiftcards = false, isDonations = false) => {
  if (isDonations && !isGiftcards) return getFormattedLists([], list, hideName, isGiftcards, isDonations)

  const _pendingList = []
  const _completedList = []

  if (list)
    list.forEach(payment => {
      const { payment_type: paymentType, status } = payment
      const isPendingType = PENDING_PAYMENT_TYPES.includes(paymentType)
      const isPendingStatus = PENDING_PAYMENT_STATUSES.includes(status.type)
      const cannotBePending = !(isPendingType && isPendingStatus)

      if ([STATUS_TYPE_MAP.completed, STATUS_TYPE_MAP.declined].includes(status.type) || cannotBePending) {
        _completedList.push(payment)
      } else _pendingList.push(payment)
    })

  return getFormattedLists(_completedList, _pendingList, hideName, isGiftcards, isDonations)
}

const payments = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return action.payload.completedList
    case SET_PENDING_GIFTCARDS:
    case CLEAR_PAYMENTS:
      return []
    default:
      return state
  }
}

const pendingPayments = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return action.payload.pendingList
    case SET_PENDING_GIFTCARDS:
    case CLEAR_PAYMENTS:
      return []
    default:
      return state
  }
}

const pendingPaymentsNotification = (state = false, action) => {
  switch (action.type) {
    case SET_PENDING_PAYMENTS_NOTIFICATION:
      return true
    case UNSET_PENDING_PAYMENTS_NOTIFICATION:
      return false
    case CLEAR_PAYMENTS:
      return false
    default:
      return state
  }
}

const pendingGiftcards = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return []
    case SET_PENDING_GIFTCARDS:
      return action.payload.pendingList
    case CLEAR_PAYMENTS:
      return []
    default:
      return state
  }
}

const charityInfo = (state = {}, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return {}
    case SET_CHARITY_INFO:
      return action.charityInfo
    case CLEAR_PAYMENTS:
      return {}
    default:
      return state
  }
}

const pendingDonations = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return []
    case SET_PENDING_DONATIONS:
      return action.payload.pendingList
    case CLEAR_PAYMENTS:
      return []
    default:
      return state
  }
}

const superGemPayoutHistory = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return []
    case SET_SUPER_GEM_HISTORY:
      return action.payload.completedList
    case CLEAR_PAYMENTS:
      return []
    default:
      return state
  }
}

const totals = (state = {}, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
    case SET_PENDING_GIFTCARDS:
    case SET_SUPER_GEM_HISTORY:
      return action.payload.totals
    case CLEAR_PAYMENTS:
      return {}
    default:
      return state
  }
}
const pendingTotal = (state = 0, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
    case SET_PENDING_GIFTCARDS:
      return action.payload.pendingTotal
    case CLEAR_PAYMENTS:
      return 0
    default:
      return state
  }
}

const pendingDonationsTotal = (state = 0, action) => {
  switch (action.type) {
    case SET_PENDING_DONATIONS:
      return action.payload.pendingDonationsTotal
    case SET_PAYMENTS:
    case CLEAR_PAYMENTS:
      return 0
    default:
      return state
  }
}

const numPendingParticipants = (state = 0, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
    case SET_PENDING_GIFTCARDS:
      return action.payload.numPendingParticipants
    case CLEAR_PAYMENTS:
      return 0
    default:
      return state
  }
}

export const currentIncentivesTab = (state = 'Pending', action) => {
  switch (action.type) {
    case SET_INCENTIVES_TAB:
      return action.incentivesTab
    default:
      return state
  }
}

export const actions = {
  approveAllPayments,
  confirmPayment,
  downloadPayments,
  fetchPayments,
  fetchPendingDonations,
  fetchPendingGiftcardsAndDonations,
  fetchPendingGiftcards,
  fetchTreasureChestPayoutHistory,
  setIncentivesTab,
  toggleAutoApprove,
  unsetPendingPaymentsNotification,
}

export default combineReducers({
  charityInfo,
  currentIncentivesTab,
  numPendingParticipants,
  payments,
  pendingDonations,
  pendingDonationsTotal,
  pendingGiftcards,
  pendingPayments,
  pendingPaymentsNotification,
  pendingTotal,
  superGemPayoutHistory,
  totals,
})
