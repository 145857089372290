import React from 'react'
import PropTypes from 'prop-types'
import { SortableTable } from 'components/UIElements'
import { Link } from 'react-router'
import { convertToLocalizedNumber } from 'utils/misc'
import StatusCell from './StatusCell'
import '../stylesheets/paymentstable.scss'

export const headerOptions = [
  { key: 'ptpName', sortable: true, component: 'Participant' },
  { key: 'ptpId', sortable: true, component: 'Part ID' },
  { key: 'subjectId', sortable: true, component: 'SubJ ID' },
  { key: 'paymentType', sortable: true, component: 'Payment Type' },
  { key: 'details', sortable: true, component: 'Details' },
  { key: 'completionDate', sortable: true, component: 'Date' },
  { key: 'status', sortable: true, component: 'Status' },
  { key: 'amount', sortable: true, component: 'Amount' },
]
export const treasureChestHeaderOptions = [
  { key: 'ptpName', sortable: true, component: 'Participant' },
  { key: 'ptpId', sortable: true, component: 'Part ID' },
  { key: 'subjectId', sortable: true, component: 'SubJ ID' },
  { key: 'paymentType', sortable: true, component: 'Payment Type' },
  { key: 'details', sortable: true, component: 'Chapter' },
  { key: 'completionDate', sortable: true, component: 'Purchase Date' },
  { key: 'status', sortable: true, component: 'Status' },
  { key: 'amount', sortable: true, component: 'Amount' },
]

const totalsMap = {
  declined: 'Declined',
  paid: 'Paid',
  pending: 'Pending',
  scheduled: 'Scheduled',
  sent: 'Sent',
}

const paymentTypeMap = {
  donations: 'Donations',
  giftcards: 'Gift Cards',
}

export const renderFunctions = {
  paymentType: ({ value }) => <td key='payment-type-cell'>{value}</td>,
  details: ({ value }) => <td key='details-cell'>{value}</td>,
  status: props => <StatusCell {...props} />,
}

export const getTotals = (payments, totals, language, hasTreasureChest) => {
  if (payments.length === 0) return null
  const totalKeys = Object.keys(totals)

  // Totals component for studies with treasure chest have mulitple items within the row
  if (hasTreasureChest) {
    const treasureChestKeys = Object.keys(totals.treasureChest)
    return (
      <div className='super-gem-history-footer'>
        {treasureChestKeys.map(key => {
          const paymentTypeTotals = totals.treasureChest[key]
          const paymentType = paymentTypeMap[key]
          const paymentTypeTotalsArr = Object.keys(paymentTypeTotals)
          const noSubtotals = paymentTypeTotalsArr.every(subtotalType => paymentTypeTotals[subtotalType] === 0)
          if (noSubtotals) {
            return null
          }
          return (
            <div className='treasure-chest flexed end-justified' key={key}>
              <div className='flexed space-between'>
                <span className='payment-type-label'>{paymentType}</span>
                <div className='subtotals flexed'>
                  {paymentTypeTotalsArr.map(totalType => {
                    const total = paymentTypeTotals[totalType]
                    const localizedTotal = convertToLocalizedNumber(total, language, true)
                    const totalTypeLabel = totalsMap[totalType]
                    if (total) {
                      return (
                        <div className='payment-totals' key={`${key}-${totalType}`}>
                          <span className='subtotal-label'>{totalTypeLabel}</span>
                          <span className='subtotal-num'>{localizedTotal}</span>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const noTreasureChestKeys = totalKeys.filter(key => key !== 'treasureChest')
  return noTreasureChestKeys.map(key => {
    const total = totals[key]
    const text = totalsMap[key]
    if (total === 0) return null
    const localizedTotal = convertToLocalizedNumber(total, language, true)
    return (
      <div className='payment-totals' key={key}>
        <span>{text}</span>
        <span>{localizedTotal}</span>
      </div>
    )
  })
}

const PaymentsTable = ({
  canWrite,
  hasTreasureChest,
  language,
  offsets,
  payments,
  study,
  superGemPayoutHistory,
  totals,
  studyLock,
}) => {
  const _payments = hasTreasureChest ? superGemPayoutHistory : payments

  renderFunctions.ptpName = ({ value, hasError, ptpID, ptpSiteID }) => {
    return (
      <td key='ptpName-cell'>
        {canWrite ? (
          studyLock ? (
            value
          ) : (
            <Link to={`/studies/${study.id}/participants/${ptpID}/ptpSite/${ptpSiteID}`}>{value}</Link>
          )
        ) : (
          `Participant ${ptpID}`
        )}
        {hasError && <i className='fas fa-exclamation-circle' />}
      </td>
    )
  }
  renderFunctions.amount = ({ value }) => {
    const localizedPayment = convertToLocalizedNumber(value, language, true)
    return (
      <td key={`payment-${value}`} className='amount'>
        {localizedPayment}
      </td>
    )
  }

  return (
    <div className='payments-table'>
      <SortableTable
        offset={offsets[0] || 183} // catch for bug where 2nd offset does not calculate on refresh
        sortingBy='name'
        rowList={_payments}
        headerOptions={hasTreasureChest ? treasureChestHeaderOptions : headerOptions}
        renderFunctions={renderFunctions}
      />
      {getTotals(_payments, totals, language, hasTreasureChest)}
    </div>
  )
}

PaymentsTable.propTypes = {
  canWrite: PropTypes.bool,
  hasTreasureChest: PropTypes.bool,
  language: PropTypes.string,
  offsets: PropTypes.arrayOf(PropTypes.number),
  payments: PropTypes.arrayOf(PropTypes.array),
  study: PropTypes.shape({
    id: PropTypes.number,
  }),
  superGemPayoutHistory: PropTypes.arrayOf(PropTypes.array),
  totals: PropTypes.shape({
    treasureChest: PropTypes.objectOf(PropTypes.object),
  }),
  studyLock: PropTypes.bool,
}

export default PaymentsTable
