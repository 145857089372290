import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { setObjectProperty } from 'utils/object'
import STRINGS from 'utils/strings'
import { DATE_FORMAT_MAP, DEFAULT_VISIT_START_HOUR, DEFAULT_VISIT_START_MINUTE } from 'utils/constants'
import { Container, DatePicker, Loader, PromptBar, AutocompleteGroupSearch } from 'components/UIElements'

const visitStartDateField = 'visit_start_datetime'
const startVisitIdField = 'start_visit_id'

const getDateErrors = (date, options) => {
  const inputDateValue = options?.props?.inputProps?.ref?.current?.value
  if (inputDateValue === '') return false
  return !date || typeof date === 'undefined' || moment().isAfter(date, 'day')
}

const VisitScheduleManagement = ({
  clearPtpVisits,
  disabled,
  fetchParticipantVisits,
  fetchVisitTemplates,
  isCreate,
  participant,
  participantVisits,
  studyID,
  updateField,
  visitTemplates,
}) => {
  const [checkedVisitTemplate, setCheckedVisitTemplate] = useState({})
  const [startVisitDateError, setStartVisitDateError] = useState(false)
  const [visitTemplatesLoading, setVisitTemplatesLoading] = useState(false)
  const [visitTemplateName, setVisitTemplateName] = useState('')

  const visitTemplatesList = useMemo(
    () =>
      visitTemplates.map(({ id: tid, template_name, visits }) => ({
        field: tid,
        label: template_name,
        value: visits.map(({ id, name }) => ({
          key: id,
          text: name,
          value: id,
        })),
      })),
    [visitTemplates],
  )

  const onToggleVisit = (item, group) => {
    if (
      (item === null &&
        group.value.find(({ key }) => {
          const getItemGroup = group?.field ? checkedVisitTemplate[group.field] : null
          return getItemGroup && getItemGroup[key]
        })) ||
      (item && checkedVisitTemplate && checkedVisitTemplate[item.field] && checkedVisitTemplate[item.field][item.key])
    ) {
      setCheckedVisitTemplate({})
      updateField(startVisitIdField, '')
      setVisitTemplateName('')
    } else if (item) {
      setCheckedVisitTemplate(setObjectProperty({}, `${item.field}.${item.key}`, item.key))
      updateField(startVisitIdField, item.value)
      setVisitTemplateName(`${group.label}: ${item.text}`)
    }
  }

  const hasVisitsInTemplates = useMemo(
    () =>
      visitTemplatesList.some(visitTemplate => {
        return !!visitTemplate.value.length
      }),
    [visitTemplatesList],
  )

  useEffect(() => {
    clearPtpVisits()
    if (!isCreate) fetchParticipantVisits(studyID, participant.id)
    setVisitTemplatesLoading(true)
    fetchVisitTemplates(studyID).finally(() => setVisitTemplatesLoading(false))
  }, [])

  const setFixedVisitStartTime = _moment => {
    return _moment.set({ hour: DEFAULT_VISIT_START_HOUR, minute: DEFAULT_VISIT_START_MINUTE })
  }

  if (participantVisits.length) return null
  if (visitTemplatesLoading) return <Loader inContainer size={25} />

  return (
    <div className='participant-customization'>
      <Container>
        <div className='title'>{STRINGS.visitScheduleManagement}</div>
        <div className='flexed start-justified start-aligned'>
          <span>{STRINGS.ptpNoVisitScheduleAssigned}</span>
        </div>
        <>
          <div>
            <p className='label-small date'>{STRINGS.nextVisitDate}</p>
            <DatePicker
              disabled={disabled || !hasVisitsInTemplates}
              hasError={startVisitDateError}
              disabledDays={{ before: new Date() }}
              initialDate={
                participant[visitStartDateField]
                  ? moment(participant[visitStartDateField], DATE_FORMAT_MAP.datePickerWithFullTime)
                  : participant[visitStartDateField]
              }
              onDayChange={(date, options) => {
                setStartVisitDateError(getDateErrors(date, options, visitTemplateName))
                if (date) {
                  const dateWithSetTime = setFixedVisitStartTime(date)
                  const formattedDateStr = dateWithSetTime.format(DATE_FORMAT_MAP.datePickerWithFullTime)
                  updateField(visitStartDateField, formattedDateStr)
                } else updateField(visitStartDateField, date)
              }}
            />
            <p className='label-small date'>{STRINGS.visitName}</p>
            <AutocompleteGroupSearch
              absolutePositionedList
              list={visitTemplatesList || []}
              toggleItem={onToggleVisit}
              checked={checkedVisitTemplate}
              placeholder='Select...'
              value={visitTemplateName}
              disabled={disabled || !hasVisitsInTemplates}
              groupComponent='radio'
              childComponent='checkbox'
            />
            {!hasVisitsInTemplates && (
              <PromptBar>
                <strong>{`${STRINGS.pleaseNote}:`}</strong>
                <span>{STRINGS.ptpNoVisitScheduleCreated}</span>
              </PromptBar>
            )}
          </div>
        </>
      </Container>
    </div>
  )
}

VisitScheduleManagement.defaultProps = {
  disabled: false,
}

VisitScheduleManagement.propTypes = {
  clearPtpVisits: PropTypes.func,
  disabled: PropTypes.bool,
  visitTemplates: PropTypes.arrayOf(PropTypes.object),
  participantVisits: PropTypes.arrayOf(PropTypes.array),
  fetchParticipantVisits: PropTypes.func,
  updateField: PropTypes.func,
  participant: PropTypes.object,
  studyID: PropTypes.number,
  fetchVisitTemplates: PropTypes.func,
  isCreate: PropTypes.bool,
}

export default VisitScheduleManagement
