import React, { useState } from 'react'
import { Button, Radio, DatePicker } from 'components/UIElements'
import STRINGS from 'utils/strings'
import {
  STATUS_OPTIONS,
  DATE_FORMAT_MAP,
  VISIT_START_HOUR,
  VISIT_START_MINUTE,
  VISIT_START_SECOND,
} from 'utils/constants'
import moment from 'moment'
import '../stylesheets/VisitTable.scss'
import '../stylesheets/VisitSchedules.scss'

const UpdateStatusSection = ({ status, studyID, ssvId, ptpID, onClose, updatePreviousScheduleVisit, heading }) => {
  const [statusOption, setStatusOption] = useState(status || STATUS_OPTIONS.Confirmed)
  const [date, setDate] = useState(new Date())

  return (
    <>
      {heading ? <p className='heading'>{heading}</p> : null}
      <p className='caption'>{STRINGS.pleaseConfirmActualDate}</p>
      <div className='checkbox-wrapper'>
        <Radio
          selected={statusOption === STATUS_OPTIONS.Confirmed}
          content={STRINGS.visitOccurred}
          onClick={() => {
            setStatusOption(STATUS_OPTIONS.Confirmed)
          }}
        />
      </div>
      {statusOption === STATUS_OPTIONS.Confirmed ? (
        <div className='actual-visit-date-wrapper'>
          <label className='label-small' htmlFor='actual-visit-date'>
            {STRINGS.actualVisitDate}
          </label>
          <DatePicker
            id='actual-visit-date'
            className='actual-visit-date-picker'
            date={moment(date)}
            disabledDays={{
              after: new Date(),
            }}
            onDayChange={date => {
              setDate(date)
            }}
            value={date}
          />
        </div>
      ) : null}
      <div className='checkbox-wrapper'>
        <Radio
          selected={statusOption === STATUS_OPTIONS.Missed}
          content={STRINGS.visitMissed}
          onClick={() => {
            setStatusOption(STATUS_OPTIONS.Missed)
          }}
        />
      </div>
      <div className='bottom flexed'>
        <Button grey id='modal-cancel' content='Cancel' onClick={onClose} />
        <Button
          id='modal-confirm'
          className='confirm'
          content='Confirm'
          onClick={() => {
            onClose()
            updatePreviousScheduleVisit(studyID, ssvId, ptpID, {
              actual_visit_datetime:
                statusOption === STATUS_OPTIONS.Confirmed
                  ? moment(date)
                      .set({ hour: VISIT_START_HOUR, minute: VISIT_START_MINUTE, second: VISIT_START_SECOND })
                      .format(DATE_FORMAT_MAP.datePickerWithFullTime)
                  : undefined,
              status: statusOption,
            })
          }}
        />
      </div>
    </>
  )
}

export default UpdateStatusSection
