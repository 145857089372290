import React from 'react'
import PropTypes from 'prop-types'
import { Popup, Input } from 'components/UIElements'
import { Link } from 'react-router'
import { MIN_QUESTIONS_FOR_SHUFFLE } from 'utils/constants'

const DiaryToolbar = props => {
  const {
    id,
    studyID,
    consent,
    downloadConsentPreview,
    wordCount,
    type,
    isLoading,
    updateTitle,
    title,
    errors,
    onSave,
    version,
    onRandomize,
    questions,
  } = props
  const previewURL =
    id === 'consent' ? `/studies/${studyID}/e-consent/consent/preview` : `/studies/${studyID}/instruments/${id}/preview`

  // We need to disabled shuffling if survey contains only 1 question and intro or just an intro
  const isShuffleDisabled = Object.keys(questions)?.length <= MIN_QUESTIONS_FOR_SHUFFLE

  return (
    <div className='survey-toolbar_placeholder'>
      <div className='spacer' />
      <div className='survey-toolbar'>
        <Input
          className='survey-title-input'
          hasError={title === '' && !!errors.titleErr}
          placeholder={errors.titleErr || `Enter your diary name here`}
          onChange={value => updateTitle(value)}
          value={title}
          spellCheck
        />
        <div className='consent-title'>{id === 'consent' ? 'Consent' : ''}</div>
        <div className='button-list'>
          {id && (
            <Link className='clickable-wrapper clickable toolbar-button' href={previewURL} target='_blank'>
              <i className='fa fa-eye' />
              <span>Preview</span>
            </Link>
          )}
          {id === 'consent' && (
            <div className='clickable toolbar-button' onClick={() => downloadConsentPreview(consent, studyID)}>
              <i className='fas fa-cloud-download-alt' />
              <span>Export to PDF</span>
            </div>
          )}
          {version && <VersionDropdown version={version} previewURL={previewURL} />}
          {onRandomize && (
            <div className={`clickable toolbar-button ${isShuffleDisabled ? 'disabled' : ''}`} onClick={onRandomize}>
              <i className='fas fa-random' />
              <span>Shuffle Questions</span>
            </div>
          )}
          <div>
            <span>{`Word Count: ${wordCount}`}</span>
          </div>
        </div>
      </div>
      <div className='spacer' />
    </div>
  )
}

const VersionDropdown = ({ version, previewURL }) => {
  const versions = [<h6 key='head'>Preview Version:</h6>]
  for (let v = 1; v < version; v++) {
    versions.push(
      <Link key={v} className='clickable-wrapper' href={`${previewURL}?version=${v}`} target='_blank'>
        <p className='clickable'>{v}</p>
      </Link>,
    )
  }
  return (
    <Popup
      align='left'
      disabled={versions.length < 2}
      className='version-dropdown clickable'
      trigger={
        <div className='toolbar-button'>
          <i className='fas fa-history' />
          <span>Previous Versions</span>
        </div>
      }>
      {versions}
    </Popup>
  )
}

VersionDropdown.propTypes = {
  previewURL: PropTypes.string,
  version: PropTypes.number,
}

DiaryToolbar.propTypes = {
  consent: PropTypes.shape({
    id: PropTypes.string,
  }),
  downloadConsentPreview: PropTypes.func,
  errors: PropTypes.shape({
    titleErr: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onRandomize: PropTypes.func,
  onSave: PropTypes.func,
  studyID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  type: PropTypes.string,
  updateTitle: PropTypes.func,
  version: PropTypes.number,
  wordCount: PropTypes.number,
  id: PropTypes.string,
}

export default DiaryToolbar
