import React from 'react'
import PropTypes from 'prop-types'
import { pluralize } from 'utils/misc'
import STRINGS from 'utils/strings'
import { fromUTCMidnightSeconds } from 'utils/time'
import { DEPLOYMENT_KEYS, DEPLOYMENT_VALUES, LOGICAL_OPERATOR_CHART, DATE_FORMAT_MAP } from 'utils/constants'
import { PROPERTIES, ICONS } from '../../../../../utils/PropertyFields'
import ExpandableList from '../../../../../../../../../../../components/ExpandableList'

const CATEGORIES = [
  {
    header: 'Deployment Details',
    fields: ['start', 'finish', 'repeat', 'visible', 'participants'],
  },
  {
    header: 'UI Properties',
    fields: ['display_name', 'display_icon'],
  },
  {
    header: 'Instrument Settings',
    fields: ['gamification_points', 'priority', 'attribution_text'],
  },
]

const _getStartValue = ({ schedule, deploy_on_confirm: deployOnConfirm }, articleType) => {
  const { chain_deployment_info = {}, deploy } = schedule
  const { prev_instrument_id } = chain_deployment_info
  if ('now' in deploy) {
    return (
      <p>
        <span className='tag'>Immediately</span>
      </p>
    )
  }
  if ('absolute' in deploy) {
    return (
      <p>
        <span className='tag'>Exact Date</span>
        <span>{fromUTCMidnightSeconds(deploy.absolute).format(DATE_FORMAT_MAP.main)}</span>
      </p>
    )
  }

  if (Array.isArray(prev_instrument_id) && !(DEPLOYMENT_KEYS.chainDeployByVisit in chain_deployment_info)) {
    const { deployment_condition } = chain_deployment_info
    const { terms, operator } = deployment_condition

    const headingText = operator === 'AND' ? STRINGS.deployAllConditions : STRINGS.deployAtLeastOneCondition

    const getText = id => {
      const term = terms?.find(item => item.instrument_id === id)
      const { cohort = {} } = term || {}
      const { filter = {} } = cohort
      const { instrument_score, logical_operator } = filter

      if (!logical_operator) {
        return 'Trigger if instrument completed'
      }

      return `Trigger if instrument score is ${LOGICAL_OPERATOR_CHART[logical_operator]} ${instrument_score}`
    }

    return (
      <>
        <p>
          <span className='tag'>Triggered by</span>
          <span className='heading-text'>{headingText}</span>
        </p>
        {prev_instrument_id.map(id => (
          <div key={id}>
            <p className='instrument-trigger'>{`Instrument ID# ${id}`}</p>
            {<p className='trigger-description'>{getText(id)}</p>}
          </div>
        ))}
      </>
    )
  }

  if (DEPLOYMENT_KEYS.chainDeployOffset in deploy) {
    const { cohort } = chain_deployment_info
    const scheduledTime = deploy.chain_deploy_offset
      ? pluralize(deploy.chain_deploy_offset, 'day', 'days')
      : 'Immediately'
    let text = `${scheduledTime} after first instrument completes`

    if (cohort) {
      const { filter } = cohort
      const { instrument_score, logical_operator, score_name } = filter
      text = `${scheduledTime} after first inst is complete and its score (${score_name}) is ${LOGICAL_OPERATOR_CHART[logical_operator]} ${instrument_score}`
    }

    return (
      <p>
        <span className='tag'>Triggered</span>
        <span>{text}</span>
      </p>
    )
  }

  if (DEPLOYMENT_KEYS.chainDeployByVisit in chain_deployment_info) {
    const isMissedArticleType = articleType === 'missed_visit_follow_up'
    const visitType = isMissedArticleType ? 'missed visit' : `${deployOnConfirm ? 'confirmed ' : ''}visit`
    const { days_offset } = deploy
    let text = `on day of ${visitType}`
    if (days_offset > 0) text = `${pluralize(days_offset, 'day', 'days')} after  ${visitType}`
    if (days_offset < 0) text = `${pluralize(Math.abs(days_offset), 'day', 'days')} before ${visitType}`
    return (
      <p>
        <span className='tag'>Triggered</span>
        <span>{text}</span>
      </p>
    )
  }
  return (
    <p>
      <span className='tag'>Relative</span>
      <span>{`${pluralize(deploy.first_login.interval, 'Day', 'Days')} after First Login`}</span>
    </p>
  )
}
const _getFinishValue = ({ recurring, expire, deploy }) => {
  if ('never' in expire && !!expire.never && (!recurring || !recurring.recurrences)) {
    return 'Never Expire'
  }
  if ('never' in expire && (!recurring || !recurring.recurrences)) {
    return 'When Complete'
  }
  if (recurring && recurring.recurrences) {
    return `After ${recurring.recurrences} occurences`
  }
  if ('first_login' in expire) {
    const offset = expire.first_login.interval - deploy.first_login.interval
    return offset === 0 ? 'Same day as instrument starts' : `${offset} days after instrument starts`
  }
  if ('chain_expire_offset' in expire) {
    return `${pluralize(
      expire.chain_expire_offset - deploy.chain_deploy_offset,
      'day',
      'days',
    )} after instrument starts `
  }
  if (`expire_type` in expire) {
    const { expire_type, days_offset } = expire
    if (expire_type === DEPLOYMENT_VALUES.completionOfVisit) {
      if (!days_offset) return 'On day visit is completed'
      return `${pluralize(days_offset, 'day', 'days')} after completion of visit`
    }
    if (expire_type === DEPLOYMENT_VALUES.receiptOfArticle) {
      return `${pluralize(days_offset, 'day', 'days')} after completion of item`
    }
    return 'When Complete'
  }
  if ('relative_to_send_ideal' in expire) {
    const { relative_to_send_ideal } = expire
    return relative_to_send_ideal?.interval === 1
      ? 'Same day as instrument starts'
      : `${pluralize(relative_to_send_ideal?.interval - 1, 'day', 'days')} after instrument starts`
  }
  return fromUTCMidnightSeconds(expire.absolute).format(DATE_FORMAT_MAP.main)
}

const _getRecurrence = ({ recurring }) => {
  return recurring ? (
    recurring.scheme === 'n-days' ? (
      <p>
        <i className='far fa-check-circle' />
        {`Repeat after every ${pluralize(recurring.interval, 'day', 'days')}`}
      </p>
    ) : (
      <p>
        <i className='far fa-check-circle' />
        Repeat Immediately
      </p>
    )
  ) : (
    <p>
      <i className='fas fa-ban' />
      No Repeat
    </p>
  )
}

const _getVisibility = (schedule, visibility) => {
  const { recurring } = schedule
  const visibleDaysText =
    recurring && recurring.interval !== recurring.visible_days ? `, ${recurring.visible_days} day(s)` : ', Everyday'
  const visibilityText = visibility === 'all_day' ? 'All day' : 'At Specific Times'
  return visibilityText + visibleDaysText
}

const generateSitesList = siteSelectionList =>
  siteSelectionList.reduce((acc, item) => {
    acc[item[0]?.value] = item[1]?.value
    return acc
  }, {})

const _getParticipant = (cohort, cohortMap, participantsList, siteSelectionList) => {
  switch (cohort.type) {
    case 'age':
      participantsList.push({
        className: 'recipients',
        label: 'Age Range',
        list: [`${cohort.filter.min_age}-${cohort.filter.max_age}`],
        showCount: false,
        cutLabel: false,
      })
      break
    case 'study_group': {
      const { include } = cohort.filter
      participantsList.push({
        className: 'recipients',
        label: 'Tracks',
        list: include.map(_cohort => cohortMap[_cohort]),
      })
      break
    }
    case 'sites': {
      const { site_ids } = cohort.filter
      const siteList = generateSitesList(siteSelectionList)
      participantsList.push({
        className: 'recipients',
        label: 'Sites',
        list: site_ids.map(id => siteList[id]),
      })
      break
    }
    case 'all_users':
    default:
      participantsList.push('All Participants')
      break
  }
}

const _getParticipants = ({ cohort }, cohortMap, siteSelectionList) => {
  const participantsList = []
  if (Array.isArray(cohort))
    cohort.forEach(item => _getParticipant(item, cohortMap, participantsList, siteSelectionList))
  else _getParticipant(cohort, cohortMap, participantsList, siteSelectionList)
  if (participantsList.length) return participantsList
  return ['All participants']
}

const _getDeploymentValue = (field, metadata, cohortMap, siteSelectionList) => {
  const { article_type, schedule, visibility } = metadata
  switch (field) {
    case 'start':
      return _getStartValue(metadata, article_type)
    case 'finish':
      return _getFinishValue(schedule)
    case 'repeat':
      return _getRecurrence(schedule)
    case 'visible':
      return _getVisibility(schedule, visibility)
    case 'participants':
      return _getParticipants(schedule, cohortMap, siteSelectionList).map((item, index) => {
        if (!item.label) return item
        return <ExpandableList key={item.label || index} {...item} />
      })
    default:
      return '---'
  }
}

const PropertiesView = props => {
  const { instrument, inDrawer, cohortMap, siteSelectionList } = props
  const { metadata } = instrument
  const _getIcon = key => ICONS.find(item => item.key === key).icon

  return (
    <div className='avoid-break'>
      {!inDrawer && <h4>Properties</h4>}
      {CATEGORIES.map(cat => {
        return (
          <div key={cat.header}>
            <h5>{cat.header}</h5>
            <table className='avoid-break'>
              <tbody>
                {cat.fields.map(field => {
                  if (instrument.type === 'TASK' && field === 'display_icon') return null
                  return (
                    <tr key={field}>
                      <td valign={field === 'participants' ? 'top' : 'middle'} className='first-column'>
                        {PROPERTIES[field].text}
                      </td>
                      {field === 'display_icon' ? (
                        <td>
                          <img src={_getIcon(instrument.metadata[field])} alt='display_icon' />
                        </td>
                      ) : (
                        <td className='sent-to-list'>
                          {instrument.metadata[field] ||
                            _getDeploymentValue(field, metadata, cohortMap, siteSelectionList) ||
                            '---'}
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
    </div>
  )
}

PropertiesView.propTypes = {
  cohortMap: PropTypes.object,
  siteSelectionList: PropTypes.arrayOf(PropTypes.array),
  inDrawer: PropTypes.bool,
  instrument: PropTypes.object,
}

export default PropertiesView
