import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Input, Popup } from 'components/UIElements'
import { pluralize } from 'utils/misc'
import STRINGS from 'utils/strings'
import calculateVisitDay from '../../utils/visitDayUtils'

const DAY_RELATIVITY_OPTIONS = [
  { key: 'joins', text: STRINGS.visitScheduleJoinsStudy },
  { key: 'lastVisit', text: STRINGS.visitScheduleLastVisit },
  { key: 'completesVisit', text: STRINGS.visitScheduleCompletesVisit },
]

const VisitDaySelector = props => {
  const {
    disabled,
    selectedVisitScheduleId,
    templateVisit,
    templateVisitErrors,
    updateVisit,
    visitScheduleInitialData,
  } = props
  const { id, visit_day, visit_window, visit_metadata } = templateVisit
  const { deployment_option, relative_visit_id, visit_day_ui_num } = visit_metadata
  const { visit_day: visitDayErr, visit_window: visitWindowErr } = templateVisitErrors

  const [selectedVisitDayRelativity, setSelectedVisitDayRelativity] = useState(DAY_RELATIVITY_OPTIONS[0].key)
  const [visitOptions, setVisitOptions] = useState([])
  const [selectedVisit, setSelectedVisit] = useState(null)
  const [hasVisitWindow, setHasVisitWindow] = useState(true)
  const [visitDayMap, setVisitDayMap] = useState({})
  const [dayVal, setDayVal] = useState(visit_day_ui_num ?? visit_day)

  const _disabled = !selectedVisitScheduleId || disabled

  useEffect(() => {
    setVisitOptions(
      visitScheduleInitialData[selectedVisitScheduleId]?.visitOptions.filter(visit => visit?.key !== id) || [],
    )
    setVisitDayMap(visitScheduleInitialData[selectedVisitScheduleId]?.visitDayMap || [])
  }, [selectedVisitScheduleId])

  /**
   * This hook will update the visit_day value using the calculateVisitDay util func dependent to
   * what type of visit day relavity is chosen and what value is entered in the visit day field.
   */
  useEffect(() => {
    updateVisit({
      key: 'visit_day',
      value: calculateVisitDay({ dayVal, selectedVisitDayRelativity, visitDayMap, selectedVisit, currentVisitId: id }),
    })
  }, [selectedVisitDayRelativity, dayVal, selectedVisit])

  useEffect(() => {
    if (id && deployment_option) {
      setSelectedVisitDayRelativity(deployment_option)
    }
  }, [])

  useEffect(() => {
    if (id && relative_visit_id) {
      setSelectedVisit(relative_visit_id)
    }
  }, [])

  useEffect(() => {
    if (!deployment_option) {
      updateVisit({
        key: 'visit_metadata',
        value: {
          ...visit_metadata,
          deployment_option: DAY_RELATIVITY_OPTIONS[0].key,
        },
      })
    }
  }, [])

  const isCompleteVisitRelativity = selectedVisitDayRelativity === 'completesVisit'

  return (
    <>
      <label className='label-small' htmlFor='visit-day-selection'>
        Visit Day
      </label>
      <div id='visit-day-selection'>
        <div className='flexed'>
          <Input
            disabled={_disabled}
            id='visit-day-input'
            min='0'
            onChange={value => {
              setDayVal(value)
              updateVisit({
                key: 'visit_metadata',
                value: {
                  ...visit_metadata,
                  visit_day_ui_num: value,
                },
              })
            }}
            value={dayVal || ''}
            posttext={`${pluralize(dayVal, 'day', 'days', false)} since participant`}
            type='number'
            hasError={(visit_day == null || visit_day < 0) && visitDayErr}
            errorText={visitDayErr}
          />
          <Dropdown
            disabled={_disabled}
            id='visit-relativity-selector'
            options={visitOptions.length ? DAY_RELATIVITY_OPTIONS : DAY_RELATIVITY_OPTIONS.slice(0, 1)}
            onSelect={item => {
              setSelectedVisitDayRelativity(item.key)
              updateVisit({
                key: 'visit_metadata',
                value: {
                  ...visit_metadata,
                  deployment_option: item.key,
                },
              })
            }}
            selected={selectedVisitDayRelativity}
          />
        </div>
        {(isCompleteVisitRelativity || !hasVisitWindow) && (
          <div className='flexed'>
            {isCompleteVisitRelativity ? (
              <Dropdown
                id='relative-visit-selector'
                disabled={_disabled}
                options={visitOptions}
                selected={selectedVisit}
                onSelect={item => {
                  setSelectedVisit(item.key)
                  updateVisit({
                    key: 'visit_metadata',
                    value: {
                      ...visit_metadata,
                      relative_visit_id: item.key,
                    },
                  })
                }}
                placeholder='Select a visit'
              />
            ) : (
              <div />
            )}
            {!hasVisitWindow && (
              <Button
                id='add-visit-window-btn'
                disabled={_disabled}
                link
                content='+ add visit window'
                onClick={() => {
                  setHasVisitWindow(true)
                }}
              />
            )}
          </div>
        )}
        {hasVisitWindow && (
          <div className='visit-window'>
            <span className={disabled ? 'disabled' : 'flexed start-justified'}>
              <label className='label-small' htmlFor='visit-window-field'>
                {STRINGS.visitWindow}
              </label>
              <Popup
                className='visit-window-popup'
                align='left'
                position='bottom'
                hover
                noPointer
                dark
                trigger={<i className='fas fa-info-circle' />}>
                <p>{STRINGS.visitWindowInfo}</p>
              </Popup>
            </span>

            <Input
              disabled={disabled}
              id='visit-window-field'
              min={0}
              onlyInteger={true}
              posttext='days of flexibility around the visit day.'
              pretext='Allow +/-'
              type='number'
              onChange={value => {
                updateVisit({ key: 'visit_window', value })
              }}
              value={visit_window === null ? '' : visit_window}
              hasError={
                (visit_window === null || visit_window < 0 || typeof visit_window !== 'number') && visitWindowErr
              }
              errorText={visitWindowErr}
            />
          </div>
        )}
      </div>
    </>
  )
}

VisitDaySelector.propTypes = {
  disabled: PropTypes.bool,
  selectedVisitScheduleId: PropTypes.string,
  templateVisit: PropTypes.shape({
    visit_day: PropTypes.number,
    visit_window: PropTypes.number,
  }),
  templateVisitErrors: PropTypes.objectOf(PropTypes.string),
  updateVisit: PropTypes.func,
  visitScheduleInitialData: PropTypes.shape({
    visitOptions: PropTypes.array,
  }),
}

export default VisitDaySelector
