import React from 'react'
import { modalActions } from 'store/modal'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { getBaseSiteIdFromStudy } from 'utils/misc'
import DiaryView from '../components/DiaryView'
import { duplicateItem, mediaActions, diaryActions } from '../modules/Diary'
import { instrumentActions, metadataActions } from '../../../modules/Instrument'
import { downloadInstrument } from '../../../../InstrumentsPage/modules/Instruments'

const mapDispatchToProps = {
  duplicateItem,
  ...diaryActions,
  ...mediaActions,
  ...instrumentActions,
  ...metadataActions,
  ...modalActions,
  downloadInstrument,
}

const mapStateToProps = state => {
  const path = state.location.pathname === '/instruments/creatediary' ? 'create' : 'edit'
  const { instrumentReducer, diaryEditor, loading, user, study } = state
  const { instrument } = instrumentReducer
  const { type, title, url } = instrument

  return {
    id: diaryEditor.diary.id,
    type,
    url,
    instrumentTitle: title,
    diary: diaryEditor.diary,
    article: diaryEditor.article,
    editorStates: diaryEditor.editorStates,
    isLoading: loading.general,
    questionNumbers: diaryEditor.diary.questionNumbers,
    wordCount: diaryEditor.wordCount,
    version: instrumentReducer.instrument.metadata.version,
    path,
    studyID: study.currentStudy.id,
    siteID: getBaseSiteIdFromStudy(study),
    errors: diaryEditor.diaryErrors,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  return props.isLoading ? <Loader active /> : <DiaryView {...props} />
})