import React from 'react'
import { connect } from 'react-redux'
import Loader from 'components/D3Loader'
import { modalActions } from 'store/modal'
import { drawerActions } from 'store/drawer'
import { getBaseSiteIdFromStudy, getUserRoleByStudy } from 'utils/misc'
import { USER_ROLES_MAP } from 'utils/constants'
import SadCloud from 'components/SadCloud'
import InstrumentsView from '../components/InstrumentsView'
import InstrumentWizard from '../components/InstrumentWizard'
import hiddenTasks from '../../../utils/instrumentConstants'
import { actions as instrumentsActions } from '../modules/Instruments'
import { surveyActions } from '../../Instrument/routes/EditSurvey/modules/Survey'
import { clinroActions } from '../../Instrument/routes/EditClinro/modules/Clinro'
import { diaryActions } from '../../Instrument/routes/EditDiary/modules/Diary'
import { instrumentActions, resetInstrument, schedulerActions } from '../../Instrument/modules/Instrument'
import { actions as uploadActions } from '../../Instrument/routes/UploadSurvey/modules/UploadSurvey'
import { downloadData, downloadBulkData } from '../../../../Participants/routes/ParticipantsPage/modules/Participants'
import { fetchVisitTemplates } from '../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'

const mapStateToProps = ({
  instruments,
  loading,
  participants,
  searchTerm,
  study,
  studyLock,
  upload,
  user,
  instrumentReducer,
}) => {
  const { currentStudy } = study
  const { config } = currentStudy
  const studyID = currentStudy.id
  const { instrumentList, visitNames, wizardTitle, wizardDisplayName } = instruments
  const { instrumentAdvancedOptions } = instrumentReducer
  const list = instrumentList
  const userRole = getUserRoleByStudy(user, studyID)
  const canSeeGeofencedInstruments = [USER_ROLES_MAP.root, USER_ROLES_MAP.dchAdmin].includes(userRole)
  let filteredList = list
  const { cohortMap } = participants

  const hasClinro = !!config?.clinro
  const hasDiary = !!config?.ediary
  const hasMedAdherence = !!config?.medication_adherence
  const hasAudio = true

  if (!canSeeGeofencedInstruments && list.length > 0) {
    filteredList = list.filter(item => !item[0].isGeofenceEnabled && !hiddenTasks.includes(item[0].taskName))
  }

  return {
    ...upload,
    canWrite: user.permissions[studyID].scope.includes('wa'),
    canViewVisits: user.permissions[studyID].scope.includes('rpv'),
    hasVisitSupport: !!currentStudy.config?.visit_support,
    cohortMap,
    componentLoading: loading,
    hasClinro,
    hasDiary,
    hasMedAdherence,
    hasAudio,
    list: filteredList,
    loading: loading.general,
    modules: study.currentStudy.modules,
    searchTerm,
    showWizard: filteredList.length === 0 && !loading.general,
    siteID: getBaseSiteIdFromStudy(study),
    studyID,
    userId: user.id,
    userToken: user.token,
    studyLock,
    currentStudy,
    visitNames,
    instrumentAdvancedOptions,
    wizardTitle,
    wizardDisplayName,
    study: currentStudy,
  }
}

const { initializeBlankSurvey } = surveyActions
const { initializeBlankClinro } = clinroActions
const { initializeBlankDiary } = diaryActions

const { resetInstrumentAdvancedOptions } = schedulerActions

const mapDispatchToProps = {
  ...instrumentsActions,
  ...uploadActions,
  ...instrumentActions,
  ...modalActions,
  ...drawerActions,
  downloadData,
  downloadBulkData,
  initializeBlankSurvey,
  initializeBlankClinro,
  initializeBlankDiary,
  resetInstrument: type => resetInstrument(type),
  fetchVisitTemplates,
  resetInstrumentAdvancedOptions,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { canWrite, loading, list, showWizard } = props
  if (loading) {
    return <Loader />
  }
  if (!canWrite && list.length === 0) {
    return <SadCloud text='There are 0 instruments. Please check back later.' />
  }
  return showWizard ? <InstrumentWizard {...props} /> : <InstrumentsView {...props} />
})
