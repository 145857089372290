import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  NumberInputDropdown,
  WarningText,
  Radio,
  DatePicker,
  Checkbox,
  TimePicker,
  PromptBar,
} from 'components/UIElements'
import { ensureFutureDate, fromUTCMidnightSeconds, isToday, toUTCMidnightSeconds } from 'utils/time'
import moment from 'moment'
import { pluralize } from 'utils/misc'
import dayOptions from '../../constants/schedulerConstants'
import contentConnect from '../../containers/ContentContainer'
import { DEFAULT_DEPLOY_TIME } from '../../../../modules/Instrument'
import InstrumentToInstrumentStart from './InstrumentToInstrumentStartContent'
import VisitStartContent from './VisitStartContent'
import STRINGS from 'utils/strings'

const StartOnContent = ({
  deploy,
  errors,
  expire,
  isRelative,
  updateDeploy,
  updateDeployField,
  updateDeployTime,
  updateExpire,
}) => {
  const { absolute, first_login, time_of_day } = deploy
  let defaultDate = moment()

  // Change to next day if current time is after 9AM
  const isPastDefaultTime = moment(DEFAULT_DEPLOY_TIME, 'HH:mm').isBefore(moment())
  if (isPastDefaultTime) {
    defaultDate = defaultDate.add(moment.duration(1, 'd'))
  }

  const selectedDate = absolute ? fromUTCMidnightSeconds(absolute) : defaultDate
  const [exactDate, setExactDate] = useState(selectedDate)
  const [showDateError, setShowDateError] = useState(true)

  useEffect(() => {
    if (Object.keys(errors).length > 0) setShowDateError(true)
  }, [errors])

  const disabledDays = {
    before: new Date(),
  }

  const isImmediate = 'now' in deploy
  const dateError = errors['schedule.deploy.absolute']

  const onToggleImmediate = () => {
    if (!isImmediate) {
      return () => {
        updateExpire({ never: null })
        updateDeploy({ now: null })
      }
    }
    return () => {
      updateExpire({ never: null })
      updateDeploy({
        absolute: exactDate ? toUTCMidnightSeconds(exactDate) : toUTCMidnightSeconds(defaultDate),
        time_of_day: DEFAULT_DEPLOY_TIME,
      })
    }
  }

  if (expire.absolute) disabledDays.after = fromUTCMidnightSeconds(expire.absolute).toDate()

  const timeOfDayPicker = (preventPastTimes = false) => (
    <TimePicker
      allowEmpty={false}
      disabled={isImmediate}
      preventPastTimes={preventPastTimes}
      format='HH:mm'
      value={time_of_day ? moment(time_of_day, 'HH:mm') : moment(DEFAULT_DEPLOY_TIME, 'HH:mm')}
      onChange={updateDeployTime}
    />
  )

  return (
    <span>
      {isRelative ? (
        <NumberInputDropdown
          value={first_login.interval}
          options={dayOptions}
          onChange={num => {
            updateDeployField('first_login', { interval: Number(num), interval_type: 'days' })
            updateExpire({ never: null })
          }}
        />
      ) : (
        <>
          <div className='flexed start-justified'>
            <DatePicker
              disabled={isImmediate}
              disabledDays={disabledDays}
              initialDate={exactDate}
              onDayChange={date => {
                if (dateError) setShowDateError(false)
                updateDeployField('absolute', toUTCMidnightSeconds(date))
                if (isToday(date)) {
                  updateDeployField('time_of_day', ensureFutureDate(time_of_day, 'HH:mm'))
                }
                setExactDate(date)
              }}
            />
            <span>at</span>
            {timeOfDayPicker()}
            <Checkbox checked={isImmediate} label='Immediately' onClick={onToggleImmediate()} />
            {showDateError && !!dateError && <WarningText text={dateError} />}
          </div>
          {!isImmediate ? (
            <PromptBar className='instrument-note-section' status='medium'>
              <p>{STRINGS.instrumentWillDeployImmediately}</p>
            </PromptBar>
          ) : null}
        </>
      )}
      {isRelative ? `${pluralize(first_login.interval, 'day', 'days', false)} after Participant start date at` : ''}
      {isRelative && timeOfDayPicker()}
    </span>
  )
}

const SameDay = ({ deploy, updateDeployTime }) => {
  const { time_of_day } = deploy
  const isImmediate = 'now' in deploy

  const timeOfDayPicker = (preventPastTimes = false) => (
    <TimePicker
      allowEmpty={false}
      disabled={isImmediate}
      preventPastTimes={preventPastTimes}
      format='HH:mm'
      value={time_of_day ? moment(time_of_day, 'HH:mm') : moment(DEFAULT_DEPLOY_TIME, 'HH:mm')}
      onChange={updateDeployTime}
    />
  )

  return (
    <span>
      On same day Participant starts at
      {timeOfDayPicker()}
    </span>
  )
}

const StartContent = props => {
  const {
    deploy,
    instrumentId,
    instrumentTriggers,
    isDeployedByVisit,
    isDiary,
    isInstrumentToInstrument,
    isRelative,
    isNewInstArch,
    recurring,
    updateDeploy,
    updateDeployField,
    updateDiaryNotificationTrigger,
    updateExpire,
  } = props
  const startsOnSameDay = isRelative && deploy.first_login.interval === 0
  const selected = {
    same_day: startsOnSameDay,
    specific: !startsOnSameDay,
  }

  const isTriggered = isInstrumentToInstrument || isDeployedByVisit
  const hasTriggerAction = instrumentTriggers?.conditions?.length

  useEffect(() => {
    if (isDiary) {
      /**
       * We only want to update the trigger once there is a trigger action in place
       * for the diary.
       */
      if (hasTriggerAction) {
        const { conditions } = instrumentTriggers
        const conditionId = conditions?.[0]?.condition_id
        updateDiaryNotificationTrigger({ deploy, instrumentId, conditionId, recurring })
      }
    }
  }, [deploy, instrumentTriggers?.conditions?.[0]?.condition_id, recurring, hasTriggerAction, isNewInstArch])

  const updateDeployExpire = isSameDay => {
    updateDeploy({
      first_login: { interval: isSameDay ? 0 : 1, interval_type: 'days' },
      time_of_day: DEFAULT_DEPLOY_TIME,
    })
    updateExpire({ never: null })
  }

  const updateDeployTime = momentVal => {
    updateDeployField('time_of_day', momentVal.format('HH:mm'))
  }

  return (
    <div className='date-menu'>
      <p>
        {!isTriggered && (
          <>
            <span>When would you like this instrument to </span>
            <strong>start</strong>
            <span>?</span>
          </>
        )}
      </p>
      {isRelative ? (
        <>
          <Radio
            id='sameday-participant-starts'
            selected={selected.same_day}
            onClick={() => updateDeployExpire(true)}
            content={<SameDay {...props} updateDeployTime={updateDeployTime} />}
          />
          <Radio
            id='day-after-participant-start-radio'
            selected={selected.specific}
            onClick={() => updateDeployExpire(false)}
            content={<StartOnContent {...props} updateDeployTime={updateDeployTime} />}
          />
        </>
      ) : (
        !isTriggered && <StartOnContent {...props} updateDeployTime={updateDeployTime} />
      )}
      {isInstrumentToInstrument && <InstrumentToInstrumentStart {...props} />}
      {isDeployedByVisit && <VisitStartContent {...props} />}
    </div>
  )
}

StartOnContent.propTypes = {
  deploy: PropTypes.shape({
    absolute: PropTypes.number,
    first_login: PropTypes.shape({
      interval: PropTypes.number,
    }),
    time_of_day: PropTypes.string,
  }),
  expire: PropTypes.shape({
    absolute: PropTypes.number,
  }),
  isRelative: PropTypes.bool,
  updateDeployField: PropTypes.func,
  updateDeployTime: PropTypes.func,
  updateExpire: PropTypes.func,
  updateDeploy: PropTypes.func,
  errors: PropTypes.shape({
    'schedule.deploy.absolute': PropTypes.string,
  }),
}

SameDay.propTypes = {
  deploy: PropTypes.shape({
    absolute: PropTypes.number,
    first_login: PropTypes.shape({
      interval: PropTypes.number,
    }),
    time_of_day: PropTypes.string,
  }),
  updateDeployTime: PropTypes.func,
}

StartContent.propTypes = {
  deploy: PropTypes.shape({
    first_login: PropTypes.shape({
      interval: PropTypes.number,
    }),
  }),
  updateDiaryNotificationTrigger: PropTypes.func,
  disabled: PropTypes.bool,
  instrumentId: PropTypes.string,
  instrumentTriggers: PropTypes.shape({
    conditions: PropTypes.array,
  }),
  isDeployedByVisit: PropTypes.bool,
  isDiary: PropTypes.bool,
  isInstrumentToInstrument: PropTypes.bool,
  isNewInstArch: PropTypes.bool,
  isRelative: PropTypes.bool,
  recurring: PropTypes.shape({}),
  updateDeploy: PropTypes.func,
  updateDeployField: PropTypes.func,
  updateExpire: PropTypes.func,
}

export default contentConnect(StartContent)
