import { amazonIncentives } from 'assets/assets'
import STRINGS from 'utils/strings'

/* Special deployment regions */
export const HPI_DEPLOY_REGIONS = {
  China: 'China',
}

/* Determines if the app is being deployed in China */
export const CHINA_DEPLOY = process.env.HPI_DEPLOY_REGION === HPI_DEPLOY_REGIONS.China

export const STUDY_CONFIG_MAP = {
  caseManagement: 'case_management',
  clinro: 'clinro',
  consentRequired: 'consent_required',
  customGeofenceBehavior: 'custom_geofence_behavior',
  customMapButton: 'custom_map_button',
  customOnboarding: 'custom_onboarding',
  dataSyncUI: 'data_sync_ui_enabled',
  forcedInstrumentPriority: 'forced_instrument_priority',
  hasAdvancedFeatures: 'hasAdvancedFeatures',
  medicationAdherence: 'medication_adherence',
  showParticipantUniqueIdentifier: 'show_participant_unique_identifier',
  studyEnd: 'study_end',
  toneOfVoice: 'neutral_reward_enabled',
  training: 'training',
  treasureChestStoreGiftCard: 'super_gem_store_payouts',
  giftCardTypeList: 'gift_card_type_list',
  donations: 'donations',
  giftCards: 'gift_cards',
  superGems: 'super_gems',
  treasureChestGiftCardConfig: 'treasure_chest_gift_card_config',
  giftCardsForUIObj: 'gift_card_for_ui_obj',
  showPreview: 'show_preview',
  visitSupport: 'visit_support',
  virtualVisits: 'virtual_visits',
  movePtps: 'move_participants',
}

export const STUDY_SUBCONFIG_MAP = {
  rewardPropsEditingDisabled: 'reward_props_editing_disabled',
}

export const QUESTION_TYPE_MAP = {
  audioRecording: 'audio_recording',
  date: 'date',
  datetime: 'datetime',
  decimal: 'decimal',
  imageCapture: 'image_capture',
  integer: 'integer',
  introduction: 'introduction',
  likert: 'likert',
  longListSelectOne: 'long_list_select_one',
  matrix: 'matrix',
  multipleField: 'multiple_field',
  numericRatingScale: 'numeric_rating_scale',
  pdf: 'pdf',
  prompt: 'prompt',
  scribble: 'scribble',
  selectMultiple: 'select_multiple',
  selectOne: 'select_one',
  signature: 'signature',
  text: 'text',
  time: 'time',
  timer: 'timer',
  vaScale: 'va_scale',
  vaScale2021: 'va_scale_2021',
  vasHorizontal: 'vas_horizontal',
  foodEntry: 'food_entry',
}

export const CONSENT_ITEM_EDITOR_ENABLED_QUESTION_TYPES_ARR = [
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.text,
  QUESTION_TYPE_MAP.prompt,
  QUESTION_TYPE_MAP.signature,
]

export const QUESTION_TYPES_WITHOUT_QUESTION_NUMBERS = [
  QUESTION_TYPE_MAP.introduction,
  QUESTION_TYPE_MAP.prompt,
  QUESTION_TYPE_MAP.signature,
  QUESTION_TYPE_MAP.pdf,
]

export const SURVEY_QUESTION_TYPE_OPTIONS = [
  { key: 'select_one', value: 'select_one', text: 'Select One' },
  { key: 'select_multiple', value: 'select_multiple', text: 'Select Multiple' },
  // { key: 'long_list_select_one', value: 'long_list_select_one', text: 'Select One (10+ options)' },
  { key: 'text', value: 'text', text: 'Text' },
  { key: 'image_capture', value: 'image_capture', text: 'Photo Submission' },
  { key: 'integer', value: 'integer', text: 'Number' },
  { key: 'decimal', value: 'decimal', text: 'Decimal' },
  { key: 'multiple_field', value: 'multiple_field', text: 'Multiple Field with Labels' },
  { key: 'date', value: 'date', text: 'Date' },
  { key: 'time', value: 'time', text: 'Time' },
  { key: 'datetime', value: 'datetime', text: 'Date & Time' },
  { key: 'likert', value: 'likert', text: 'Likert' },
  { key: 'numeric_rating_scale', value: 'numeric_rating_scale', text: 'Numeric Rating Scale' },
  { key: 'va_scale', value: 'va_scale', text: 'EQ VAS 2018' },
  { key: 'va_scale_2021', value: 'va_scale_2021', text: 'EQ VAS 2021' },
  { key: 'timer', value: 'timer', text: 'Timer' },
  { key: 'vas_horizontal', value: 'vas_horizontal', text: 'VAS (horizontal)' },
  { key: 'audio_recording', value: 'audio_recording', text: 'Audio Capture' },
  { key: 'scribble', value: 'scribble', text: 'Draw/Signature' },
  { key: 'food_entry', value: 'food_entry', text: 'Food Entry' },
]

export const DIARY_QUESTION_TYPE_OPTIONS = [
  { key: 'select_one', value: 'select_one', text: 'Select One' },
  { key: 'select_multiple', value: 'select_multiple', text: 'Select Multiple' },
  { key: 'text', value: 'text', text: 'Text' },
  { key: 'integer', value: 'integer', text: 'Number' },
  { key: 'decimal', value: 'decimal', text: 'Decimal' },
  { key: 'multiple_field', value: 'multiple_field', text: 'Multiple Field with Labels' },
  { key: 'date', value: 'date', text: 'Date' },
  { key: 'time', value: 'time', text: 'Time' },
  { key: 'datetime', value: 'datetime', text: 'Date & Time' },
  { key: 'numeric_rating_scale', value: 'numeric_rating_scale', text: 'Numeric Rating Scale' },
]

export const CLINRO_QUESTION_TYPE_OPTIONS = [
  { key: 'select_one', value: 'select_one', text: 'Select One' },
  { key: 'select_multiple', value: 'select_multiple', text: 'Select Multiple' },
  { key: 'numeric_rating_scale', value: 'numeric_rating_scale', text: 'Numeric Rating Scale' },
  { key: 'matrix', value: 'matrix', text: 'Matrix' },
  { key: 'text', value: 'text', text: 'Text' },
  { key: 'image_capture', value: 'image_capture', text: 'Photo Submission' },
  { key: 'datetime', value: 'datetime', text: 'Date & Time' },
  { key: 'scribble', value: 'scribble', text: 'Draw/Signature' },
]

export const CONSENT_QUESTION_TYPE_OPTIONS = [
  { key: 'select_one', value: 'select_one', text: 'Select One' },
  { key: 'select_multiple', value: 'select_multiple', text: 'Select Multiple' },
  { key: 'text', value: 'text', text: 'Text' },
]

export const QUESTION_TYPES_WITH_SCORING = [
  QUESTION_TYPE_MAP.matrix,
  QUESTION_TYPE_MAP.numericRatingScale,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.selectOne,
]

export const QUESTION_TYPES_WITH_OPT_OUT = [QUESTION_TYPE_MAP.numericRatingScale]

export const QUESTION_TYPES_WITH_IMAGE = [
  QUESTION_TYPE_MAP.audioRecording,
  QUESTION_TYPE_MAP.numericRatingScale,
  QUESTION_TYPE_MAP.scribble,
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.text,
  QUESTION_TYPE_MAP.imageCapture,
  QUESTION_TYPE_MAP.prompt,
  QUESTION_TYPE_MAP.longListSelectOne,
]

export const QUESTION_TYPES_WITH_AUDIO = [
  QUESTION_TYPE_MAP.prompt,
  QUESTION_TYPE_MAP.text,
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.longListSelectOne,
]

export const QUESTION_TYPES_WITH_VIDEO = [
  QUESTION_TYPE_MAP.prompt,
  QUESTION_TYPE_MAP.text,
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.selectMultiple,
  QUESTION_TYPE_MAP.longListSelectOne,
]

export const QUESTION_TYPES_WITH_AUTO_EXPANDING_ITEM_EDITOR = [
  QUESTION_TYPE_MAP.selectOne,
  QUESTION_TYPE_MAP.signature,
  QUESTION_TYPE_MAP.prompt,
]

const TASK_NAME_MAP = {
  rapidNaming: 'rapid_naming',
}

export const GEOFENCEABLE_TASKS = ['NYUChatbot', 'JanssenChatbot']

export const TASKS_WITH_AUDIO_PERMISSION = [TASK_NAME_MAP.rapidNaming]
export const TASKS_WITH_STORAGE_PERMISSION = [TASK_NAME_MAP.rapidNaming]

export const MOBILE_APP_PERMISSION_NAMES_MAP = {
  audioRecording: 'audio_recording',
  storageAccess: 'storage_access',
}

export const NUMERIC_CHOICE_QUESTION_TYPES = [QUESTION_TYPE_MAP.likert, QUESTION_TYPE_MAP.numericRatingScale]

export const SELECT_QUESTION_TYPES = [QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple]

export const QUESTION_TYPES_WITH_CHOICE_IMAGES = [QUESTION_TYPE_MAP.selectOne, QUESTION_TYPE_MAP.selectMultiple]

export const QUESTION_TYPES_WITH_CHOICES = [
  ...NUMERIC_CHOICE_QUESTION_TYPES,
  ...SELECT_QUESTION_TYPES,
  QUESTION_TYPE_MAP.longListSelectOne,
  QUESTION_TYPE_MAP.multipleField,
]

export const SCALE_QUESTION_TYPES = [
  QUESTION_TYPE_MAP.vaScale,
  QUESTION_TYPE_MAP.vaScale2021,
  QUESTION_TYPE_MAP.vasHorizontal,
]

export const QUESTION_TYPES_WITH_HINTS = [QUESTION_TYPE_MAP.vasHorizontal, QUESTION_TYPE_MAP.vaScale2021]
export const QUESTION_TYPES_WITH_CAREGIVER_HINTS = [QUESTION_TYPE_MAP.vaScale2021]

export const CONSENT_QUESTION_TYPES_WITHOUT_LOGIC = [QUESTION_TYPE_MAP.pdf, QUESTION_TYPE_MAP.signature]

export const INPUT_VALIDATION_TYPE_MAP = {
  absDate: 'absolute_date',
  absTime: 'absolute_time',
  decimalPlaces: 'decimal_places',
  max: 'max',
  min: 'min',
  relative: 'relative',
  acceptFutureValues: 'accept_future_values',
}

export const USER_ROLES_MAP = {
  root: 'Root',
  dchAdmin: 'DCH Admin',
  dchPii: 'DCH PII',
  dchStudyCoordinator: 'DCH Study Coordinator',
  studyCoordinator: 'Study Coordinator',
  investigator: 'Investigator',
  sponsor: 'Sponsor',
  researcher: 'Researcher',
  enroller: 'Enroller',
  siteCoordinator: 'Site Coordinator',
  auditor: 'Auditor',
  caseManager: 'Case Manager',
  clinicianI: 'Clinical Staff Level 1',
  clinicianII: 'Clinical Staff Level 2',
  clinicianIII: 'Clinical Staff Level 3',
  clinicalCoordinator: 'Clinical Coordinator',
  supportAgent: 'Support Agent',
  reporter: 'Reporter',
  siteUserManagementAdmin: 'Site and User Management Admin',
  impartialWitness: 'Impartial Witness',
}

export const ROLE_NAME_ID_MAP = {
  Root: 1,
  DCH_Admin: 2,
  Study_Coordinator: 4,
  Investigator: 4,
  Sponsor: 5,
  Researcher: 6,
  Enroller: 7,
  Site_Coordinator: 8,
  Auditor: 9,
  Case_Manager: 10,
  Clinical_Staff_Level_1: 11,
  Clinical_Staff_Level_2: 12,
  Clinical_Staff_Level_3: 13,
  Clinical_Coordinator: 16,
  Support_Agent: 17,
  Reporter: 18,
  Site_User_Management_Admin: 19,
  Impartial_Witness: 20,
}

export const USER_ROLES_WITH_PII_NAME_ID_MAP = {
  root: { name: 'Root', id: 1 },
  dchPii: { name: 'DCH PII', id: 14 },
  studyCoordinator: { name: 'Study Coordinator', id: 4 },
  investigator: { name: 'Investigator', id: 4 },
  enroller: { name: 'Enroller', id: 7 },
  siteCoordinator: { name: 'Site Coordinator', id: 8 },
  caseManager: { name: 'Case Manager', id: 10 },
  clinicalCoordinator: { name: 'Clinical Coordinator', id: 16 },
}

export const SITE_ROLE_LIST = [
  USER_ROLES_MAP.enroller,
  USER_ROLES_MAP.siteCoordinator,
  USER_ROLES_MAP.caseManager,
  USER_ROLES_MAP.clinicianI,
  USER_ROLES_MAP.clinicianII,
  USER_ROLES_MAP.clinicianIII,
  USER_ROLES_MAP.clinicalCoordinator,
  USER_ROLES_MAP.siteUserManagementAdmin,
  USER_ROLES_MAP.impartialWitness,
]

export const AVAILABLE_USER_OPTIONS = [
  // { key: 'root', text: 'Root', value: 'root', role_id: 1 },
  // { key: 'dchAdmin', text: 'DCH Admin', value: 'dchAdmin', role_id: 2 },
  // { key: 'studyCoordinator', text: 'Study Coordinator / Investigator', value: 'studyCoordinator', role_id: 3 },
  // { key: 'investigator', text: 'Investigator', value: 'investigator', role_id: 4 },
  { key: 'studyCoordinator', text: 'Study Coordinator / Investigator', value: 'studyCoordinator', role_id: 4 },
  { key: 'sponsor', text: 'Sponsor', value: 'sponsor', role_id: 5 },
  { key: 'researcher', text: 'Researcher', value: 'researcher', role_id: 6 },
  { key: 'enroller', text: 'Enroller', value: 'studyManager', role_id: 7 },
  { key: 'siteCoordinator', text: 'Site Coordinator', value: 'siteCoordinator', role_id: 8 },
  { key: 'auditor', text: 'Auditor', value: 'auditor', role_id: 9 },
  { key: 'caseManager', text: 'Case Manager', value: 'caseManager', role_id: 10 },
  { key: 'clinRoI', text: 'Clinician I', value: 'clinRoI', role_id: 11 },
  { key: 'clinRoII', text: 'Clinician II', value: 'clinRoII', role_id: 12 },
  { key: 'clinRoIII', text: 'Clinician III', value: 'clinRoIII', role_id: 13 },
  { key: 'clinicalCoordinator', text: 'Clinical Coordinator', value: 'clinicalCoordinator', role_id: 16 },
  { key: 'supportAgent', text: 'Support', value: 'supportAgent', role_id: 17 },
  { key: 'reporter', text: 'Reporter', value: 'reporter', role_id: 18 },
  {
    key: 'siteUserManagementAdmin',
    text: 'Site and User Management Admin',
    value: 'siteUserManagementAdmin',
    role_id: 19,
  },
  { key: 'impartialWitness', text: 'Impartial Witness', value: 'impartialWitness', role_id: 20 },
]

export const CLINICIAN_ROLES_LIST = [
  USER_ROLES_MAP.clinicianI,
  USER_ROLES_MAP.clinicianII,
  USER_ROLES_MAP.clinicianIII,
  USER_ROLES_MAP.clinicalCoordinator,
  USER_ROLES_MAP.impartialWitness,
]

export const USER_ROLE_ID_MAP = {
  root: 1,
  dchAdmin: 2,
  studyCoordinator: 3,
  investigator: 4,
  sponsor: 5,
  researcher: 6,
  enroller: 7,
  siteCoordinator: 8,
  auditor: 9,
  caseManager: 10,
  clinRoI: 11,
  clinRoII: 12,
  clinRoIII: 13,
  dchPii: 14,
  dchStudyCoordinator: 15,
  clinicalCoordinator: 16,
  supportAgent: 17,
  reporter: 18,
  siteUserManagementAdmin: 19,
  impartialWitness: 20,
}

export const SITE_USER_MANAGEMENT_ADMIN_DELETION_FORBIDDEN = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.studyCoordinator,
  USER_ROLE_ID_MAP.investigator,
  USER_ROLE_ID_MAP.auditor,
  USER_ROLE_ID_MAP.dchPii,
  USER_ROLE_ID_MAP.dchStudyCoordinator,
  USER_ROLE_ID_MAP.supportAgent,
]

export const STUDY_ROLE_IDS = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.studyCoordinator,
  USER_ROLE_ID_MAP.investigator,
  USER_ROLE_ID_MAP.sponsor,
  USER_ROLE_ID_MAP.researcher,
  USER_ROLE_ID_MAP.auditor,
  USER_ROLE_ID_MAP.dchPii,
  USER_ROLE_ID_MAP.dchStudyCoordinator,
  USER_ROLE_ID_MAP.reporter,
  USER_ROLE_ID_MAP.siteUserManagementAdmin,
]
export const SITE_ROLE_IDS = [
  USER_ROLE_ID_MAP.enroller,
  USER_ROLE_ID_MAP.siteCoordinator,
  USER_ROLE_ID_MAP.caseManager,
  USER_ROLE_ID_MAP.clinRoI,
  USER_ROLE_ID_MAP.clinRoII,
  USER_ROLE_ID_MAP.clinRoIII,
  USER_ROLE_ID_MAP.clinicalCoordinator,
  USER_ROLE_ID_MAP.supportAgent,
  USER_ROLE_ID_MAP.siteUserManagementAdmin,
  USER_ROLE_ID_MAP.impartialWitness,
]

export const ROOT_DCH_ADMIN_ROLE_ID_LIST = [USER_ROLE_ID_MAP.root, USER_ROLE_ID_MAP.dchAdmin]

export const IMPARTIAL_WITNESS_ALLOWED_CREATORS_LIST = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.studyCoordinator,
  USER_ROLE_ID_MAP.investigator,
  USER_ROLE_ID_MAP.dchPii,
  USER_ROLE_ID_MAP.dchStudyCoordinator,
]

export const CLINICIAN_ROLES_ID_LIST = [
  USER_ROLE_ID_MAP.clinRoI,
  USER_ROLE_ID_MAP.clinRoII,
  USER_ROLE_ID_MAP.clinRoIII,
  USER_ROLE_ID_MAP.clinicalCoordinator,
  USER_ROLE_ID_MAP.impartialWitness,
]

export const HIDE_PERMISSION_TABLE_ROLES_LIST = []

export const USER_ROLE_IDS_WITH_LIMITED_WRITE_USER_PERMISSIONS = [
  USER_ROLE_ID_MAP.siteCoordinator,
  USER_ROLE_ID_MAP.caseManager,
  USER_ROLE_ID_MAP.clinicalCoordinator,
  USER_ROLE_ID_MAP.siteUserManagementAdmin,
]

export const USER_ROLE_IDS_THAT_CAN_BE_CREATED_BY_SITE_ADMIN = [
  USER_ROLE_ID_MAP.enroller,
  USER_ROLE_ID_MAP.siteCoordinator,
  USER_ROLE_ID_MAP.caseManager,
  USER_ROLE_ID_MAP.clinRoI,
  USER_ROLE_ID_MAP.clinRoII,
  USER_ROLE_ID_MAP.clinRoIII,
  USER_ROLE_ID_MAP.reporter,
]

export const USER_ROLE_IDS_CAN_EDIT_CONSENT_SITES = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.studyCoordinator,
  USER_ROLE_ID_MAP.investigator,
  USER_ROLE_ID_MAP.sponsor,
  USER_ROLE_ID_MAP.researcher,
  USER_ROLE_ID_MAP.auditor,
  USER_ROLE_ID_MAP.siteUserManagementAdmin,
]

export const USER_ROLE_IDS_CAN_SEE_FLAGSMITH_CONFIGURATION = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.dchStudyCoordinator,
]

export const USER_ROLE_IDS_CAN_DOWNLOAD_USER_SITE_LIST = [
  USER_ROLE_ID_MAP.root,
  USER_ROLE_ID_MAP.dchAdmin,
  USER_ROLE_ID_MAP.dchPii,
]

export const USER_ROLES_WITH_SCHEDULE_VIRTUAL_VISIT_PERMISSION = [
  USER_ROLES_MAP.root,
  USER_ROLES_MAP.studyCoordinator,
  USER_ROLES_MAP.investigator,
  USER_ROLES_MAP.siteCoordinator,
  USER_ROLES_MAP.caseManager,
  USER_ROLES_MAP.clinicalCoordinator,
]

export const USER_ROLES_WITH_SCHEDULE_ADHOC_VISIT_PERMISSION = [
  USER_ROLES_MAP.siteCoordinator,
  USER_ROLES_MAP.clinicalCoordinator,
]

/**
 * Site and User Management Admins and Reporters are commonly restricted in many pages
 */
export const USER_ROLES_WITH_RESTRICTED_ACCESS = [USER_ROLES_MAP.siteUserManagementAdmin, USER_ROLES_MAP.reporter]
export const USER_ROLE_IDS_WITH_RESTRICTED_ACCESS = [
  USER_ROLE_ID_MAP.siteUserManagementAdmin,
  USER_ROLE_ID_MAP.reporter,
]

// Sites restrictions
export const USER_ROLES_WITH_BLOCKED_SITES_ACCESS = [USER_ROLES_MAP.supportAgent, USER_ROLES_MAP.reporter]
export const USER_ROLE_IDS_WITH_BLOCKED_SITES_ACCESS = [USER_ROLE_ID_MAP.supportAgent, USER_ROLE_ID_MAP.reporter]

// Virtual visit restrictions
export const USER_ROLES_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS = [
  USER_ROLES_MAP.enroller,
  ...USER_ROLES_WITH_RESTRICTED_ACCESS,
]
export const USER_ROLE_IDS_WITH_BLOCKED_VIRTUAL_VISIT_ACCESS = [
  USER_ROLE_ID_MAP.enroller,
  ...USER_ROLE_IDS_WITH_RESTRICTED_ACCESS,
]

export const QUESTION_ATTRIBUTE_MAP = {
  disableButtons: 'disable_buttons',
  disableGreeting: 'disable_greeting',
  hasOptOut: 'hasOptOut',
  optOut: 'opt_out',
  inReview: 'in_review',
  maxPhotoLimit: 'max_photo_limit',
}

export const INSTRUMENT_TYPE_MAP = {
  article: 'ARTICLE',
  consent: 'CONSENT',
  diary: 'EDIARY',
  clinro: 'CLINRO',
  survey: 'SURVEY',
  task: 'TASK',
  announcement: 'ANNOUNCEMENT',
}

export const INSTRUMENT_TYPES_WITH_SCORING = [
  INSTRUMENT_TYPE_MAP.clinro,
  INSTRUMENT_TYPE_MAP.diary,
  INSTRUMENT_TYPE_MAP.survey,
]

export const INSTRUMENT_TYPES_WITH_TRANSLATIONS = [
  INSTRUMENT_TYPE_MAP.diary,
  INSTRUMENT_TYPE_MAP.survey,
  INSTRUMENT_TYPE_MAP.article,
]

export const REWARD_TYPE_MAP = {
  cash: 'cash',
  gems: 'gems',
  superGems: 'super_gems',
}

export const REWARD_NAME_MAP = {
  [REWARD_TYPE_MAP.cash]: 'dollars',
  [REWARD_TYPE_MAP.gems]: 'gems',
  [REWARD_TYPE_MAP.superGems]: 'supergems',
}

export const DURATION_TYPE_MAP = {
  days: 'days',
  weeks: 'weeks',
}

export const CONSENT_TYPE_MAP = {
  consent: 'consent',
  reconsent: 're_consent',
  addendum: 'addendum',
}

export const CONSENT_TYPES = {
  [CONSENT_TYPE_MAP.consent]: 'Consent',
  [CONSENT_TYPE_MAP.reconsent]: 'Reconsent',
  [CONSENT_TYPE_MAP.addendum]: 'Addendum',
}

export const CONSENT_OPTIONS_IDS = {
  consentBuilder: 'consent-builder',
  pdfUpload: 'pdf-upload',
}

export const VISIT_TIMELINE_EVENT_MAP = {
  cancelled: 'cancelled',
  createdDate: 'created_date',
  ptpConnected: 'participant_connected',
  ptpDisconnected: 'participant_disconnected',
  virtualVisitBegins: 'virtual_visit_begins',
  virtualVisitEnds: 'virtual_visit_ends',
}
export const VISIT_INFO_TYPE_MAP = {
  type: 'type',
  length: 'length',
  instrumentsCompleted: 'instruments_completed',
  notifications: 'notifications',
}

export const VISIT_NOTY_TYPE_MAP = {
  announcement: 'Announcement',
  email: 'Email',
  notification: 'Notification',
  push_notification: 'Push notification',
}

export const CURRENCY_MAP = {
  aud: 'A$',
  AUD: 'A$',
  cad: 'C$',
  CAD: 'C$',
  eur: '€',
  EUR: '€',
  gbp: '£',
  GBP: '£',
  jpy: '¥',
  JPY: '¥',
  mxp: 'Mex$',
  MXP: 'Mex$',
  usd: '$',
  USD: '$',
}

export const INSTRUMENT_PATH_MAP = {
  createClinro: 'createclinro',
  createSurvey: 'createinstrument',
  editClinro: 'editclinro',
  editSurvey: 'editinstrument',
  scoring: 'scoring',
}

export const PAYOUT_TYPE_THUMBNAIL_MAP = {
  amazon_giftcard: amazonIncentives,
}

export const PAYMENT_DELIVERY_METHODS_MAP = {
  email: 'email',
  announcement: 'announcement',
}

export const FA_ICON_MAP = {
  audio: 'fa-solid fa-play',
  document: 'fa-regular fa-file',
  download: 'fa-solid fa-download',
  externalLink: 'fa-solid fa-arrow-up-right-from-square',
  fileDownload: 'fa-solid fa-file-arrow-down',
  plus: 'fa-solid fa-plus',
  trash: 'fa-solid fa-trash-can',
  verticalEllipsis: 'fa-solid fa-ellipsis-vertical',
  video: 'fa-solid fa-play',
  windowMin: 'fa-solid fa-window-minimize',
  windowMax: 'fa-solid fa-up-right-and-down-left-from-center',
  x: 'fa-solid fa-xmark',
}

export const FILE_MIME_TYPE_MAP = {
  '.csv': 'text/csv',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.json': 'application/json',
  '.m4v': 'video/mp4',
  '.mov': 'video/quicktime',
  '.mp3': 'audio/mpeg',
  '.mp4': 'video/mp4',
  '.pdf': 'application/pdf',
  '.png': 'image/png',
  '.wav': 'audio/wav',
  '.webm': 'video/webm',
}

export const SECURITY_KEY_MAP = {
  attempt_num: 'attempt_num',
  expiration: 'expiration',
  lockout_time: 'lockout_time',
  reuse_history: 'reuse_history',
  session_timeout: 'session_timeout',
}

export const TRIGGER_TYPE_MAP = {
  score: 'get_instrument_scores',
  compliance: 'get_instrument_compliance',
  newActivityNotification: 'send_new_activity_notification',
  singleScore: 'get_instrument_score_by_name',
}

export const BLOB_TYPE_MAP = {
  csv: 'text/csv',
  zip: 'application/x-zip-compressed',
}

export const GROUP_TYPE_MAP = {
  age: 'age',
  all: 'all_users',
  cohort: 'study_group',
  cohorts: 'study_groups',
  sites: 'sites',
}

export const GROUP_LABEL_MAP = {
  study_groups: 'Tracks',
  sites: 'Sites',
}

export const DEPLOYMENT_KEYS = {
  abs: 'absolute',
  chainDeployByVisit: 'chain_deployment_by_ss_visit',
  chainDeployOffset: 'chain_deploy_offset',
  chainExpireOffset: 'chain_expire_offset',
  daysOffset: 'days_offset',
  expireType: 'expire_type',
  firstLogin: 'first_login',
  never: 'never',
  now: 'now',
  relativeToSendIdeal: 'relative_to_send_ideal',
  visitId: 'visit_id',
  visitScheduleId: 'visit_schedule_id',
}

export const DEPLOYMENT_VALUES = {
  completionOfVisit: 'completion_of_visit',
  receiptOfArticle: 'receipt_of_article',
}

export const RELATIVE_DATE_INTERVAL_MAP = { preceding: 'preceding_interval', succeeding: 'succeeding_interval' }

export const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm

export const { GOOGLE_MAPS_API_KEY } = process.env

export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`

export const { FLAGSMITH_API_KEY } = process.env

export const DEFAULT_LANG = 'en_US'

export const OPERATOR_OPTIONS = [
  { key: '<', value: '<', text: 'less than' },
  { key: '<=', value: '<=', text: 'less than or equal to' },
  { key: '==', value: '==', text: 'equal to' },
  { key: '>', value: '>', text: 'greater than' },
  { key: '>=', value: '>=', text: 'greater than or equal to' },
]

export const LOGICAL_OPERATOR_CHART = {
  '<': 'less than',
  '<=': 'less than or equal to',
  '==': 'equal to',
  '>': 'greater than',
  '>=': 'greater than or equal to',
}

export const TOOLTIP_MENU = [
  { key: 'surveyCount', text: 'Surveys', icon: 'far fa-file-alt', extra: {} },
  { key: 'taskCount', text: 'Tasks', icon: 'fas fa-gamepad', extra: {} },
  { key: 'caseManagement', text: 'Case management', icon: 'fas fa-briefcase', path: 'cases' },
  { key: 'geofencing', text: 'Geofencing', icon: 'fas fa-map-marker-alt', path: 'geofences' },
  { key: 'clinro', text: 'Clinician reported outcomes', icon: 'fas fa-clipboard-list', path: 'instruments' },
  {
    key: 'medicationAdherence',
    text: 'Medication adherence',
    icon: 'fas fa-pills',
    path: 'notifications/conditional/medication_adherence',
  },
  { key: 'superGems', text: 'Treasure chest store', icon: 'far fa-gem', path: 'payments' },
]

export const TOOLTIP_MENU_MAP = {
  caseManagement: 'caseManagement',
  geofencing: 'geofencing',
  clinro: 'clinro',
  medicationAdherence: 'medicationAdherence',
  superGems: 'superGems',
}

export const CONDITION_RESOURCE_TYPE = {
  medicationAdherence: 'medication_adherence',
}

export const CONDITION_RESOURCE_FUNCTION_TYPE = {
  getMedicationCount: 'get_medication_count',
}

export const CONDITION_ACTION_TYPE = {
  sendPushNotification: 'send_push_notification',
}

export const PARTICIPANT_DATA_FIELDS_MAP = {
  dob: 'date_of_birth',
}

export const STUDY_ROUTES = {
  communication: 'communication',
}

export const SIGNATURE_TYPE_MAP = {
  draw: 'draw',
  text: 'text',
  image: 'image',
}

export const CONSENT_STATUS = {
  readyToBeScheduled: { key: 'ready_to_be_scheduled', component: 'Ready to be scheduled' },
  inProgress: { key: 'in_progress', component: 'In Progress' },
}

export const CONSENT_SIGNEE_MAP = {
  participant: 'participant',
  caregiver: 'caregiver',
  witness: 'witness',
  clinro: 'clinro',
}

export const PARTICIPANT_CONSENT_STATUS_MAP = {
  complete: { text: STRINGS.complete, sortValue: 1 },
  awaiting_clinro: { text: STRINGS.awaitingPI, longText: STRINGS.awaitingAdditionalSignature, sortValue: 2 },
  awaiting_admin: { text: STRINGS.awaitingAdmin, sortValue: 3 },
  awaiting_witness: { text: STRINGS.awaitingIw, longText: STRINGS.awaitingAdditionalSignature, sortValue: 4 },
  opened: { text: STRINGS.opened, sortValue: 5 },
  unopened: { text: STRINGS.unopened, sortValue: 6 },
  declined: { text: STRINGS.declined, sortValue: 7 },
}

export const MODAL_CONTENT_MAP = {
  actionCannotUndone: 'This action cannot be undone. Are you sure you want to delete',
  actionInvalidate:
    'This action will invalidate some logic conditions. These conditions will be removed, please confirm.',
  addStudyParticipant:
    'Clicking confirm will add this participant to your study. Ensure all participant consent has been completed before continuing.',
  approveAll: 'Are you sure you want to approve all? This action cannot be undone.',
  autoApprove: 'Would you like to turn on auto-approve?',
  cancelChanges: 'Are you sure you want to cancel? You’ll lose any changes you’ve made.',
  cannotBeUndone: 'Are you sure you want to delete this study and its associated content? This cannot be undone.',
  cancelQuestion: 'Are you sure you want to cancel',
  closeCase: 'Are you sure you want to close this case?',
  closeOut: 'Are you sure you want to close out of this?',
  deleteItem: 'Are you sure you want to delete this item?',
  deleteUser: 'Are you sure you want to delete this user? This cannot be undone.',
  deleteTrack: groupName => `Are you sure you want to delete Track ${groupName}?`,
  deleteTrackDescriptionStart: 'This will delete the track from the ',
  wholeStudy: 'whole study',
  deleteTrainingMaterial: 'Are you sure you want to delete this training?',
  deleteTrackDescriptionEnd: groupName =>
    `All participants in the study will be removed from Track ${groupName} and any deployment based on Track ${groupName} assignment will discontinue (instrument, communication, consent, etc.).`,
  deployConsent: 'Are you sure you want to deploy this consent?',
  deployInstrument: 'Are you sure you want to deploy this instrument?',
  discardPaymentAccount: 'Are you sure you want to discard this payment account?',
  logicInvalidatedByDeletion:
    'Some logic conditions will be invalidated by this deletion and will be removed. Please confirm.',
  logicInvalidatedByMove: 'Some logic conditions will be invalidated by this move and will be removed. Please confirm.',
  logicInvalidatedByType:
    'Some logic conditions will be invalidated by this type change and will be removed. Please confirm.',
  makeChange:
    'Are you sure you want to make this change?\n\nThis change may affect logic conditions assigned to other answer choices.',
  markAllDonations: 'Are you sure you want to mark all the donations as donated? This action cannot be undone.',
  newInvitation: 'A new invitation will be emailed to this participant. Would you like to send?',
  participantNewPassword: 'Participant will be sent a new temporary password on reset. Please confirm.',
  pleaseConfirm: 'Please confirm',
  saveChanges: 'Would you like to save your changes?',
  shuffleQuestionOrder:
    'Are you sure you want to shuffle the question order? This action is irreversible and will remove all question logic.',
  translationsUploadPrompt:
    'Existing languages have been replaced with the translations included in the CSV file. New languages have been appended. Languages not supported by the study have been discarded.',
  undone: 'This action cannot be undone.',
  updateConsent: 'Are you sure you want to update the consent?',
  userNewPassword: 'User will be sent a new temporary password on reset. Please confirm.',
  leavingThisPage: 'Leaving this page?',
  closingThisPage: 'Closing this page will delete participant details entered on this page. ',
  downloadAllAudioFiles: 'Download all audio files',
  downloadAllAudioFilesSubtitle:
    'We will compress the audio files and send a zip file to your email address associated with DCH account.',
  downloadAllAudioFilesCaption: 'This may take a couple of minutes depending on the size of the audio files.',
  didVisitHappen: 'Did this visit happen?',
}

export const MODAL_BUTTONS_MAP = {
  addExistingUser: 'Add Existing User',
  cancel: 'Cancel',
  cancelVisit: 'Cancel Visit',
  closeCase: 'Close case',
  confirm: 'Confirm',
  createDiary: 'Create Diary',
  createInstrument: 'Create Instrument',
  createSurvey: 'Create Survey',
  createParticipantInstrument: 'Create Participant Instrument',
  disable: 'Disable',
  enable: 'Enable',
  goInstrumentPage: 'Go to Instruments Page',
  markAll: 'Mark all',
  nevermind: 'Nevermind',
  next: 'Next',
  no: 'No',
  okay: 'Okay',
  proceed: 'Proceed',
  yes: 'Yes',
  yesUpdateConsent: 'Yes, Update Consent',
  confirmAndClose: 'Confirm and Close',
}

export const VISIT_START_HOUR = 9
export const VISIT_START_MINUTE = 0
export const VISIT_START_SECOND = 0

export const MODAL_CLASSES_MAP = {
  accountDeletion: 'account-deletion',
  accountSettings: 'account-settings',
  charityPreview: 'charity-preview',
  closeCase: 'close-case',
  confirmation: 'confirmation-modal',
  createInstrument: 'create-instrument-modal',
  deleteParticipant: 'delete-participant',
  diaryDownload: 'diary-download-form',
  donation: 'donation-form',
  existingUserPrompt: 'existing-user-prompt',
  spacious: 'spacious',
  manualConsentConfirmation: 'manual-consent-confirmation',
  none: 'display-none',
  payout: 'payout-type-modal',
  photoSubmissionUploader: 'photo-submission-uploader',
  uploadMedia: 'media-upload-modal',
}

export const SIGNATURE_TIPS = {
  camera_tip: 'Try to fit your signature, printed name, date, and version number within the frame.',
  clinro_camera_tip:
    'Try to fit the image within the frame above and ensure the center of the photo is aligned with the crosshair.',
}

export const DATE_FORMAT_MAP = {
  main: 'DD MMM YYYY',
  mainWithTime: 'DD MMM YYYY HH:mm',
  mainWithDateTime: 'DD MMM YYYY h:mm A',
  mainWithFullTime: 'DD MMM YYYY HH:mm:ss',
  datePicker: 'YYYY-MM-DD',
  datePickerWithFullTime: 'YYYY-MM-DD HH:mm:ss',
}

export const DEFAULT_VISIT_START_HOUR = 9
export const DEFAULT_VISIT_START_MINUTE = 0

export const HOURS_IN_DAY = 24
export const DAYS_IN_WEEK = 7
export const HOURS_IN_WEEK = HOURS_IN_DAY * DAYS_IN_WEEK
export const MINUTES_IN_HOUR = 60
export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY
export const MINUTES_IN_DAY = HOURS_IN_DAY * MINUTES_IN_HOUR

export const DEFAULT_NA_STRING_SORT_VAL = ''

export const INSIGHTS_DASHBOARD_KEY_MAP = {
  consentDetail: `informed_consent_detail`,
  dataSurveillance: 'data_surveillance',
  demographics: 'demographics',
  instrumentDetail: 'instrument_detail',
  location: 'location',
  riskEngagement: 'risk_engagement',
  studySpecific: 'study_specific',
}

export const DEFAULT_INSIGHTS_DASHBOARD_CONFIG = {
  enabled: true,
  custom_buttons: [
    INSIGHTS_DASHBOARD_KEY_MAP.consentDetail,
    INSIGHTS_DASHBOARD_KEY_MAP.dataSurveillance,
    INSIGHTS_DASHBOARD_KEY_MAP.demographics,
    INSIGHTS_DASHBOARD_KEY_MAP.instrumentDetail,
    INSIGHTS_DASHBOARD_KEY_MAP.location,
    INSIGHTS_DASHBOARD_KEY_MAP.riskEngagement,
  ],
  study_level_role_access: {
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
    11: true,
    12: true,
    13: true,
    15: true,
    16: true,
    18: true,
    19: true,
    20: true,
  },
}

export const DEFAULT_EMAIL_CONFIG = {
  display_label: 'Email',
  type: 'email',
  metadata: {
    internal_key: 'email',
    custom_validations: '',
    is_custom: false,
    default_value: '',
    can_update: true,
  },
}

export const NOT_APPLICABLE_PLACEHOLDER = 'n/a'

export const USER_SCOPES = {
  ca: {
    code: 'ca',
    name: 'ClinRO - Activities',
    note: 'Allow user to view/complete instruments per Participant on ClinRO platform',
  },
  cp: {
    code: 'cp',
    name: 'ClinRO - Participants',
    note: 'Allow user to search for Participant on ClinRO platform',
  },
  ft: {
    code: 'ft',
    name: 'Interact with features',
    note: 'Modify the features of a study, e.g. modify white label custom logo',
  },
  mp: {
    code: 'mp',
    name: 'Rollover Participant',
    note: 'Allows moving a participant from one study to another Participants (see items 11-13)',
  },
  ra: {
    code: 'ra',
    name: 'Read Activities',
    note:
      'Allows user to view/download surveys, tasks, eConsent forms, articles, Clinical Instruments, geofences, sites',
    text: {
      0: 'View/download surveys, tasks, eConsent forms, articles, Clinical Instruments, geofences, sites',
    },
  },
  rc: { code: 'rc', name: 'Read Consents', note: 'Read consents' },
  rd: {
    code: 'rd',
    name: 'Read Data',
    note: 'Allows to view/download data sent by participants, including compliance dashboards',
    text: {
      0: 'View/download data sent by participants',
      1: 'including compliance dashboards',
    },
  },
  roa: {
    code: 'roa',
    name: 'Read Only Activities',
    note:
      'Allow users to only read any instrument such as: surveys, tasks, eConsent forms, articles, Clinical Instruments, geofences, sites',
    text: {
      0: 'Read any instrument such as: surveys, tasks, eConsent forms, articles, Clinical Instruments, geofences, sites',
      1: 'Consent documents can be routed to the Impartial Witness for review and signature through ClinRo',
    },
  },
  rp: {
    code: 'rp',
    name: 'Read Participants',
    note:
      'View Participant Information (list and Individual). View Cohorts. Allows user to view payments. View sites. Can view cases and virtual visits.',
  },
  'rp-no-pii': {
    code: 'rp-no-pii',
    name: 'Read Participants (No PII)',
    note: 'Same as rp. No PII implies that PII fields will be obfuscated (ex initials only)',
  },
  rpc: {
    code: 'rpc',
    name: 'Read Participant Communications',
    note: 'Allow users to only read announcements and SMSs',
    text: {
      0: 'Read announcements and SMS',
    },
  },
  rpe: {
    code: 'rpe',
    name: 'Resending Participant Emails',
    note: 'Allow users to resend invites and reset passwords for participant',
    text: {
      0: 'Reset participant password',
      1: 'Resend invites for participants',
    },
  },
  rppv: {
    code: 'rppv',
    name: 'Read IRL Participant Visits',
    note: 'Allow users to read participant visits',
    text: {
      0: 'Read participant visits',
    },
  },
  'rppv-no-pii': {
    code: 'rppv-no-pii',
    name: 'Read IRL Participant Visits (No PII)',
    note:
      'Allow users to read participant visits. No PII implies that PII fields will be obfuscated (ex initials only)',
    text: {
      0: 'Read participant visits with no PII',
    },
  },
  rpv: {
    code: 'rpv',
    name: 'Read IRL Visits',
    note: 'Allow users to read physical visit templates',
    text: {
      0: 'Read physical visit templates',
    },
  },

  rs: {
    code: 'rs',
    name: 'Read Study',
    note: 'View study configuration properties (data enabled, cycle durations, reward type)',
  },
  ru: {
    code: 'ru',
    name: 'Read User',
    note:
      'Ability to view information about platform users. Recording behavior being used for the last 2 years: Study coordinator can see every one. Everyone else that has permission to see users, will only see users on the same site or below. (2020-07-21)',
  },
  wa: {
    code: 'wa',
    name: 'Write Activities',
    note:
      'Allows users to create, edit and delete instruments (surveys, tasks). User with this permission can create, edit, and delete geofences.',
  },
  wp: {
    code: 'wp',
    name: 'Write Participants',
    note: 'Allows users to edit and delete participants. Edit and delete cohorts.',
    text: {
      0: 'Edit and delete participants',
      1: 'Edit and delete cohorts',
    },
  },
  wppv: {
    code: 'wppv',
    name: 'Write IRL Participant Visits',
    note: 'Allow users to create, modify and execute participant visits, includes adhoc irl visits',
    text: {
      0: 'Create, modify and execute participant visits',
      1: 'includes adhoc irl visits',
    },
  },
  wpv: {
    code: 'wpv',
    name: 'Write IRL Visits',
    note: 'Allow users to create, modify and execute physical visit templates',
    text: {
      0: 'Create, modify and execute physical visit templates',
    },
  },
  wu: {
    code: 'wu',
    name: 'Write User and Study',
    note:
      'Allows users to create new, update and delete system users. Permission to create a new study. Only study coordinators and above can create/edit/delete sites. Allows scheduling Virtual Visit. (Added on 2020-06-09)',
    text: {
      0: 'Create new, update and delete users',
      1: 'Permission to create a new study',
      2: 'Only study coordinators and above can create/edit/delete sites',
      3: 'Allows scheduling Virtual Visit',
    },
  },
  wxc: {
    code: 'wxc',
    name: 'Add Cohorts',
    note: 'Allows users to add cohorts',
  },
  wxp: {
    code: 'wxp',
    name: 'Add Participants',
    note: 'Allows users to add participants',
    text: {
      0: 'Add paticipants',
    },
  },
  xa: {
    code: 'xa',
    name: 'Deploy Activities',
    note:
      'Deploy Surveys, Tasks, eConsent, articles to participants. Upload “Survey Translation” will perform 2 tasks (upload survey, deploy/make it available), therefore changed to -xa (Added on 2020-10-08)',
  },
  xc: {
    code: 'xc',
    name: 'Write and Deploy Consents',
    note: 'Write and deploy consents',
  },
  xcu: {
    code: 'xcu',
    name: 'Upload Consent Attachments',
    note: 'Upload Consent Attachments',
  },
  xp: {
    code: 'xp',
    name: 'Interact with Participants',
    note:
      'Used for sending reset passwords, resending invites, read and send announcements, modifying notifications, download files uploaded by participants. Can edit cases and schedule virtual visits.',
    text: {
      0: 'Reset passwords',
      1: 'Resending invites',
      2: 'Read announcements',
      3: 'Send announcements',
      4: 'Modifying notifications',
      5: 'Download files uploaded by participants',
      6: 'Can edit cases and schedule virtual visits',
    },
  },
  xr: {
    code: 'xr',
    name: 'Interact with Report',
    note: 'Audit function - fetching report, creating report using the filter, using audit report',
  },
  'xr-no-pii': {
    code: 'xr-no-pii',
    name: 'Interact with Report (no PII)',
    note:
      'Audit function - fetching report, creating report using the filter, using audit report. PII of participants will be obfuscated in the audit report for these users',
  },
  xs: {
    code: 'xs',
    name: 'Interact with study (delete)',
    note: 'Delete study',
  },
  xu: {
    code: 'xu',
    name: 'Modify Configuration',
    note: 'Root level permissions for: Assigning study users, modifying system variables, etc',
  },
  wpi: {
    code: 'wpi',
    name: 'Write Insights Permissions',
    note: 'Allow users to create, modify Insights Permissions',
  },
  rpi: {
    code: 'rpi',
    name: 'Read Insights Permissions',
    note: 'Allow users to read Insights Permissions',
  },
}

export const ROLE_PERMISSIONS = {
  1: {
    name: 'ROOT/Super Admin',
    allowed: [
      'ra',
      'rd',
      'rp',
      'ru',
      'rs',
      'wa',
      'wp',
      'wxp',
      'wxc',
      'wu',
      'xa',
      'rc',
      'xc',
      'xp',
      'xu',
      'xs',
      'xr',
      'ft',
      'rpe',
      'rpc',
      'roa',
      'wpv',
      'rpv',
      'wppv',
      'rppv',
      'wpi',
      'rpi',
    ],
  },
  2: {
    name: 'DCH Admin',
    allowed: [
      'ra',
      'rd',
      'ru',
      'rs',
      'wa',
      'wxc',
      'xa',
      'rc',
      'xc',
      'xf',
      'xu',
      'xr-no-pii',
      'mp',
      'ft',
      'rpe',
      'rpc',
      'roa',
      'wpv',
      'rpv',
      'wppv',
      'rppv',
      'wpi',
      'rpi',
    ],
  },
  3: {
    name: 'Study Coordinator',
    allowed: [
      'ra',
      'rd',
      'rp',
      'ru',
      'rs',
      'wa',
      'wp',
      'wxp',
      'wxc',
      'wu',
      'xa',
      'rc',
      'xc',
      'xf',
      'xp',
      'xr-no-pii',
      'mp',
      'rpe',
      'rpc',
      'roa',
      'wpv',
      'rpv',
      'wppv',
      'rppv',
      'wpi',
      'rpi',
    ],
  },
  4: {
    name: 'Investigator',
    allowed: [
      'ra',
      'rd',
      'rp',
      'ru',
      'rs',
      'wa',
      'wp',
      'wxp',
      'wxc',
      'wu',
      'xa',
      'rc',
      'xc',
      'xf',
      'xp',
      'xr-no-pii',
      'mp',
      'rpe',
      'rpc',
      'roa',
      'wpv',
      'rpv',
      'wppv',
      'rppv',
      'wpi',
      'rpi',
    ],
  },
  5: {
    name: 'Sponsor',
    allowed: ['ra', 'rd', 'ru', 'rs', 'rc', 'xr-no-pii', 'roa', 'rppv-no-pii'],
  },
  6: {
    name: 'Researcher',
    allowed: ['ra', 'rd', 'ru', 'rs', 'rc', 'roa'],
  },
  7: {
    name: 'Enroller',
    allowed: ['wp', 'wxp', 'wxc', 'rc', 'rpe', 'rpc'],
  },
  8: {
    name: 'Site Coordinator',
    allowed: [
      'ra',
      'rd',
      'rp',
      'ru',
      'rs',
      'wp',
      'wxp',
      'wxc',
      'rc',
      'xc',
      'xf',
      'xp',
      'mp',
      'rpe',
      'rpc',
      'roa',
      'rpv',
      'wppv',
      'rppv',
    ],
  },
  9: {
    name: 'Auditor',
    allowed: ['ra', 'rd', 'rp', 'ru', 'rs', 'rc', 'xr-no-pii', 'roa'],
  },
  10: {
    name: 'Case Manager',
    allowed: ['ra', 'rd', 'rp', 'ru', 'rs', 'wxp', 'wxc', 'rc', 'xp', 'rpe', 'rpc', 'roa'],
  },
  11: {
    name: 'Clinical Staff 1',
    allowed: ['ca', 'cp', 'rd', 'roa'],
  },
  12: {
    name: 'Clinical Staff 2',
    allowed: ['ca', 'cp', 'rd', 'roa'],
  },
  13: {
    name: 'Clinical Staff 3',
    allowed: ['ca', 'cp', 'rd', 'roa'],
  },
  14: {
    name: 'DCH PII',
    allowed: ['ra', 'rd', 'rp', 'ru', 'rs', 'rc', 'xf', 'xr', 'ft', 'roa', 'wpv', 'rpv', 'wppv', 'rppv', 'wpi', 'rpi'],
  },
  15: {
    name: 'DCH Study Coordinator',
    allowed: [
      'ra',
      'rd',
      'ru',
      'rs',
      'wa',
      'wxp',
      'wxc',
      'wu',
      'xa',
      'rc',
      'xc',
      'xf',
      'xu',
      'xr-no-pii',
      'mp',
      'rpe',
      'rpc',
      'roa',
      'wpv',
      'rpv',
      'wppv',
      'rppv',
      'wpi',
      'rpi',
    ],
  },
  16: {
    name: 'Clinical Coordinator',
    allowed: [
      'ca',
      'cp',
      'ra',
      'rd',
      'rp',
      'ru',
      'rs',
      'wp',
      'wxp',
      'wxc',
      'rc',
      'xc',
      'xf',
      'xp',
      'rpe',
      'rpc',
      'roa',
      'rpv',
      'wppv',
      'rppv',
    ],
  },
  17: {
    name: 'Support Agent',
    allowed: ['rs', 'rc', 'rpe'],
  },
  18: {
    name: 'Reporter',
    allowed: ['ra', 'rd', 'rs', 'wpi', 'rpi'],
  },
  19: {
    name: 'Site and User Management Admin',
    allowed: ['ru', 'wu', 'xr-no-pii', 'rs'],
  },
  20: {
    name: 'Impartial Witness',
    allowed: ['ca', 'cp', 'rd', 'rp', 'roa'],
  },
}

export const AVAILABLE_INSIGHTS_ROLE_OPTIONS = [
  // { key: 4, text: 'Study Coordinator / Investigator', value: 'studyCoordinator', role_id: 4 },
  { key: 5, text: 'Sponsor', value: 'sponsor', role_id: 5 },
  { key: 6, text: 'Researcher', value: 'researcher', role_id: 6 },
  { key: 7, text: 'Enroller', value: 'studyManager', role_id: 7 },
  { key: 8, text: 'Site Coordinator', value: 'siteCoordinator', role_id: 8 },
  { key: 9, text: 'Auditor', value: 'auditor', role_id: 9 },
  // { key: 10, text: 'Case Manager', value: 'caseManager', role_id: 10 },
  // { key: 11, text: 'Clinician I', value: 'clinRoI', role_id: 11 },
  // { key: 12, text: 'Clinician II', value: 'clinRoII', role_id: 12 },
  // { key: 13, text: 'Clinician III', value: 'clinRoIII', role_id: 13 },
  { key: 16, text: 'Clinical Coordinator', value: 'clinicalCoordinator', role_id: 16 },
  // { key: 17, text: 'Support', value: 'supportAgent', role_id: 17 },
  { key: 18, text: 'Reporter', value: 'reporter', role_id: 18 },
  // { key: 20, text: 'Impartial Witness', value: 'impartialWitness', role_id: 20 },
]

export const DEFAULT_INSIGHTS_STUDY_ROLE_ACCESS = {
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  16: true,
  18: true,
  19: true,
  20: true,
}

export const DEFAULT_PTP_DATA_FIELDS = {
  mandatory: [
    {
      display_label: 'First Name',
      type: 'text',
      metadata: {
        internal_key: 'fname',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Last Name',
      type: 'text',
      metadata: {
        internal_key: 'lname',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },

    {
      display_label: 'Sites',
      type: 'number',
      metadata: {
        internal_key: 'site_id',
        ui_type: 'custom_dropdown',
        custom_validations: '',
        is_custom: false,
        default_value: null,
        can_update: true,
      },
    },
    {
      display_label: 'Locale',
      type: 'text',
      metadata: {
        internal_key: 'default_locale',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
  ],
  optional: [
    {
      display_label: 'Subject ID',
      type: 'text',
      metadata: {
        internal_key: 'subject_id',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Caregiver',
      type: 'checkbox',
      metadata: {
        internal_key: 'is_caregiver',
        custom_validations: '',
        is_custom: false,
        default_value: false,
        can_update: false,
      },
    },
    {
      display_label: 'Sex',
      type: 'text',
      metadata: {
        internal_key: 'gender',
        custom_validations: '',
        is_custom: false,
        default_value: 'n',
        can_update: true,
      },
    },
    {
      display_label: 'Date of Birth',
      type: 'date',
      metadata: {
        internal_key: 'date_of_birth',
        custom_validations: '',
        is_custom: false,
        default_value: '1980-01-01',
        can_update: true,
      },
    },
  ],
  disabled: [
    {
      display_label: 'Email',
      type: 'email',
      metadata: {
        internal_key: 'email',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Phone',
      type: 'tel',
      metadata: {
        internal_key: 'phone',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
  ],
}

export const DEFAULT_STUDY_CONFIG = {
  custom_onboarding: {},
  case_management: {},
  custom_geofence_behavior: {},
  custom_map_button: {},
  data_sync_ui_enabled: true,
  ediary: { version: 2 },
  modules: {
    // 3.17
    geofencing: false,
    battery: false,
    location: false,
    stepcounter: false,
    appsinstalled: false,
    screentime: false,
    phonecontacts: false,
    calls: false,
    sms: false,
    bluetooth: false,
    gmail: false,
    googlecontacts: false,
    gmailaccounts: false,
    linkedin: false,
    twitter: false,
  },
  forced_instrument_priority: false, // 3.17
  reward_duration: null, // 3.17
  reward_goal: null, // 3.17
  has_dynamic_cycles: false, // 3.17
  cycles: [
    // 3.17
    {
      target: 2,
      reward: 100,
      cycleDuration: 1,
      studyDuration: 1,
      durationType: 'days',
      rewardType: 'gems',
    },
  ],
  auto_notification: {
    // 3.17
    incomplete_instrument: {
      enabled: false,
      interval_hours: 24,
    },
    expiring_instrument: {
      enabled: false,
      interval_hours: 24,
    },
    cycle_end: {
      enabled: false,
      interval_hours: 24,
    },
    periodic: {
      enabled: false,
      interval_days: 1,
      time_of_day: '18:00',
    },
  },
  participant_data_fields: DEFAULT_PTP_DATA_FIELDS,
  consent_required: false,
  hasAdvancedFeatures: false,
  session_timeout_seconds: 0,
  neutral_reward_enabled: false,
  admin_security: {
    attempt_num: { isToggled: false, value: 6, showError: false },
    lockout_time: { isToggled: false, value: 30, showError: false },
    expiration: { isToggled: false, value: 90, showError: false },
    reuse_history: { isToggled: false, value: 6, showError: false },
  },
  training: { enabled: false },
}

export const DEFAULT_STUDY = {
  /* Duplicate keys that are in study root level and config level are commented with the app versions that use them:
    v3.16 for app version 3.16 and below
    v3.17 for app version 3.17 and above */
  name: '',
  platform_name: '',
  modules: {
    // 3.16
    geofencing: false,
    battery: false,
    location: false,
    stepcounter: false,
    appsinstalled: false,
    screentime: false,
    phonecontacts: false,
    calls: false,
    sms: false,
    bluetooth: false,
    gmail: false,
    googlecontacts: false,
    gmailaccounts: false,
    linkedin: false,
    twitter: false,
  },
  forced_instrument_priority: false, // 3.16
  reward_duration: null, // 3.16
  reward_goal: null, // 3.16
  has_dynamic_cycles: false, // 3.16
  cycles: [
    {
      target: 2,
      reward: 100,
      cycleDuration: 1,
      studyDuration: 1,
      durationType: 'days',
      rewardType: 'gems',
    },
  ], // 3.16
  auto_notification: {
    // 3.16
    incomplete_instrument: {
      enabled: false,
      interval_hours: 24,
    },
    expiring_instrument: {
      enabled: false,
      interval_hours: 24,
    },
    cycle_end: {
      enabled: false,
      interval_hours: 24,
    },
    periodic: {
      enabled: false,
      interval_days: 1,
      time_of_day: '18:00',
    },
  },
  config: {
    custom_onboarding: {},
    case_management: {},
    custom_geofence_behavior: {},
    custom_map_button: {},
    data_sync_ui_enabled: true,
    ediary: { version: 2 },
    modules: {
      // 3.17
      geofencing: false,
      battery: false,
      location: false,
      stepcounter: false,
      appsinstalled: false,
      screentime: false,
      phonecontacts: false,
      calls: false,
      sms: false,
      bluetooth: false,
      gmail: false,
      googlecontacts: false,
      gmailaccounts: false,
      linkedin: false,
      twitter: false,
    },
    forced_instrument_priority: false, // 3.17
    reward_duration: null, // 3.17
    reward_goal: null, // 3.17
    has_dynamic_cycles: false, // 3.17
    cycles: [
      // 3.17
      {
        target: 2,
        reward: 100,
        cycleDuration: 1,
        studyDuration: 1,
        durationType: 'days',
        rewardType: 'gems',
      },
    ],
    auto_notification: {
      // 3.17
      incomplete_instrument: {
        enabled: false,
        interval_hours: 24,
      },
      expiring_instrument: {
        enabled: false,
        interval_hours: 24,
      },
      cycle_end: {
        enabled: false,
        interval_hours: 24,
      },
      periodic: {
        enabled: false,
        interval_days: 1,
        time_of_day: '18:00',
      },
    },
    participant_data_fields: {
      mandatory: [
        {
          display_label: 'First Name',
          type: 'text',
          metadata: {
            internal_key: 'fname',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
        {
          display_label: 'Last Name',
          type: 'text',
          metadata: {
            internal_key: 'lname',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
        {
          display_label: 'Locale',
          type: 'text',
          metadata: {
            internal_key: 'default_locale',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
        {
          display_label: 'Sites',
          type: 'number',
          metadata: {
            internal_key: 'site_id',
            ui_type: 'custom_dropdown',
            custom_validations: '',
            is_custom: false,
            default_value: null,
            can_update: true,
          },
        },
      ],
      optional: [
        {
          display_label: 'Subject ID',
          type: 'text',
          metadata: {
            internal_key: 'subject_id',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
        {
          display_label: 'Caregiver',
          type: 'checkbox',
          metadata: {
            internal_key: 'is_caregiver',
            custom_validations: '',
            is_custom: false,
            default_value: false,
            can_update: false,
          },
        },
        {
          display_label: 'Sex',
          type: 'text',
          metadata: {
            internal_key: 'gender',
            custom_validations: '',
            is_custom: false,
            default_value: 'n',
            can_update: true,
          },
        },
        {
          display_label: 'Date of Birth',
          type: 'date',
          metadata: {
            internal_key: 'date_of_birth',
            custom_validations: '',
            is_custom: false,
            default_value: '1980-01-01',
            can_update: true,
          },
        },
      ],
      disabled: [
        {
          display_label: 'Email',
          type: 'email',
          metadata: {
            internal_key: 'email',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
        {
          display_label: 'Phone',
          type: 'tel',
          metadata: {
            internal_key: 'phone',
            custom_validations: '',
            is_custom: false,
            default_value: '',
            can_update: true,
          },
        },
      ],
    },
    consent_required: false,
    hasAdvancedFeatures: false,
  },
}

export const MAX_INT = 2147483647
export const MIN_INT = -2147483648

export const DECIMAL_OPTIONS = [
  { key: '', text: '', value: '' },
  { key: '1', text: '0', value: '1' },
  { key: '2', text: '00', value: '2' },
  { key: '3', text: '000', value: '3' },
  { key: '4', text: '0000', value: '4' },
  { key: '5', text: '00000', value: '5' },
  { key: '6', text: '000000', value: '6' },
  { key: '7', text: '0000000', value: '7' },
  { key: '8', text: '00000000', value: '8' },
  { key: '9', text: '000000000', value: '9' },
  { key: '10', text: '0000000000', value: '10' },
  { key: '11', text: '00000000000', value: '11' },
  { key: '12', text: '000000000000', value: '12' },
]

export const INSIGHTS_DASHBOARD_NAMES = {
  location: 'location',
  auditReport: 'audit_report',
  dataSurveillance: 'data_surveillance',
  demographics: 'demographics',
  instrumentDetail: 'instrument_detail',
}

export const TRAINING_MATERIAL_TYPE = {
  document: 'document',
  video: 'video',
}

export const REDIRECT_DELAY = 400

export const MEDIA_TYPE_MAP = {
  image: 'image',
  audio: 'audio',
  video: 'video',
  document: 'document',
  white_labeling: 'white_labeling',
  pdf: 'pdf',
}

export const MEDIA_UPLOAD_MAP = {
  audio: {
    acceptedTypes: ['.mp3', '.wav'],
  },
  white_labeling: {
    acceptedTypes: ['.png', '.jpg', '.jpeg'],
    restriction: 'Max size 2.5 MB, 1000 x 1000 pixels',
  },
  image: {
    acceptedTypes: ['.png', '.jpg', '.jpeg'],
    restriction: 'Max size 2.5 MB, 1000 x 1000 pixels',
  },
  general_image: {
    acceptedTypes: ['.png', '.jpg', '.jpeg'],
    restriction: 'Max 400 KB',
  },
  video: {
    acceptedTypes: ['.mp4', '.m4v', '.mov', '.webm'],
  },
  document: {
    acceptedTypes: ['.pdf'],
  },
}

export const BULK_DATA_TYPES = {
  audioFiles: 'audio_files',
}

export const STATUS_OPTIONS = {
  Confirmed: 'Confirmed',
  Missed: 'Missed',
  Passed: 'Passed',
  Scheduled: 'Scheduled',
}

export const CONSENT_STATUSES = {
  inProgress: 'in_progress',
  readyToBeScheduled: 'ready_to_be_scheduled',
  scheduled: 'scheduled',
}

export const TIMER_DEFAULT_DATE = '2000-01-01T00:00:00.000-00:00'

export const TIMER_TIME_FORMAT = 'mm:ss'

export const TIMER_TIME_OPTIONS = {
  minute: 'minute',
  second: 'second',
}

export const MIN_QUESTIONS_FOR_SHUFFLE = 2

export const INSTRUMENT_TIMELINE_EVENTS = {
  completed: 'instrument_completed',
  completedArticle: 'instrument_completed_article',
  completedDiary: 'instrument_completed_ediary',
  completedSurvey: 'instrument_completed_survey',
  completedTask: 'instrument_completed_task',
  delivered: 'instrument_delivered',
  deliveredArticle: 'instrument_delivered_article',
  deliveredDiary: 'instrument_delivered_ediary',
  deliveredSurvey: 'instrument_delivered_survey',
  deliveredTask: 'instrument_delivered_task',
  read: 'instrument_read',
  readArticle: 'instrument_read_article',
  readDiary: 'instrument_read_ediary',
  readSurvey: 'instrument_read_survey',
  readTask: 'instrument_read_task',
  submitSuccessful: 'instrument_submit_successful',
  submitSuccessfulArticle: 'instrument_submit_successful_article',
  submitSuccessfulDiary: 'instrument_submit_successful_ediary',
  submitSuccessfulSurvey: 'instrument_submit_successful_survey',
  submitSuccessfulTask: 'instrument_submit_successful_task',
  visible: 'instrument_visible',
  visibleArticle: 'instrument_visible_article',
  visibleDiary: 'instrument_visible_ediary',
  visibleSurvey: 'instrument_visible_survey',
  visibleTask: 'instrument_visible_task',
  instrumentSendActual: 'instrument_send_actual',
}

export const ANNOUNCEMENT_TIMELINE_EVENTS = {
  delivered: 'announcement_delivered',
  visible: 'announcement_visible',
  seen: 'announcement_seen',
  read: 'announcement_read',
}

export const COMBINED_INSTRUMENT_TIMELINE_MAP = {
  [INSTRUMENT_TIMELINE_EVENTS.completed]: STRINGS.completedSentToSystem,
  [INSTRUMENT_TIMELINE_EVENTS.completedArticle]: STRINGS.completedSentToSystem,
  [INSTRUMENT_TIMELINE_EVENTS.completedDiary]: STRINGS.completedSentToSystem,
  [INSTRUMENT_TIMELINE_EVENTS.completedSurvey]: STRINGS.completedSentToSystem,
  [INSTRUMENT_TIMELINE_EVENTS.completedTask]: STRINGS.completedSentToSystem,

  [INSTRUMENT_TIMELINE_EVENTS.delivered]: STRINGS.receivedByDevice,
  [INSTRUMENT_TIMELINE_EVENTS.deliveredArticle]: STRINGS.receivedByDevice,
  [INSTRUMENT_TIMELINE_EVENTS.deliveredDiary]: STRINGS.receivedByDevice,
  [INSTRUMENT_TIMELINE_EVENTS.deliveredSurvey]: STRINGS.receivedByDevice,
  [INSTRUMENT_TIMELINE_EVENTS.deliveredTask]: STRINGS.receivedByDevice,

  [INSTRUMENT_TIMELINE_EVENTS.read]: STRINGS.opened,
  [INSTRUMENT_TIMELINE_EVENTS.readArticle]: STRINGS.opened,
  [INSTRUMENT_TIMELINE_EVENTS.readDiary]: STRINGS.opened,
  [INSTRUMENT_TIMELINE_EVENTS.readSurvey]: STRINGS.opened,
  [INSTRUMENT_TIMELINE_EVENTS.readTask]: STRINGS.opened,

  [INSTRUMENT_TIMELINE_EVENTS.submitSuccessful]: STRINGS.systemReceived,
  [INSTRUMENT_TIMELINE_EVENTS.submitSuccessfulArticle]: STRINGS.systemReceived,
  [INSTRUMENT_TIMELINE_EVENTS.submitSuccessfulDiary]: STRINGS.systemReceived,
  [INSTRUMENT_TIMELINE_EVENTS.submitSuccessfulSurvey]: STRINGS.systemReceived,
  [INSTRUMENT_TIMELINE_EVENTS.submitSuccessfulTask]: STRINGS.systemReceived,

  [INSTRUMENT_TIMELINE_EVENTS.visible]: STRINGS.visible,
  [INSTRUMENT_TIMELINE_EVENTS.visibleArticle]: STRINGS.visible,
  [INSTRUMENT_TIMELINE_EVENTS.visibleDiary]: STRINGS.visible,
  [INSTRUMENT_TIMELINE_EVENTS.visibleSurvey]: STRINGS.visible,
  [INSTRUMENT_TIMELINE_EVENTS.visibleTask]: STRINGS.visible,

  [INSTRUMENT_TIMELINE_EVENTS.instrumentSendActual]: STRINGS.sentToDevice,

  [ANNOUNCEMENT_TIMELINE_EVENTS.delivered]: STRINGS.receivedByDevice,
  [ANNOUNCEMENT_TIMELINE_EVENTS.visible]: STRINGS.visible,
  [ANNOUNCEMENT_TIMELINE_EVENTS.seen]: STRINGS.seen,
  [ANNOUNCEMENT_TIMELINE_EVENTS.read]: STRINGS.openedAndRead,
}

export const FLAGSMITH_FEATURES = {
  advancedEvents: 'advanced_events_timeline',
  architectureVersion: 'default_instrument_architecture_version',
  instrumentUnification: 'unification',
  foodDiary: 'food_diary_enabled',
  studyPromotion: 'study_promotion_enabled',
  maintenanceTool: 'maintenance_tool',
}

export const INSTRUMENT_ERROR_LIST = {
  instrumentScore: 'cohort.filter.instrument_score',
  instrumentScoreId: 'schedule.chain_deployment_info.cohort.filter.score_id',
}

export const INSTRUMENT_DISPLAY_LOCATION_OPTIONS = {
  carousel: 'CAROUSEL',
  calendar: 'CALENDAR',
}

export const MIN_SESSION_TIMEOUT_MINUTES = 3

export const MAX_OFFLINE_DURATION_DAYS = 14

export const STUDY_CONFIG_FEATURE_KEY_MAP = {
  consent: 'eConsent',
  offlineDuration: 'participant_offline_duration_days',
  tracks: 'tracks',
}

export const INPUT_DEBOUNCE = 1000

export const MAX_STUDIES_ON_PAGE = 12

export const STUDY_FEATURE_MAP = {
  caseManagement: 'caseManagement',
  geofencing: 'geofencing',
  clinro: 'clinro',
  medicationAdherence: 'medicationAdherence',
  superGems: 'superGems',
  testStudy: 'testStudy',
  emailless: 'emailless',
  econsent: 'econsent',
}

export const STUDY_FEATURE_FILTER_OPTIONS = [
  { key: STUDY_FEATURE_MAP.caseManagement, value: STUDY_FEATURE_MAP.caseManagement, text: 'Case management' },
  { key: STUDY_FEATURE_MAP.geofencing, value: STUDY_FEATURE_MAP.geofencing, text: 'Geofencing' },
  { key: STUDY_FEATURE_MAP.clinro, value: STUDY_FEATURE_MAP.clinro, text: 'Clinician reported outcomes' },
  {
    key: STUDY_FEATURE_MAP.medicationAdherence,
    value: STUDY_FEATURE_MAP.medicationAdherence,
    text: 'Medication adherence',
  },
  { key: STUDY_FEATURE_MAP.superGems, value: STUDY_FEATURE_MAP.superGems, text: 'Treasure chest store' },
  { key: STUDY_FEATURE_MAP.testStudy, value: STUDY_FEATURE_MAP.testStudy, text: 'Test study' },
  { key: STUDY_FEATURE_MAP.emailless, value: STUDY_FEATURE_MAP.emailless, text: 'Emailless registration' },
  { key: STUDY_FEATURE_MAP.econsent, value: STUDY_FEATURE_MAP.econsent, text: 'eConsent' },
]

export const STUDY_SORTING_OPTIONS = {
  studyCreateDate: 'studyCreateDate',
  studyName: 'studyName',
}

export const STUDY_PAGINATION_SETTINGS_KEY = 'studyPaginationSettings'

export const DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION = 1

export const MAX_PHOTO_LIMIT_FIELD_DEFAULT_VALUE = 3
export const MAX_PHOTO_LIMIT_MIN_VALUE = 3
export const MAX_PHOTO_LIMIT_MAX_VALUE = 25

export const MAINTENANCE_TOOL_URL = process.env.MAINTENANCE_TOOL_URL || 'https://admin-maintenance.develop.d-3.io/login'
