import { injectReducer } from 'store/reducers'
import { USER_ROLES_WITH_RESTRICTED_ACCESS } from 'utils/constants'
import { UserDoesNotHaveRole, UserIsAuthenticated, UserHasValidPassword, UserHasScope } from 'containers/authContainers'
import DataInboxPageContainer from './containers/DataInboxPageContainer'
import dataInboxMaterial, { actions } from './modules/DataInbox'

// Sync route definition
export default store => ({
  exact: true,
  getComponent(nextState, cb) {
    require.ensure(
      [],
      () => {
        const { params } = nextState
        const { studyID } = params

        // Add Reducer
        injectReducer(store, {
          key: 'dataInboxMaterial',
          reducer: dataInboxMaterial,
        })

        store.dispatch(actions.fetchDataInbox(studyID))
        // Return Component
        cb(
          null,
          UserIsAuthenticated(
            UserHasValidPassword(
              UserHasScope(
                ['rd'],
                UserDoesNotHaveRole(USER_ROLES_WITH_RESTRICTED_ACCESS, DataInboxPageContainer),
                false,
                studyID,
              ),
            ),
          ),
        )
      },
      'dashboard',
    )
  },
})
