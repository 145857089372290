import { combineReducers } from 'redux'
import { sendingActions } from 'store/sender'
import request, { generateNotyMessage } from 'utils/request'
import STRINGS from 'utils/strings'
import { actions as notyActions } from 'layouts/ErrorBox'
import { onRedirectVisit } from '../../VisitTemplatePage/utils/visitRedirection'
import { getCreateTemplateVisitErrors } from '../../VisitTemplatePage/utils/visitTemplateValidation'
import { SET_SCHEDULE_VISIT_TEMPLATES } from '../../../../VisitSchedulesPage/modules/VisitSchedules'
import { SET_VISIT_TEMPLATE } from '../../VisitTemplatePage/modules/VisitTemplate'

//
// Actions
//
const INIT_TEMPLATE_VISIT = 'INIT_TEMPLATE_VISIT'
const UPDATE_TEMPLATE_VISIT = 'UPDATE_TEMPLATE_VISIT'
const UPDATE_TEMPLATE_VISIT_METADATA = 'UPDATE_TEMPLATE_VISIT_METADATA'
const UPDATE_ORIGINAL_TEMPLATE_VISIT = 'UPDATE_ORIGINAL_TEMPLATE_VISIT'
const SET_TEMPLATE_VISIT = 'SET_TEMPLATE_VISIT'

// ERRORS
const ADD_TEMPLATE_VISIT_ERRORS = 'ADD_TEMPLATE_VISIT_ERRORS'
const CLEAR_TEMPLATE_VISIT_ERRORS = 'CLEAR_TEMPLATE_VISIT_ERRORS'
//
// Action Creators
//

const initPage = () => dispatch => {
  return dispatch({
    type: INIT_TEMPLATE_VISIT,
  })
}

const updateVisit = ({ key, value }) => dispatch => dispatch({ type: UPDATE_TEMPLATE_VISIT, key, value })

const updateVisitMetadata = ({ key, value }) => dispatch =>
  dispatch({ type: UPDATE_TEMPLATE_VISIT_METADATA, key, value })

const addVisitErrors = errors => dispatch => dispatch({ type: ADD_TEMPLATE_VISIT_ERRORS, errors })

const clearVisitErrors = () => dispatch => dispatch({ type: CLEAR_TEMPLATE_VISIT_ERRORS })
//
// Utils
//
const defaultTemplateVisitState = {
  name: '',
  visit_day: null,
  visit_metadata: {
    display_name: '',
    reminders: {},
  },
  visit_window: null,
}

//
// API functions
//
const saveTemplateVisitRequest = ({ studyID, body, visitTemplateId, isUpdate, onSuccess }) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedules/${visitTemplateId}${!isUpdate ? '/visits' : ''}`
  return dispatch(
    request({
      url,
      body,
      method: isUpdate ? 'PATCH' : 'POST',
      successMessage: STRINGS.visitSavedSuccess,
      failMessage: STRINGS.visitSavedFail,
      success: onSuccess,
      hasSender: true,
    }),
  )
}

const onDeleteVisit = ({ studyID, templateVisitId, visitScheduleId }) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedules/${visitScheduleId}/visit/${templateVisitId}`
  const success = resJSON => {
    dispatch(onRedirectVisit({ resJSON, studyID }))
  }

  return dispatch(
    request({
      url,
      method: 'DELETE',
      successMessage: STRINGS.visitDelSuccess,
      failMessage: STRINGS.visitDelFail,
      success,
    }),
  )
}

//
// Util Functions
//
const saveTemplateVisit = ({ studyID, templateVisit, isUpdate, visitTemplateId, onRedirect }) => dispatch => {
  const onSuccess = resJSON => {
    dispatch(sendingActions.stopSender())
    dispatch({
      type: UPDATE_ORIGINAL_TEMPLATE_VISIT,
      json: templateVisit,
    })
    setTimeout(() => {
      dispatch(sendingActions.resetSender())
      onRedirect(resJSON)
    }, 500)
  }

  const body = JSON.stringify(
    isUpdate ? { study_visit_schedule: { visits: [templateVisit] } } : { visits: [templateVisit] },
  )

  return dispatch(saveTemplateVisitRequest({ studyID, body, visitTemplateId, isUpdate, onSuccess }))
}

const onSaveTemplateVisit = ({ studyID, redirectPath, queryParams, isUpdate, visitTemplateId }) => (
  dispatch,
  getState,
) => {
  const { templateVisitReducer, subroute } = getState()
  const { templateVisit } = templateVisitReducer
  const errors = getCreateTemplateVisitErrors(templateVisit)
  const valid = !(Object.values(errors).filter(val => val).length >= 1)
  const onRedirect = resJSON =>
    dispatch(onRedirectVisit({ resJSON, redirectPath, studyID, queryParams, visitScheduleSubroute: subroute }))

  if (valid) {
    dispatch(clearVisitErrors())
    dispatch(sendingActions.startSender())
    dispatch(saveTemplateVisit({ studyID, ...templateVisitReducer, isUpdate, visitTemplateId, onRedirect }))
  } else {
    dispatch(addVisitErrors(errors))
    dispatch(
      notyActions.showError({
        text: generateNotyMessage(STRINGS.pleaseCheckErr, false),
      }),
    )
  }
}

const selectVisit = (visitTemplate, templateVisitId) => {
  if (templateVisitId) {
    const indexOfTemplateVisit = visitTemplate.visits.findIndex(visit => visit.id === templateVisitId)
    return visitTemplate.visits[indexOfTemplateVisit]
  }
  return defaultTemplateVisitState
}

const formatVisitSchedules = visitSchedulesList => {
  return visitSchedulesList.map(visitTemplate => {
    const { id, template_name } = visitTemplate
    return { key: id, text: template_name }
  })
}

const formatScheduleVisitTemplatesList = visitSchedulesTemplatesList => {
  const resultObj = {}

  visitSchedulesTemplatesList.forEach(({ visits, id: visitScheduleId }) => {
    const visitTableMap = {}
    const visitOptions = []
    const visitDayMap = {}

    visits.forEach(({ id, name, visit_day }) => {
      const row = [
        { key: 'visitName', value: name, sortValue: name, visitId: id },
        {
          key: 'visitDay',
          value: visit_day,
          sortValue: visit_day,
          className: 'visit-day-col',
        },
      ]
      visitTableMap[id] = row
      visitOptions.push({ key: id, text: name, visitDay: visit_day })
      visitDayMap[id] = visit_day
    })

    resultObj[visitScheduleId] = { visitDayMap, visitTableMap, visitOptions }
  })
  return resultObj
}

//
// Reducers
//

const templateVisit = (state = defaultTemplateVisitState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INIT_TEMPLATE_VISIT:
      return defaultTemplateVisitState
    case SET_VISIT_TEMPLATE: {
      return selectVisit(action.visitTemplate, action.templateVisitId)
    }
    case UPDATE_TEMPLATE_VISIT:
      return { ...newState, [action.key]: action.value }
    case UPDATE_TEMPLATE_VISIT_METADATA:
      return { ...newState, visit_metadata: { ...newState.visit_metadata, [action.key]: action.value } }
    case SET_TEMPLATE_VISIT: {
      return action.visitTemplate
    }
    default:
      return state
  }
}

const templateVisitErrors = (state = {}, action) => {
  switch (action.type) {
    case INIT_TEMPLATE_VISIT:
    case CLEAR_TEMPLATE_VISIT_ERRORS:
      return {}
    case ADD_TEMPLATE_VISIT_ERRORS:
      return action.errors
    default:
      return state
  }
}

const originalTemplateVisit = (state = {}, action) => {
  if (action.type === INIT_TEMPLATE_VISIT || action.type === UPDATE_ORIGINAL_TEMPLATE_VISIT) {
    return JSON.parse(JSON.stringify(action.json || defaultTemplateVisitState))
  }
  return state
}

const visitScheduleOptions = (state = [], action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISIT_TEMPLATES:
      return formatVisitSchedules(action.payload)
    default:
      return state
  }
}

const visitScheduleInitialData = (state = {}, action) => {
  switch (action.type) {
    case SET_SCHEDULE_VISIT_TEMPLATES:
      return formatScheduleVisitTemplatesList(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  templateVisit,
  templateVisitErrors,
  originalTemplateVisit,
  visitScheduleInitialData,
  visitScheduleOptions,
})

export const visitActions = {
  initPage,
  onDeleteVisit,
  onSaveTemplateVisit,
  updateVisit,
  updateVisitMetadata,
}
