import STRINGS from 'utils/strings'

export const getCreateVisitTemplateErrors = visitTemplate => {
  const errors = {}
  const { template_name } = visitTemplate
  if (!template_name || template_name === '') errors.template_name = STRINGS.visitNameErr
  return errors
}
export const getCreateTemplateVisitErrors = templateVisit => {
  const errors = {}
  const { name, visit_metadata = {}, visit_day, visit_window } = templateVisit
  const { display_name } = visit_metadata
  if (!name || name === '') errors.name = STRINGS.visitNameErr
  if (!display_name) errors.display_name = STRINGS.appDisplayNameErr
  if (visit_day == null) errors.visit_day = STRINGS.visitDayErr
  if (visit_day < 0) errors.visit_day = STRINGS.visitDayNegErr
  if (visit_window === null || visit_window === '') errors.visit_window = STRINGS.visitWindowErr
  else if (visit_window < 0) errors.visit_window = STRINGS.visitWindowNegErr
  else if (typeof visit_window !== 'number') errors.visit_window = STRINGS.visitWindowNaNErr
  return errors
}
