import React, { useEffect } from 'react'
import { browserHistory } from 'react-router'
import { Button } from 'components/UIElements'
import STRINGS from 'utils/strings'
import useQueryString from 'utils/hooks/useQueryString'
import AccountSetup from '../../CreateParticipant/components/AccountSetup'
import CreateParticipantNav from '../../CreateParticipant/components/CreateParticipantNav'
import TemporaryPassword from '../../CreateParticipant/components/TemporaryPassword'

import '../stylesheets/accountsettings.scss'

const AccountSettings = props => {
  const {
    fetchPtp,
    generateTempPassword,
    isEmailless,
    location,
    participant,
    ptpSiteID,
    resetCheckedSites,
    setNavBarProps,
    study,
    studyID,
    tempPwdLoading,
  } = props

  const { query, queryRemove } = useQueryString({
    location,
  })

  useEffect(() => {
    const { ptpId = null } = query
    if (!ptpId) return browserHistory.push(`/studies/${studyID}/participants/create`)
    fetchPtp(studyID, ptpSiteID, ptpId)
    queryRemove(null)
    return () => {
      setNavBarProps(null)
      resetCheckedSites()
    }
  }, [])
  return (
    <div className='participant-account-settings page'>
      <CreateParticipantNav {...props} />
      <div className='flexed-header'>
        <h2 className='ptp-name'>New Participant</h2>
      </div>
      <AccountSetup
        title={STRINGS.accountSettings}
        url={study?.config?.complete_account_setup_url}
        fileName={'complete_account_setup_url.pdf'}
      />
      <TemporaryPassword
        participant={participant}
        title={STRINGS.accountSettingsTitle}
        description={STRINGS.accountSettingsDesc}
        studyID={studyID}
        generateTempPassword={generateTempPassword}
        tempPwdLoading={tempPwdLoading}
        isEmailless={isEmailless}
      />
      <div className='button-list'>
        <Button
          onClick={() => {
            resetCheckedSites()
            browserHistory.push(`/studies/${study.id}/participants`)
          }}
          content='Back to dashboard'
        />
      </div>
    </div>
  )
}

export default AccountSettings
