import { combineReducers } from 'redux'
import { browserHistory } from 'react-router'
import moment from 'moment'
import request, { generateNotyMessage } from 'utils/request'
import {
  BLOB_TYPE_MAP,
  DATE_FORMAT_MAP,
  MODAL_BUTTONS_MAP,
  MODAL_CLASSES_MAP,
  MODAL_CONTENT_MAP,
  NOT_APPLICABLE_PLACEHOLDER,
  PARTICIPANT_CONSENT_STATUS_MAP,
  REDIRECT_DELAY,
  STATUS_OPTIONS,
  DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION,
  INSTRUMENT_TYPE_MAP,
  COMBINED_INSTRUMENT_TIMELINE_MAP,
} from 'utils/constants'
import { downloadBlob, fileTypeRegex } from 'utils/misc'
import { today, getFormattedUTCTimestamp } from 'utils/time'
import { objMapToList } from 'utils/object'
import { modalActions } from 'store/modal'
import { loadingActions } from 'store/loader'
import { sendingActions } from 'store/sender'
import documents, { documentsActions } from 'store/documents'
import { actions as notyActions } from 'layouts/ErrorBox'
import { getParticipantErrors } from '../../../utils/participantValidation'
import {
  _fetchPayments,
  _fetchTreasureChestPayoutHistory,
  getFormattedPayments,
} from '../../../../Payments/modules/Payments'
import { actions as ptpActions } from '../../ParticipantsPage/modules/Participants'
import { _fetchVisitSchedules } from '../../../../VisitSchedules/routes/VisitSchedulesPage/modules/VisitSchedules'
import { setAdhocVisit } from '../../../../VisitSchedules/routes/VisitScheduleNav/routes/ParticipantVisitPage/modules/ParticipantVisit'

export const FORM_FIELDS = [
  {
    key: 'fname',
    text: 'Preferred Name',
    type: 'text',
    placeholder: 'Preferred Name',
    tester: true,
  },
  {
    key: 'lname',
    text: 'Last Name',
    type: 'text',
    placeholder: 'Last Name',
    tester: true,
  },
  {
    key: 'subject_id',
    text: 'Subject ID',
    type: 'text',
    placeholder: 'Subject ID',
    tester: true,
    hint: '[space] + - _ , . are allowed',
  },
  { key: 'is_caregiver', text: 'Caregiver', type: 'check' },
  {
    key: 'gender',
    text: 'Sex',
    type: 'select',
    options: [
      { key: 'm', text: 'Male', value: 'm' },
      { key: 'f', text: 'Female', value: 'f' },
      { key: 'o', text: 'Other', value: 'o' },
      { key: 'n', text: '---', value: 'n' },
    ],
  },
  {
    key: 'date_of_birth',
    text: 'Date of Birth',
    type: 'date',
    tester: true,
  },
  {
    key: 'default_locale',
    text: 'Locale',
    type: 'select',
    // options for the dropdown will be populated by the either the '/locales' API endpoint or list of enforced languages if available,
    // not hard coded here to allow for more locales as they come
  },
  {
    key: 'email',
    text: 'Email Address',
    type: 'text',
    placeholder: 'email@email.com',
    tester: true,
  },
  {
    key: 'phone',
    text: 'Phone Number',
    placeholder: 'Phone Number',
    // type: 'text',
    type: 'phone',
  },
]

const DEFAULT_PTP_DATA_FIELDS_CONFIG = {
  mandatory: [
    {
      display_label: 'Preferred Name',
      type: 'text',
      metadata: {
        internal_key: 'fname',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Last Name',
      type: 'text',
      metadata: {
        internal_key: 'lname',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Email',
      type: 'email',
      metadata: {
        internal_key: 'email',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Sites',
      type: 'number',
      metadata: {
        internal_key: 'site_id',
        ui_type: 'custom_dropdown',
        custom_validations: '',
        is_custom: false,
        default_value: null,
        can_update: true,
      },
    },
  ],
  optional: [
    {
      display_label: 'Subject ID',
      type: 'text',
      metadata: {
        internal_key: 'subject_id',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
    {
      display_label: 'Caregiver',
      type: 'checkbox',
      metadata: {
        internal_key: 'is_caregiver',
        custom_validations: '',
        is_custom: false,
        default_value: false,
        can_update: false,
      },
    },
    {
      display_label: 'Locale',
      type: 'text',
      metadata: {
        internal_key: 'default_locale',
        custom_validations: '',
        is_custom: false,
        default_value: 'en_US',
        can_update: true,
      },
    },
    {
      display_label: 'Sex',
      type: 'text',
      metadata: {
        internal_key: 'gender',
        custom_validations: '',
        is_custom: false,
        default_value: 'n',
        can_update: true,
      },
    },
    {
      display_label: 'Date of Birth',
      type: 'date',
      metadata: {
        internal_key: 'date_of_birth',
        custom_validations: '',
        is_custom: false,
        default_value: '1980-01-01',
        can_update: true,
      },
    },
    {
      display_label: 'Phone',
      type: 'tel',
      metadata: {
        internal_key: 'phone',
        custom_validations: '',
        is_custom: false,
        default_value: '',
        can_update: true,
      },
    },
  ],
  disabled: [],
}

//
// Actions
//

const UPDATE_FIELD = 'UPDATE_FIELD'
const INITIALIZE_CREATE_PTP = 'INITIALIZE_CREATE_PTP'
const SET_PARTICIPANT = 'SET_PARTICIPANT'
const SET_V2_PARTICIPANT = 'SET_V2_PARTICIPANT'
export const RESET_PARTICIPANT = 'RESET_PARTICIPANT'
const INITIALIZE_CHECKED_COHORTS = 'INITIALIZE_CHECKED_COHORTS'
const TOGGLE_COHORT = 'TOGGLE_COHORT'
const CLEAR_COHORTS = 'CLEAR_COHORTS'
const SET_PTP_PAYMENTS = 'SET_PTP_PAYMENTS'
const SET_LOCALES = 'SET_LOCALES'
const SET_FILES = 'SET_FILES'
const SET_PARTICIPANT_INSTRUMENTS = 'SET_PARTICIPANT_INSTRUMENTS'
const SET_PARTICIPANT_ANNOUNCEMENTS = 'SET_PARTICIPANT_ANNOUNCEMENTS'
const SET_V2_PARTICIPANT_INSTRUMENTS = 'SET_V2_PARTICIPANT_INSTRUMENTS'
const SET_V2_PARTICIPANT_ANNOUNCEMENTS = 'SET_V2_PARTICIPANT_ANNOUNCEMENTS'
const SET_INSTRUMENT_TIMELINE = 'SET_INSTRUMENT_TIMELINE'
const SET_V2_INSTRUMENT_TIMELINE = 'SET_V2_INSTRUMENT_TIMELINE'
const SET_DIARY_TIMELINE = 'SET_DIARY_TIMELINE'
const SET_ANNOUNCEMENT_TIMELINE = 'SET_ANNOUNCEMENT_TIMELINE'
const SET_V2_ANNOUNCEMENT_TIMELINE = 'SET_V2_ANNOUNCEMENT_TIMELINE'
const RESET_INSTRUMENT_TIMELINE = 'RESET_INSTRUMENT_TIMELINE'
const RESET_DIARY_TIMELINE = 'RESET_DIARY_TIMELINE'
const RESET_ANNOUNCEMENT_TIMELINE = 'RESET_ANNOUNCEMENT_TIMELINE'
export const SET_PARTICIPANT_VISITS = 'SET_PARTICIPANT_VISITS'
export const CLEAR_PARTICIPANT_VISITS = 'CLEAR_PARTICIPANT_VISITS'
export const SET_VISIT_TEMPLATES = 'SET_VISIT_TEMPLATES'
const SET_SUBJECT_ID_STATUS = 'SET_SUBJECT_ID_STATUS'
const RESET_SUBJECT_ID_STATUS = 'RESET_SUBJECT_ID_STATUS'

//
// Action Creators
//

export function toggleCohort(id) {
  return {
    type: TOGGLE_COHORT,
    id,
  }
}

export function clearCohorts() {
  return {
    type: CLEAR_COHORTS,
  }
}

const resetParticipant = () => {
  return {
    type: RESET_PARTICIPANT,
  }
}

const clearPtpVisits = () => {
  return {
    type: CLEAR_PARTICIPANT_VISITS,
  }
}

const setSubjectIdStatus = subjectIdStatus => {
  return {
    type: SET_SUBJECT_ID_STATUS,
    subjectIdStatus,
  }
}

const resetSubjectIdStatus = () => {
  return {
    type: RESET_SUBJECT_ID_STATUS,
  }
}

export function saveToDatabase({
  studyID,
  siteID,
  participant,
  checkedCohorts: _checkedCohorts,
  participantID,
  isPatch = false,
  sitesArr,
  pDataFields,
  isNotLeaving,
  participantField,
  redirect = null,
}) {
  let currentSite
  return (dispatch, getState) => {
    const { drawer } = getState()

    const selectedTracks = Object.keys(_checkedCohorts)
    if (
      !validateParticipant({
        participant,
        dispatch,
        sitesArr,
        pDataFields,
        selectedTracks: selectedTracks.length ? selectedTracks : null,
        isPatch,
      })
    ) {
      return false
    }

    dispatch(modalActions.closeModal())
    const ptpCopy = { ...participant }
    const { phone } = ptpCopy
    if (!phone) ptpCopy.phone = ''

    currentSite = sitesArr.shift()
    ptpCopy.study_id = studyID
    ptpCopy.site_id = currentSite
    ptpCopy.study_groups = selectedTracks

    const hasParticipantDataDisabled = pDataFields?.disabled
    if (ptpCopy.visit_start_datetime === '') delete ptpCopy.visit_start_datetime
    if (hasParticipantDataDisabled) {
      hasParticipantDataDisabled.forEach(data => {
        delete ptpCopy[data.metadata.internal_key]
      })
    }
    if (isPatch && pDataFields) {
      const { mandatory, optional, disabled } = pDataFields
      const cantUpdateArr = [...mandatory, ...optional, ...disabled].filter(field => {
        return !(field.metadata.can_update ?? true)
      })
      cantUpdateArr.forEach(field => {
        delete ptpCopy[field.metadata.internal_key]
      })
    }

    const successMessage = `Successfully ${isPatch ? 'edited' : 'added'} participant`

    dispatch(sendingActions.startSender())
    return dispatch(
      request({
        method: isPatch ? 'PATCH' : 'POST',
        successMessage,
        url: `/control/${
          isPatch ? `studies/${studyID}/sites/${currentSite}/participants/${participantID}` : 'participants'
        }`,
        failMessage: 'Error saving participant',
        body: JSON.stringify(ptpCopy),
        success: response => {
          delete participant.password
          while (sitesArr.length > 0) {
            dispatch(
              saveToDatabase({
                studyID,
                siteID,
                participant,
                checkedCohorts: _checkedCohorts,
                participantID,
                sitesArr,
                pDataFields,
                isNotLeaving,
              }),
            )
          }
          dispatch(sendingActions.stopSender())
          if (
            participantField &&
            typeof participant[participantField] !== 'undefined' &&
            participant[participantField] !== null
          )
            dispatch(updateField(participantField, participant[participantField]))
          if (drawer.open && participantField === 'opt_out') dispatch(ptpActions.fetchPtps(studyID))
          setTimeout(() => {
            if (redirect) {
              browserHistory.push(`${redirect}?ptpId=${response.id}`)
            } else if (!isNotLeaving) {
              browserHistory.push(`/studies/${studyID}/participants`)
            }
            dispatch(sendingActions.resetSender())
          }, REDIRECT_DELAY)
        },
        fail: (res, content) => {
          dispatch(notyActions.showError({ text: generateNotyMessage(content.message, false) }))
          dispatch(sendingActions.resetSender())
        },
        hasLoader: true,
        loadingKey: 'request',
        hasSender: true,
      }),
    )
  }
}

function openSaveModal({
  studyID,
  siteID,
  participant,
  checkedCohorts: _checkedCohorts,
  participantID,
  consentRequired,
  sites,
  pDataFields,
  isNotLeaving,
  ptpField,
  hasVisitSupport,
  redirect = null,
}) {
  const _sitesArr = Object.keys(sites)
  const hasSites = _sitesArr.length > 0 && _sitesArr[0] !== 'null'
  const sitesArr = hasSites ? _sitesArr.map(idString => parseInt(idString, 10)) : []
  return dispatch => {
    if ('id' in participant) {
      return dispatch(
        saveToDatabase({
          studyID,
          siteID,
          participant,
          checkedCohorts: _checkedCohorts,
          participantID,
          isPatch: true,
          sitesArr,
          pDataFields,
          isNotLeaving,
          participantField: ptpField,
          hasVisitSupport,
        }),
      )
    }
    const onConfirm = () => {
      dispatch(
        saveToDatabase({
          studyID,
          siteID,
          participant,
          checkedCohorts: _checkedCohorts,
          participantID: null,
          isPatch: false,
          sitesArr,
          pDataFields,
          isNotLeaving,
          hasVisitSupport,
          redirect,
        }),
      )
    }
    if (consentRequired) {
      onConfirm()
    } else {
      dispatch(
        modalActions.openModal({
          content: MODAL_CONTENT_MAP.addStudyParticipant,
          confirmButton: MODAL_BUTTONS_MAP.confirm,
          cancelButton: MODAL_BUTTONS_MAP.cancel,
          className: MODAL_CLASSES_MAP.manualConsentConfirmation,
          onConfirm,
        }),
      )
    }
  }
}

export function downloadParticipantData({
  type,
  studyId,
  ptpIdArr,
  siteId,
  scheduleDate,
  formatType = 'long',
  resType = 'blob',
}) {
  let url
  let name
  let from
  let to
  let ptpQueryParams = ''
  if (ptpIdArr) {
    ptpIdArr.forEach(ptpId => {
      ptpQueryParams += `&participant_id=${ptpId}`
    })
  }
  const formatParam = `&format_type=${formatType}`
  if (type === 'diary') {
    if (scheduleDate && !scheduleDate.allDate) {
      if (typeof scheduleDate === 'string') {
        from = scheduleDate
        to = scheduleDate
      } else {
        from = moment(scheduleDate.from).format(DATE_FORMAT_MAP.datePicker)
        to = moment(scheduleDate.to).format(DATE_FORMAT_MAP.datePicker)
      }
      url = `/control/data/ediary?study_id=${studyId}&site_id=${siteId}&from=${from}&to=${to}${formatParam}${ptpQueryParams}`
      name = `_participant_diary_single_response`
    } else {
      // download entire responses from the participant
      url = `/control/data/ediary?study_id=${studyId}&site_id=${siteId}${formatParam}${ptpQueryParams}`
      name = 'participant_diary_all_responses'
    }
  }

  return dispatch => {
    const success = (blob, fileName) => {
      if (resType === 'text') {
        dispatch(
          notyActions.showSuccess({
            text: generateNotyMessage(blob, true),
          }),
        )
      } else {
        if (blob.type === BLOB_TYPE_MAP.zip) {
          downloadBlob(blob, `study_${studyId}${name}_${today(true)}.zip`, fileName)
        } else {
          downloadBlob(blob, `study_${studyId}${name}_${today(true)}.csv`, fileName)
        }
      }
    }
    dispatch(loadingActions.startLoader(true, type))
    return dispatch(
      request({
        url,
        resType,
        success,
      }),
    ).then(() => {
      dispatch(loadingActions.stopLoader(true, type))
    })
  }
}

function fetchParticipant(studyID, siteID, ptpID) {
  return (dispatch, getState) => {
    const { study } = getState()
    const { instrument_architecture_version: instrumentArchitectureVersion } = study?.currentStudy?.config || {}
    if (instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION) {
      return dispatch(
        request({
          method: 'GET',
          url: `/control/v2/studies/${studyID}/sites/${siteID}/participants/${ptpID}`,
          failMessage: 'Error fetching participant.',
          success: participant => {
            dispatch({
              type: SET_V2_PARTICIPANT,
              payload: participant,
            })
            dispatch(setV2ParticipantInstruments(participant.instruments))
            dispatch(setV2ParticipantAnnouncements(participant.instruments))
            if (participant.study_groups) {
              dispatch(initializeCheckedCohorts(Object.keys(participant.study_groups)))
            }
          },
        }),
      )
    } else {
      return dispatch(
        request({
          method: 'GET',
          url: `/control/studies/${studyID}/sites/${siteID}/participants/${ptpID}`,
          failMessage: 'Error fetching participant.',
          success: participant => {
            dispatch({
              type: SET_PARTICIPANT,
              payload: participant,
            })
            dispatch(setParticipantInstruments(participant.instruments))
            dispatch(setParticipantAnnouncements(participant.announcements))
            if (participant.study_groups) {
              dispatch(initializeCheckedCohorts(Object.keys(participant.study_groups)))
            }
          },
        }),
      )
    }
  }
}

function fetchPtpPayments(studyID, ptpID, isInSuperGemStudy = false) {
  const fetchHistoryFunc = () => {
    if (isInSuperGemStudy) {
      return _fetchTreasureChestPayoutHistory(studyID, false)
    }
    return _fetchPayments(studyID, false)
  }
  const payloadKey = isInSuperGemStudy ? 'completed_super_gem_payouts' : 'payout_info'
  return dispatch => {
    return dispatch(fetchHistoryFunc()).then(json => {
      dispatch({
        type: SET_PTP_PAYMENTS,
        payload: getFormattedPayments(
          json ? json[payloadKey].filter(pmt => ptpID === Number(pmt.participant.id)) : [],
          true, // hideName
          isInSuperGemStudy, // isGiftcards
          isInSuperGemStudy, // isDonations
        ),
      })
      dispatch(loadingActions.stopLoader(true, 'pendingPayments'))
    })
  }
}

const getLocaleOptions = locales => {
  const localesMap = {}
  const list = locales
    ? locales
        .map(option => {
          const { applanga, id, locale, name } = option
          localesMap[id] = { text: name, value: id, key: id }
          return {
            key: id,
            text: name,
            value: id,
            id,
            locale,
            applanga,
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    : []
  return { list, localesMap }
}

export const _updatePreviousScheduleVisit = (studyId, ssvId, ptpId, body, onSuccess = () => {}) => dispatch => {
  const success = payload => {
    onSuccess(payload)
  }

  return dispatch(
    request({
      method: 'PATCH',
      url: `/control/studies/${studyId}/study_schedules_visits/${ssvId}/participants/${ptpId}`,
      success,
      hasLoader: true,
      loadingKey: 'visitSchedules',
      body: JSON.stringify(body),
    }),
  )
}

export const fetchLocales = () => {
  return dispatch => {
    const success = payload => {
      const { list, localesMap } = getLocaleOptions(payload.languages)
      dispatch({
        type: SET_LOCALES,
        locales: list,
        localesMap,
      })
    }
    return dispatch(
      request({
        method: 'GET',
        url: '/control/locales',
        success,
      }),
    )
  }
}

export const fetchVisitTemplates = studyID => {
  return dispatch => {
    const success = payload =>
      dispatch({
        type: SET_VISIT_TEMPLATES,
        visits: payload.study_visit_schedule,
      })
    return dispatch(_fetchVisitSchedules(studyID, success))
  }
}

export const fetchFiles = (studyID, siteID, ptpID) => {
  return dispatch => {
    const success = payload => {
      dispatch({
        type: SET_FILES,
        files: payload.participant_files,
      })
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/sites/${siteID}/participant_files/${ptpID}`,
        success,
      }),
    )
  }
}

export const fetchParticipantVisits = (studyID, ptpID, isAdhocPage = false, isUpdate = false, ssvpID) => {
  return dispatch => {
    const success = payload => {
      dispatch({
        type: SET_PARTICIPANT_VISITS,
        visits: payload,
        isAdhocPage,
      })
      if (isUpdate) {
        dispatch(setAdhocVisit(payload, ssvpID))
      }
    }
    return dispatch(
      request({
        method: 'GET',
        url: `/control/studies/${studyID}/study_schedules_visits/participants/${ptpID}`,
        success,
        hasLoader: isAdhocPage,
        loadingKey: isAdhocPage ? 'participantVisits' : undefined,
      }),
    )
  }
}

const updatePreviousScheduleVisit = (studyId, ssvId, ptpId, body, onSuccess = () => {}) => dispatch => {
  const success = () => {
    onSuccess()
    dispatch(fetchParticipantVisits(studyId, ptpId))
  }
  return dispatch(_updatePreviousScheduleVisit(studyId, ssvId, ptpId, body, success))
}

export const formatSSVisits = obj =>
  objMapToList(obj, value => value).reduce((acc, item) => [...acc, ...objMapToList(item, value => value)], [])

const formatVisits = (visits, isAdhocPage) => {
  return [...formatSSVisits(visits.ss_visits_participant), ...visits.ss_visits_participant_adhoc].map((visit, idx) => {
    const {
      is_adhoc,
      name,
      participant_visit_metadata,
      visit_datetime,
      actual_visit_datetime,
      ssvp_id,
      visit_day,
      status = '',
      is_update_allowed = true,
    } = visit
    const visitName = (participant_visit_metadata?.visit_name || name) ?? `Visit ${ssvp_id}`
    const visitTime = moment.utc(visit_datetime)
    const actualVisitTime = moment.utc(actual_visit_datetime)
    const isDayAfter = moment().isAfter(visitTime, 'day')
    const className =
      status !== STATUS_OPTIONS.Missed && status !== STATUS_OPTIONS.Confirmed && !actual_visit_datetime && isDayAfter
        ? 'ptp-visit-cell-highlight'
        : ''
    const row = [
      {
        key: 'visitName',
        value: visitName,
        sortValue: visitName,
        visitId: ssvp_id,
        isAdhoc: is_adhoc,
        className: `ptp-visit-name ${className}`,
      },
      isAdhocPage ? { key: 'visitDay', value: visit_day ?? 'N/A', className: `ptp-visit-day ${className}` } : null,
      {
        key: 'scheduledDate',
        value: `${visitTime.format(DATE_FORMAT_MAP.main)}`,
        sortValue: visitTime.valueOf(),
        className,
      },
      {
        key: 'actualDate',
        value: actual_visit_datetime ? `${actualVisitTime.format(DATE_FORMAT_MAP.main)}` : 'N/A',
        sortValue: actualVisitTime.valueOf(),
        className,
      },
      !isAdhocPage
        ? {
            key: 'status',
            value: status || (isDayAfter ? STATUS_OPTIONS.Passed : STATUS_OPTIONS.Scheduled),
            className: `ptp-visit-status ${className}`,
          }
        : null,
      !isAdhocPage ? { key: 'action', type: '', title: 'Action', className, isUpdateAllowed: is_update_allowed } : null,
      isAdhocPage
        ? {
            key: 'status',
            value: status || (isDayAfter ? STATUS_OPTIONS.Passed : STATUS_OPTIONS.Scheduled),
            className: `ptp-visit-status ${className}`,
          }
        : null,
    ]
    return row.filter(obj => obj)
  })
}

const parseFiles = files => {
  const resultFiles = files.map(file => {
    const { file_name, original_file_name, tag_name, url } = file
    const type = file_name.match(fileTypeRegex)[0]
    const fileRow = [
      { key: 'type', value: type, sortValue: type, className: 'file-type' },
      { key: 'fileName', value: original_file_name, sortValue: original_file_name, className: 'ptp-file-name' },
      { key: 'category', value: tag_name, sortValue: tag_name },
      { key: 'action', value: url, sortValue: null, className: 'file-download' },
    ]
    return fileRow
  })
  return resultFiles
}

function initializeEditPage(studyID, siteID, ptpID, isInSuperGemStudy) {
  return dispatch => {
    dispatch(loadingActions.startLoader(true))
    return dispatch(fetchParticipant(studyID, siteID, ptpID)).then(() => {
      return dispatch(fetchPtpPayments(studyID, ptpID, isInSuperGemStudy)).then(() => {
        return dispatch(fetchLocales()).then(() => {
          return dispatch(loadingActions.stopLoader(true))
        })
      })
    })
  }
}

const markInstrumentComplete = ({ ptpID, studyID, siteID, instrumentsToComplete }, successCallback) => dispatch => {
  const instrumentsIdqArr = Object.keys(instrumentsToComplete)
  const _body = { idq_ids: instrumentsIdqArr }
  const success = () => {
    return dispatch(fetchParticipant(studyID, siteID, ptpID)).then(() => {
      successCallback()
      dispatch(loadingActions.stopLoader(true, 'participantInstruments'))
    })
  }

  const fail = (res, content) => {
    dispatch(loadingActions.stopLoader(true, 'participantInstruments'))
    dispatch(notyActions.showError({ text: generateNotyMessage(content.message, false) }))
  }
  dispatch(loadingActions.startLoader(true, 'participantInstruments'))
  dispatch(
    request({
      method: 'PATCH',
      url: `/control/studies/${studyID}/sites/${siteID}/participants/${ptpID}/instruments`,
      body: JSON.stringify(_body),
      success,
      fail,
    }),
  )
}

const regularDataValidation = {
  fname: 'fname',
  lname: 'lname',
  email: 'email',
  phone: 'phone',
  date_of_birth: 'date_of_birth',
  site_id: 'site_id',
  default_locale: 'default_locale',
  visit_start_datetime: 'visit_start_datetime',
  start_visit_id: 'start_visit_id',
}

const newDataValidation = {
  subject_id: 'subject_id',
  study_groups: 'study_groups',
}

const keysToSkipValidationsOnUpdate = [regularDataValidation.visit_start_datetime, regularDataValidation.start_visit_id]

const newMandatoryPtpFields = [
  {
    display_label: 'Locale',
    type: 'text',
    metadata: {
      internal_key: 'default_locale',
      custom_validations: '',
      is_custom: false,
      default_value: '',
      can_update: true,
    },
  },
]

export function validateParticipant({
  participant,
  dispatch,
  sitesArr,
  pDataFields = DEFAULT_PTP_DATA_FIELDS_CONFIG,
  selectedTracks,
  isPatch,
}) {
  const {
    fname,
    lname,
    email,
    phone,
    date_of_birth,
    visit_start_datetime,
    start_visit_id,
    subject_id,
    default_locale,
  } = participant
  let errorMessage = ''
  const mandatoryPtpFields = [...pDataFields?.mandatory, ...newMandatoryPtpFields]

  const defaultValidationKeys = Object.keys(regularDataValidation)

  let mandatoryValidations =
    mandatoryPtpFields?.length > 0
      ? defaultValidationKeys.filter(key => mandatoryPtpFields.map(data => data.metadata.internal_key).includes(key))
      : defaultValidationKeys

  if (mandatoryPtpFields?.length > 0) {
    const additionalValidationKeys = Object.keys(newDataValidation).filter(key =>
      mandatoryPtpFields.map(data => data.metadata.internal_key).includes(key),
    )
    if (additionalValidationKeys.length) mandatoryValidations.push(...additionalValidationKeys)
  }

  if (isPatch) {
    mandatoryValidations = mandatoryValidations.filter(key => {
      return !keysToSkipValidationsOnUpdate.includes(key)
    })
  }

  /**
   * We will create a map of custom validations if any field has any
   * custom validation (e.g. regex pattern the value must meet)
   */
  const customValidationsMap = mandatoryPtpFields.reduce((acc, currentField) => {
    const { metadata } = currentField
    const { custom_validations, internal_key } = metadata
    const obj = { ...acc }
    if (custom_validations) {
      obj[internal_key] = custom_validations
    }
    return obj
  }, {})

  const errors = getParticipantErrors({
    participantData: {
      fname,
      lname,
      email,
      phone,
      date_of_birth,
      site_id: sitesArr,
      visit_start_datetime,
      start_visit_id,
      subject_id,
      default_locale,
      study_groups: selectedTracks,
    },
    mandatoryValidations,
    customValidationsMap,
  })
  if (errors && errors.length > 0) {
    errorMessage += `${errors.join('<br>')}<br>`
  }
  if (errorMessage !== '' && dispatch) {
    dispatch(notyActions.showError({ text: generateNotyMessage(errorMessage, false) }))
  }
  return errorMessage === ''
}

function updateField(field, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_FIELD,
      field,
      value,
    })
  }
}

function triggerPasswordReset({ id, username }) {
  return dispatch => {
    dispatch(
      request({
        method: 'POST',
        url: `/control/participants/${id}/forgot_password`,
        body: JSON.stringify({ username, participant_id: id }),
        successMessage: 'Reset password email sent to participant',
      }),
    )
  }
}

function validateSubjectId({ studyId, subjectId, participantId }) {
  return dispatch => {
    const parameterObj = { study_id: studyId, subject_id: subjectId, participant_id: participantId }
    Object.keys(parameterObj).forEach(paramKey => {
      if (!parameterObj[paramKey]) delete parameterObj[paramKey]
    })
    const searchParams = new URLSearchParams(parameterObj)
    const queryParamStr = searchParams.toString()

    dispatch(
      request({
        method: 'GET',
        url: `/control/check-subject-id?${queryParamStr}`,
        success: json => {
          dispatch(setSubjectIdStatus(!json?.exists))
        },
      }),
    )
  }
}

function triggerResendInvite({ username, ptpId }) {
  return dispatch => {
    dispatch(
      request({
        method: 'POST',
        url: `/control/resend_invite`,
        body: JSON.stringify({ username, participant_id: ptpId }),
        successMessage: 'Resend invite email sent to participant',
      }),
    )
  }
}

function generateTempPassword({ studyID, ptpID, successCallback }) {
  return dispatch => {
    dispatch(
      request({
        method: 'POST',
        url: `/control/studies/${studyID}/participants/${ptpID}/temporary_password`,
        success: response => successCallback(response),
        hasLoader: true,
        loadingKey: 'tempPassword',
      }),
    )
  }
}

function initializePage({ fname = '', lname = '', defaultPtpValMap = {} }) {
  return dispatch => {
    return dispatch({
      type: INITIALIZE_CREATE_PTP,
      fname,
      lname,
      defaultPtpValMap,
    })
  }
}

export function initializeCheckedCohorts(cohorts) {
  return {
    type: INITIALIZE_CHECKED_COHORTS,
    cohorts,
  }
}

function resetInstrumentTimeline() {
  return {
    type: RESET_INSTRUMENT_TIMELINE,
  }
}

function setInstrumentTimeline(payload) {
  return {
    type: SET_INSTRUMENT_TIMELINE,
    payload,
  }
}

function setV2InstrumentTimeline(payload) {
  return {
    type: SET_V2_INSTRUMENT_TIMELINE,
    payload,
  }
}

function resetDiaryTimeline() {
  return {
    type: RESET_DIARY_TIMELINE,
  }
}

function setDiaryTimeline(payload) {
  return {
    type: SET_DIARY_TIMELINE,
    payload,
  }
}

export function _fetchInstrumentTimeline(instrumentId) {
  return dispatch => {
    const success = jsonBody => {
      return Promise.resolve(jsonBody.instruments_timeline)
    }
    return dispatch(
      request({
        url: `/control/instruments_queue/${instrumentId}/timeline`,
        success,
      }),
    ).then(payload => {
      return Promise.resolve(payload)
    })
  }
}

export function _fetchInstanceTimeline(instanceId) {
  return dispatch => {
    const success = jsonBody => {
      return Promise.resolve(jsonBody.instruments_timeline)
    }
    return dispatch(
      request({
        url: `/control/instances/${instanceId}/timeline`,
        success,
      }),
    ).then(payload => {
      return Promise.resolve(payload)
    })
  }
}

export const fetchInstrumentTimeline = instrumentId => (dispatch, getState) => {
  const { study } = getState()
  const { instrument_architecture_version: instrumentArchitectureVersion } = study?.currentStudy?.config || {}

  dispatch(loadingActions.startLoader(true, 'instrumentTimeline'))
  dispatch(resetInstrumentTimeline())
  dispatch(
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION
      ? _fetchInstanceTimeline(instrumentId)
      : _fetchInstrumentTimeline(instrumentId),
  ).then(payload => {
    dispatch(
      instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION
        ? setV2InstrumentTimeline(payload)
        : setInstrumentTimeline(payload),
    )
    dispatch(loadingActions.stopLoader(true, 'instrumentTimeline'))
  })
}

export const fetchDiaryTimeline = instrumentId => dispatch => {
  dispatch(loadingActions.startLoader(true, 'diaryTimeline'))
  dispatch(resetDiaryTimeline())
  dispatch(_fetchInstrumentTimeline(instrumentId)).then(payload => {
    dispatch(setDiaryTimeline(payload))
    dispatch(loadingActions.stopLoader(true, 'diaryTimeline'))
  })
}

function resetAnnouncementTimeline() {
  return {
    type: RESET_ANNOUNCEMENT_TIMELINE,
  }
}

function setAnnouncementTimeline(payload) {
  return {
    type: SET_ANNOUNCEMENT_TIMELINE,
    payload,
  }
}

function setV2AnnouncementTimeline(payload) {
  return {
    type: SET_V2_ANNOUNCEMENT_TIMELINE,
    payload,
  }
}

export function _fetchAnnouncementTimeline(announcementId) {
  return dispatch => {
    const success = jsonBody => {
      return Promise.resolve(jsonBody.announcements_timeline)
    }
    return dispatch(
      request({
        url: `/control/announcements_queue/${announcementId}/timeline`,
        success,
      }),
    ).then(payload => {
      return Promise.resolve(payload)
    })
  }
}

export const fetchAnnouncementTimeline = instrumentId => (dispatch, getState) => {
  const { study } = getState()
  const { instrument_architecture_version: instrumentArchitectureVersion } = study?.currentStudy?.config || {}

  dispatch(loadingActions.startLoader(true, 'announcementTimeline'))
  dispatch(resetAnnouncementTimeline())
  dispatch(
    instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION
      ? _fetchInstanceTimeline(instrumentId)
      : _fetchAnnouncementTimeline(instrumentId),
  ).then(payload => {
    dispatch(
      instrumentArchitectureVersion > DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION
        ? setV2AnnouncementTimeline(payload)
        : setAnnouncementTimeline(payload),
    )
    dispatch(loadingActions.stopLoader(true, 'announcementTimeline'))
  })
}

const parsePtp = participant => {
  const resultPtp = { ...participant }
  if (participant.subject_id === null) resultPtp.subject_id = ''
  return resultPtp
}

const parsePtpConsents = ({ consents }) => {
  if (!consents) return []
  const result = consents.map(consent => {
    const { completed_date, consent_id, consent_participant_status, consent_title, deployed_date } = consent
    const scheduledDate = deployed_date ? moment(deployed_date).format(DATE_FORMAT_MAP.mainWithDateTime) : ''
    const completedDate = completed_date ? moment(completed_date).format(DATE_FORMAT_MAP.mainWithDateTime) : ''

    const checkboxEnabled = () => {
      return !['awaiting_clinro', 'complete'].includes(consent_participant_status)
    }

    const { text, sortValue } = PARTICIPANT_CONSENT_STATUS_MAP[consent_participant_status]

    const row = [
      { key: 'status', value: text, sortValue },
      {
        key: 'name',
        value: consent_title,
        completed: consent_participant_status === 'complete',
        consentId: consent_id,
        canUpload: checkboxEnabled(),
      },
      { key: 'scheduled', value: scheduledDate },
      { key: 'completion', value: completedDate },
    ]
    return row
  })
  return result
}

function getInitialState(fname = '', lname = '', defaultPtpValMap) {
  return {
    fname,
    lname,
    email: defaultPtpValMap?.email !== undefined ? defaultPtpValMap.email : '',
    phone: defaultPtpValMap?.phone !== undefined ? defaultPtpValMap.phone : '',
    gender: defaultPtpValMap?.gender !== undefined ? defaultPtpValMap.gender : 'n',
    subject_id: defaultPtpValMap?.subject_id !== undefined ? defaultPtpValMap.subject_id : '',
    default_locale: defaultPtpValMap?.default_locale !== undefined ? defaultPtpValMap.default_locale : 'en_US',
    date_of_birth: defaultPtpValMap?.date_of_birth !== undefined ? defaultPtpValMap.date_of_birth : '1980-01-01',
    is_caregiver: defaultPtpValMap?.is_caregiver !== undefined ? defaultPtpValMap.is_caregiver : false,
    is_self_sign_on: false,
    visit_start_datetime: '',
    start_visit_id: '',
  }
}

const setParticipantAnnouncements = (ptpAnnouncements = []) => dispatch => {
  dispatch(loadingActions.startLoader(true, 'participantsAnnouncements'))
  const keys = ptpAnnouncements.map(ptpAnnouncement => Object.keys(ptpAnnouncement)).flat()
  const values = ptpAnnouncements.map((ptpAnnouncement, idx) => ptpAnnouncement[keys[idx]]).flat()
  const formattedValues = values.map((item, idx) => ({
    ...item,
    idqId: Number(keys[idx]),
  }))

  const announcementList = formattedValues.map(item => {
    const { title, status, status_timestamp, idqId, info } = item
    const { utc_offset } = info || {}

    const statusDate = moment(status_timestamp).utc()
    const utcStatusDate = getFormattedUTCTimestamp(status_timestamp, utc_offset)

    return [
      { key: 'name', value: title, sortValue: title },
      {
        key: 'status',
        value: COMBINED_INSTRUMENT_TIMELINE_MAP[status] || NOT_APPLICABLE_PLACEHOLDER,
        sortValue: COMBINED_INSTRUMENT_TIMELINE_MAP[status] || 'zz',
      },
      {
        key: 'statusTimestamp',
        value: status_timestamp ? utcStatusDate : undefined,
        sortValue: status_timestamp ? statusDate.valueOf() : 'zz',
        idqId,
      },
    ]
  })
  dispatch({
    type: SET_PARTICIPANT_ANNOUNCEMENTS,
    announcements: announcementList,
  })
  dispatch(loadingActions.stopLoader(true, 'participantsAnnouncements'))
}

const setParticipantInstruments = (ptpInstruments = {}) => dispatch => {
  const { completed, pending } = ptpInstruments
  dispatch(loadingActions.startLoader(true, 'participantInstruments'))
  const instruments = { ...completed, ...pending }
  const hasInstruments = Object.keys(instruments).length > 0

  const list = hasInstruments
    ? Object.keys(instruments).map(key => {
        const instrumentObj = instruments[key]
        instrumentObj.idqId = key
        return instrumentObj
      })
    : []

  const getCycleNumValAndSortVal = (cycleNum, completionDate) => {
    const largeNumber = Number.MAX_SAFE_INTEGER
    if (completionDate) {
      if (cycleNum) {
        return { cycleNum, sortVal: cycleNum }
      }
      return { cycleNum: 'Post-study', sortVal: largeNumber }
    }
    return { cycleNum: '', sortVal: largeNumber + 1 }
  }

  const instrumentList = list.map(item => {
    const {
      completion_timestamp,
      cycle_no,
      idqId,
      status,
      mark_instrument_complete: instrumentMarkedComplete,
      completed_utc_offset,
    } = item // idqId is the instrument deployment queue ID
    const { cycleNum, sortVal: cycleNumSortVal } = getCycleNumValAndSortVal(cycle_no, completion_timestamp)

    const { event_timestamp, event_type, event_utc_offset } = status || {}
    /**
     * As completion_timestamp is UTC,
     * we need to invoke the utc method on the moment object to set it to UTC
     */
    const completionDate = moment(completion_timestamp).utc()
    const eventDate = moment(event_timestamp).utc()
    const utcEventDate = getFormattedUTCTimestamp(event_timestamp, event_utc_offset)

    const getActionValue = () => {
      if (completion_timestamp) {
        if (instrumentMarkedComplete) {
          return 'Marked complete'
        }
        return 'completed'
      }
      return 'canComplete'
    }
    return [
      { key: 'name', value: item.title, sortValue: item.title },
      { key: 'cycleNo', value: cycleNum, sortValue: cycleNumSortVal },
      {
        key: 'completionDate',
        value: completion_timestamp
          ? completionDate.add(completed_utc_offset || 0, 'minutes').format(DATE_FORMAT_MAP.mainWithDateTime)
          : NOT_APPLICABLE_PLACEHOLDER,
        sortValue: completion_timestamp ? completionDate.valueOf() : cycleNumSortVal,
        completed: !!completion_timestamp,
      },
      {
        key: 'status',
        value: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || NOT_APPLICABLE_PLACEHOLDER,
        sortValue: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || 'zz',
      },
      {
        key: 'statusTimestamp',
        value: event_timestamp ? utcEventDate : null,
        sortValue: event_timestamp ? eventDate.valueOf() : 0,
      },
      {
        key: 'action',
        value: getActionValue(),
        idqId,
        completed: !!completion_timestamp,
      },
    ]
  })
  dispatch({
    type: SET_PARTICIPANT_INSTRUMENTS,
    instruments: instrumentList,
  })
  dispatch(loadingActions.stopLoader(true, 'participantInstruments'))
}

const setV2ParticipantAnnouncements = (ptpInstruments = {}) => dispatch => {
  dispatch(loadingActions.startLoader(true, 'participantsAnnouncements'))
  const hasInstruments = Object.keys(ptpInstruments).length > 0

  const list = hasInstruments
    ? Object.keys(ptpInstruments).map(key => {
        const instrumentObj = ptpInstruments[key]
        instrumentObj.idqId = key
        return instrumentObj
      })
    : []

  const filteredList = list.filter(item => item.instrument_type === INSTRUMENT_TYPE_MAP.announcement)

  const announcementList = filteredList.map(item => {
    const { title, status, idqId } = item
    const { event_timestamp, event_type, event_utc_offset } = status || {}
    /**
     * As completion_timestamp is UTC,
     * we need to invoke the utc method on the moment object to set it to UTC
     */
    const eventDate = moment(event_timestamp).utc()
    const utcEventDate = getFormattedUTCTimestamp(event_timestamp, event_utc_offset)

    return [
      { key: 'name', value: title, sortValue: title },
      {
        key: 'status',
        value: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || NOT_APPLICABLE_PLACEHOLDER,
        sortValue: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || 'zz',
      },
      {
        key: 'statusTimestamp',
        value: event_timestamp ? utcEventDate : null,
        sortValue: event_timestamp ? eventDate.valueOf() : 0,
        idqId,
      },
    ]
  })

  dispatch({
    type: SET_V2_PARTICIPANT_ANNOUNCEMENTS,
    announcements: announcementList,
  })
  dispatch(loadingActions.stopLoader(true, 'participantsAnnouncements'))
}

const setV2ParticipantInstruments = (ptpInstruments = {}) => dispatch => {
  dispatch(loadingActions.startLoader(true, 'participantInstruments'))
  const hasInstruments = Object.keys(ptpInstruments).length > 0

  const list = hasInstruments
    ? Object.keys(ptpInstruments).map(key => {
        const instrumentObj = ptpInstruments[key]
        instrumentObj.idqId = key
        return instrumentObj
      })
    : []

  const filteredList = list.filter(item => item.instrument_type !== INSTRUMENT_TYPE_MAP.announcement)

  const getCycleNumValAndSortVal = (cycleNum, completionDate) => {
    const largeNumber = Number.MAX_SAFE_INTEGER
    if (completionDate) {
      if (cycleNum) {
        return { cycleNum, sortVal: cycleNum }
      }
      return { cycleNum: 'Post-study', sortVal: largeNumber }
    }
    return { cycleNum: '', sortVal: largeNumber + 1 }
  }

  const instrumentList = filteredList.map(item => {
    const {
      completion_timestamp,
      cycle_no,
      idqId,
      status,
      mark_instrument_complete: instrumentMarkedComplete,
      completed_utc_offset,
    } = item // idqId is the instrument deployment queue ID
    const { cycleNum, sortVal: cycleNumSortVal } = getCycleNumValAndSortVal(cycle_no, completion_timestamp)

    const { event_timestamp, event_type, event_utc_offset } = status || {}
    /**
     * As completion_timestamp is UTC,
     * we need to invoke the utc method on the moment object to set it to UTC
     */
    const completionDate = moment(completion_timestamp).utc()
    const eventDate = moment(event_timestamp).utc()
    const utcEventDate = getFormattedUTCTimestamp(event_timestamp, event_utc_offset)

    const getActionValue = () => {
      if (completion_timestamp) {
        if (instrumentMarkedComplete) {
          return 'Marked complete'
        }
        return 'completed'
      }
      return 'canComplete'
    }
    return [
      { key: 'name', value: item.title, sortValue: item.title },
      { key: 'cycleNo', value: cycleNum, sortValue: cycleNumSortVal },
      {
        key: 'completionDate',
        value: completion_timestamp
          ? completionDate.add(completed_utc_offset || 0, 'minutes').format(DATE_FORMAT_MAP.mainWithDateTime)
          : NOT_APPLICABLE_PLACEHOLDER,
        sortValue: completion_timestamp ? completionDate.valueOf() : cycleNumSortVal,
        completed: !!completion_timestamp,
      },
      {
        key: 'status',
        value: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || NOT_APPLICABLE_PLACEHOLDER,
        sortValue: COMBINED_INSTRUMENT_TIMELINE_MAP[event_type] || 'zz',
      },
      {
        key: 'statusTimestamp',
        value: event_timestamp ? utcEventDate : null,
        sortValue: event_timestamp ? eventDate.valueOf() : 0,
      },
      {
        key: 'action',
        value: getActionValue(),
        idqId,
        completed: !!completion_timestamp,
      },
    ]
  })
  dispatch({
    type: SET_V2_PARTICIPANT_INSTRUMENTS,
    instruments: instrumentList,
  })
  dispatch(loadingActions.stopLoader(true, 'participantInstruments'))
}

function setParticipantDiaries(diaries = {}) {
  const diaryKeys = Object.keys(diaries)
  return diaryKeys
    .map(key => {
      const diary = diaries[key]
      const ptpDiaryKeys = Object.keys(diary)
      return ptpDiaryKeys.map(ptpDiaryKey => {
        const ptpDiary = diary[ptpDiaryKey]
        const { completion_timestamp, completed_utc_offset, schedule_timestamp, title, completed_dep_queue_id } =
          ptpDiary || {}

        /**
         * As completion_timestamp is UTC,
         * we need to invoke the utc method on the moment object to set it to UTC
         */
        let completionDate = completion_timestamp ? moment(completion_timestamp).utc() : ''
        completionDate = completionDate ? completionDate.add(completed_utc_offset || 0, 'minutes') : ''

        const scheduleDate = schedule_timestamp ? moment(schedule_timestamp, DATE_FORMAT_MAP.diaryScheduledDate) : ''

        return [
          { key: 'name', value: title || NOT_APPLICABLE_PLACEHOLDER, sortValue: title },
          {
            key: 'scheduleDate',
            value: scheduleDate ? scheduleDate.format(DATE_FORMAT_MAP.main) : NOT_APPLICABLE_PLACEHOLDER,
            sortValue: scheduleDate ? scheduleDate.valueOf() : Number.MAX_SAFE_INTEGER,
          },
          {
            key: 'completionDate',
            value: completionDate
              ? completionDate.format(DATE_FORMAT_MAP.mainWithDateTime)
              : NOT_APPLICABLE_PLACEHOLDER,
            sortValue: completionDate ? completionDate.valueOf() : Number.MAX_SAFE_INTEGER,
            completed: !!completion_timestamp,
          },
          {
            key: 'action',
            value: 'download',
            id: `${key}-${scheduleDate}`,
            idqId: completed_dep_queue_id,
          },
        ]
      })
    })
    .flat()
}

/**
 * Decline the account deletion request of the participant.
 * @param {Number} ptpId participant id
 * @param {Number} studyID study id
 * @param {Number} siteID site id
 * @returns
 */
const submitCancelDeactivation = ({ ptpId, studyID, siteID }) => dispatch => {
  const success = () => {
    dispatch(fetchParticipant(studyID, siteID, ptpId))
    dispatch(ptpActions.fetchPtps(studyID))
  }
  return dispatch(
    request({
      method: 'POST',
      url: `/control/studies/${studyID}/participants/${ptpId}/decline`,
      successMessage: 'Account Deletion Request Deactivated',
      success,
      hasLoader: true,
    }),
  )
}

//
// Reducers
//

export const participant = (state = {}, action) => {
  let newState
  switch (action.type) {
    case INITIALIZE_CREATE_PTP: {
      return getInitialState(action.fname, action.lname, action.defaultPtpValMap)
    }
    case UPDATE_FIELD:
      newState = { ...state }
      newState[action.field] = action.value
      return newState
    case SET_V2_PARTICIPANT:
    case SET_PARTICIPANT:
      return parsePtp(action.payload)
    case RESET_PARTICIPANT:
      return {}
    default:
      return state
  }
}

export const participantConsents = (state = [], action) => {
  switch (action.type) {
    case SET_V2_PARTICIPANT:
    case SET_PARTICIPANT:
      return parsePtpConsents(action.payload)
    default:
      return state
  }
}

const payments = (state = { totals: {}, pendingTotal: 0, completedList: [], pendingList: [] }, action) => {
  switch (action.type) {
    case SET_PTP_PAYMENTS:
      return action.payload
    default:
      return state
  }
}

const instrumentStatus = (state = [], action) => {
  switch (action.type) {
    case SET_V2_PARTICIPANT_INSTRUMENTS:
    case SET_PARTICIPANT_INSTRUMENTS:
      return action.instruments
    default:
      return state
  }
}

const announcementStatus = (state = [], action) => {
  switch (action.type) {
    case SET_V2_PARTICIPANT_ANNOUNCEMENTS:
    case SET_PARTICIPANT_ANNOUNCEMENTS:
      return action.announcements
    default:
      return state
  }
}

const diaryStatus = (state = [], action) => {
  switch (action.type) {
    case SET_V2_PARTICIPANT:
    case SET_PARTICIPANT: {
      return setParticipantDiaries(action.payload.ediaries)
    }
    default:
      return state
  }
}

export function checkedCohorts(state = {}, action) {
  let newState
  switch (action.type) {
    case INITIALIZE_CHECKED_COHORTS:
      newState = {}
      action.cohorts.forEach(cohortID => {
        newState[cohortID] = null
      })
      return newState
    case INITIALIZE_CREATE_PTP:
      return {}
    case TOGGLE_COHORT:
      newState = { ...state }
      if (action.id in newState) delete newState[action.id]
      else newState[action.id] = null
      return newState
    case CLEAR_COHORTS:
      return {}
    default:
      return state
  }
}

export const locales = (state = [], action) => {
  switch (action.type) {
    case SET_LOCALES:
      return action.locales
    default:
      return state
  }
}

export const instrumentTimeline = (state = [], action) => {
  switch (action.type) {
    case SET_V2_INSTRUMENT_TIMELINE:
      return action.payload
    case SET_INSTRUMENT_TIMELINE:
      return action.payload
    case RESET_INSTRUMENT_TIMELINE:
      return []
    default:
      return state
  }
}

export const diaryTimeline = (state = [], action) => {
  switch (action.type) {
    case SET_DIARY_TIMELINE:
      return action.payload
    case RESET_DIARY_TIMELINE:
      return []
    default:
      return state
  }
}

export const announcementTimeline = (state = [], action) => {
  switch (action.type) {
    case SET_V2_ANNOUNCEMENT_TIMELINE:
      return action.payload
    case SET_ANNOUNCEMENT_TIMELINE:
      return action.payload
    case RESET_ANNOUNCEMENT_TIMELINE:
      return []
    default:
      return state
  }
}

export const localesMap = (state = {}, action) => {
  switch (action.type) {
    case SET_LOCALES:
      return action.localesMap
    default:
      return state
  }
}

export const participantVisits = (state = [], action) => {
  switch (action.type) {
    case SET_PARTICIPANT_VISITS:
      return formatVisits(action.visits, action.isAdhocPage)
    case CLEAR_PARTICIPANT_VISITS:
    case RESET_PARTICIPANT:
      return []
    default:
      return state
  }
}

export const visitTemplates = (state = [], action) => {
  switch (action.type) {
    case SET_VISIT_TEMPLATES:
      return action.visits
    default:
      return state
  }
}

export const files = (state = [], action) => {
  switch (action.type) {
    case SET_FILES:
      return parseFiles(action.files)
    default:
      return state
  }
}

export const isSubjectIdAvailable = (state = null, action) => {
  switch (action.type) {
    case SET_SUBJECT_ID_STATUS:
      return action.subjectIdStatus
    case RESET_SUBJECT_ID_STATUS:
      return null
    default:
      return state
  }
}

export default combineReducers({
  documents,
  participant,
  participantConsents,
  checkedCohorts,
  payments,
  instrumentStatus,
  announcementStatus,
  diaryStatus,
  locales,
  files,
  participantVisits,
  visitTemplates,
  instrumentTimeline,
  diaryTimeline,
  announcementTimeline,
  isSubjectIdAvailable,
})

export const actions = {
  closeModal: modalActions.closeModal,
  clearPtpVisits,
  downloadParticipantData,
  fetchFiles,
  fetchLocales,
  fetchParticipant,
  fetchPtpPayments,
  generateTempPassword,
  initializeCheckedCohorts,
  initializeEditPage,
  initializePage,
  markInstrumentComplete,
  openModal: modalActions.openModal,
  resetParticipant,
  saveToDatabase: openSaveModal,
  toggleCohort,
  clearCohorts,
  triggerPasswordReset,
  validateSubjectId,
  resetSubjectIdStatus,
  triggerResendInvite,
  updateField,
  ...documentsActions,
  submitCancelDeactivation,
  fetchParticipantVisits,
  fetchVisitTemplates,
  updatePreviousScheduleVisit,
  resetInstrumentTimeline,
  resetDiaryTimeline,
  fetchInstrumentTimeline,
  fetchDiaryTimeline,
  resetAnnouncementTimeline,
  fetchAnnouncementTimeline,
}
