const STRINGS = {
  acceptAllHours: 'Accept all hours',
  acceptFrom: 'Accept from',
  acceptFutureDates: 'Accept future dates',
  acceptFutureDatesTime: 'Accept future dates and time',
  acceptFutureDatesTimeTooltip:
    'When "Accept future dates and time" toggle is on, participants will be able to select future dates/time from their current date/time if it is within the acceptable configured range.',
  acceptFutureDatesTooltip:
    'When "Accept future dates" toggle is on, participants will be able to select future dates/time from their current date/time if it is within the acceptable configured range.',
  acceptFutureTime: 'Accept future time',
  acceptFutureTimeTooltip:
    'When "Accept future time" toggle is on, participants will be able to select future times from their current time if it is within the acceptable time range.',
  acceptTo: 'Accept to',
  accountDeletion: 'Account Deletion',
  accountDeletionDesc:
    'When the account is deleted, data collection is paused for the particiant, and the participant will not be able to log in. The data for this participant will not be deleted.',
  accountDisabled: 'Account disabled',
  accountDisabledDesc:
    'When the account is disabled, data collection is paused for the participant, and the participant will not be able to log in. The data for this participant will not be deleted.',
  accountEnabled: 'Account enabled',
  accountLogin: 'Account Login',
  accountSettings: 'Account settings',
  accountSettingsCap: 'Account Settings',
  accountSettingsDesc:
    "Is the meeting is actively happening now, or is the participant ready to login to their device? If yes, you may communicate this temporary password now to assist with the participant's first login process.",
  accountSettingsTitle: 'Speaking with the participant now?',
  actualVisitDate: 'Actual visit date',
  addendumForPrefix: 'Addendum for',
  addendumDescription:
    'Addendum is a supplementary to an existing consent. A new addendum will not replace a consent form.',
  addWindow: 'add window',
  adhocVisitDel: 'Adhoc Visit deleted successfully',
  advancedFeatures: 'Advanced features',
  after: 'after',
  afterCompletionOfVisit: 'after completion of visit.',
  afterInstrumentDeployment: 'after instrument deployment day',
  afterReceiptOfArticle: 'after receipt of item.',
  allAudioFiles: 'All Audio Files',
  allFutureDates: 'All future dates',
  allFutureDatesTime: 'All future dates and time',
  allow: 'Allow',
  allowPtps: 'Allow participants',
  allPastDates: 'All past dates',
  allPastDatesTime: 'All past dates and time',
  allStudies: 'All Studies',
  appDisplayName: 'App Display Name',
  appDisplayNameErr: 'App display name cannot be empty',
  at: 'at',
  audioFiles: 'Audio Files',
  avatarItems: 'Avatar Items',
  back: 'Back',
  before: 'before',
  beforeInstrumentDeployment: 'before instrument deployment day',
  cancel: 'Cancel',
  cannotBeEmpty: 'Cannot be empty',
  caseManagement: 'Case management',
  cases: 'Cases',
  celebratory: 'Celebratory',
  clinicianReportedOutcomes: 'Clinician Reported Outcomes',
  clinroConfigBody:
    'Invite Clinicians to your study to administer clinician-reported outcome assessments. Manage Clinicians from different sites and assign site-specific surveys for each of them',
  close: 'Close',
  communication: 'Communication',
  compliance: 'Compliance',
  configuration: 'Configuration',
  confirmAndRejectRequest: 'Confirm and reject request',
  consentContent: 'Consent content',
  consentPdfFormatPreview: 'Page format preview',
  consentPdfPageConsentContent: 'CONSENT CONTENT HERE',
  consentPdfPagePlaceholderFooterOne: 'FOOTER 1',
  consentPdfPagePlaceholderFooterTwo: 'FOOTER 2',
  consentPdfPagePlaceholderHeaderOne: 'HEADER 1',
  consentPdfPagePlaceholderHeaderThree: 'HEADER 3',
  consentPdfPagePlaceholderHeaderTwo: 'HEADER 2',
  consentPdfPlaceholderPageNumber: '1/21',
  consents: 'Consents',
  createStudy: 'Create Study',
  createStudyAddPaymentAcct: 'Add Payment Account',
  createStudyManagePayments: 'Or, manage payments yourself',
  createStudyPaymentsManaged: 'Payments will be handled and managed by you',
  createStudyPaymentCurrency: 'Payment currency',
  createStudySelectCurrency: 'Select a currency',
  customOnboarding: 'Custom onboarding',
  customReportButton: 'Custom report button',
  dataCorrection: 'Data Correction',
  datacubedInsights: 'Datacubed Insights',
  dataSync: 'Data Sync Indicator',
  dataSyncConfigBody:
    'When a participant completes an instrument, should a data sync toast appear at the bottom of the screen to show the status of the data transfer?',
  day: 'day',
  days: 'days',
  delete: 'Delete',
  deleteAdhocVisit: 'Delete Adhoc Visit',
  deleteAdhocVisitConfirm: 'Are you sure you want to delete this adhoc visit? This cannot be undone.',
  deleteVisit: 'Delete Visit',
  deleteVisitConfirm: 'Are you sure you want to delete this visit? This cannot be undone.',
  diaryTimedEntry: 'How many days after diary deployment should participants be allowed to complete diary entries?',
  instrumentTimedEntry:
    'How many days after instrument deployment should participants be allowed to complete instrument entries?',
  didVisitHappen: 'Did this visit happen?',
  disable: 'Disable',
  disableAccount: 'Disable Account',
  disableAccountDesc: 'Disabling this account will force log out the participant from the app.',
  displayEndOfStudy: 'Display end of study summary',
  document: 'Document',
  documentName: 'Document Name',
  download: 'Download',
  downloadAllAudioFiles: 'Download All Audio Files',
  eConsent: 'eConsent',
  eConsentConfigBody:
    'Deploy eConsent in your study to electronically manage the consent process and facilitate seamless user participation',
  emaillessPCI: 'Participant Contact Information',
  emaillessPCIBody:
    "Participant email address or phone number is not collected on DCH platform by default.<br />Participant's initial account setup and account recovery will need to be completed through an offline authentication process. ",
  emaillessPCINote:
    "Please note: When email collection and/or phone number collection is enabled, participant's contact information will be visible to all admin users through DCH platform.",
  emaillessPCIOpt1: 'Continue without collecting any participant contact information.',
  emaillessPCIOpt2: 'Collect participant email address on DCH.',
  enable: 'Enable',
  enableAccount: 'Enable Account',
  enableAccountDesc: 'Enabling this account will allow participants to log in again.',
  endOfStudyConfigBody:
    'At the end of the study journey, participants can get a full summary of their participation, including number of chapters completed, number of activities completed, and avatar items unlocked',
  endOfStudySummary: 'End of Study Summary',
  enterHere: 'Enter here...',
  errPleaseASelectDiffDeployNoVisits: 'Please select a deployment option other than "Triggered by Visit"',
  expire: 'Expire',
  features: 'Features',
  foodNutritionalReview: 'Show Nutritional value review',
  foodNutritionalReviewPopup:
    'Activate "Nutritional Insights" to enable participants to view detailed nutritional information for each food entry and Review screen.',
  generateTemporaryPassword: 'Generate Temporary Password',
  geofenceInstruments: 'Geofence-triggered instruments',
  geofences: 'Geofences',
  giftcardConfig: 'Gift card configurations',
  hide: 'Hide',
  incentives: 'Incentives',
  insightsDashboardUnavailable: 'Datacubed insights dashboard unavailable.',
  instrumentCompletionOrder: 'Instrument Completion Order',
  instrumentDeployedDay: 'Day when the instrument is deployed',
  instrumentOrder: 'Instrument Order',
  instruments: 'Instruments',
  languages: 'Languages',
  lastLogin: 'Last login: ',
  loseUnsavedContent: 'Closing out would lose any unsaved content.',
  medicationAdherence: 'Medication Adherence',
  medicationAdherenceConfigBody:
    "Monitor participants' medication adherence by setting up a tracker and increase compliance rates by reminding participants to take their prescribed medications at the correct times",
  midtermRewards: 'Mid-term Rewards',
  midtermRewardsBody:
    'If on, a second currency called “Super Gems” will be introduced to participants, with which they will be able to unlock special rewards like minigames, gift cards and the option to donate to a charity you select.',
  midtermRewardsSubheading:
    'When a participant reaches the end of a mid-term interval, what kind of reward should they receive?',
  neutral: 'Neutral',
  neverExpire: 'Never Expire',
  next: 'Next',
  nextVisitDate: 'Next Visit Date',
  no: 'No',
  noDocuments: 'There are no documents at this time',
  notifications: 'Notifications',
  noTrainingDocument: 'No training document is uploaded at this time',
  noTrainingVideo: 'No training video is uploaded at this time',
  ofRecipientLocalTime: "of recipient's local time.",
  orAttach: 'Or attach by URL',
  ordered: 'Ordered',
  orderedInstrumentsBody:
    'If a participant has more than 1 activity at a given time, there is a specific order in which surveys need to be completed',
  orderedInstrumentsHeading: 'Ordered Instruments',
  participant: 'Participant',
  participantCurrentDate: 'Participant current date',
  participantCurrentTime: 'Participant current time',
  participantId: 'Participant ID',
  participants: 'Participants',
  participantValidationName: 'Name field cannot be empty',
  participantValidationValidName: 'Please enter a valid name',
  participantValidationEmail: 'Email field cannot be empty',
  participantValidationDateOfBirthFuture: 'Date of birth cannot be in the future',
  participantValidationDateOfBirthValid: 'Please enter a valid date of birth',
  participantValidationEmailInvalid: 'Invalid email address',
  participantValidationLocale: 'Please select a locale',
  participantValidationPhoneEmpty: 'Phone number field cannot be empty',
  participantValidationPhoneValid: 'Please enter a valid phone number',
  participantValidationSubjectId: 'Please enter a subject ID',
  participantValidationValidSubjectId: 'Please enter a valid subject ID',
  participantValidationTrack: 'Please assign the participant to a track',
  participantValidationVisitDatePast: 'Visit Date cannot be in the past',
  participantValidationVisitDateValid: 'Please enter a valid visit date',
  participantValidationVisitNameValid: 'Please select a visit name',
  password: 'Password',
  pleaseCheckErr: 'Please check errors before proceeding.',
  pleaseConfirmActualDate:
    'Please confirm the actual date this visit occurred on. If this visit did not happen, please mark “No, this visit was missed”.',
  pleaseNote: 'Please note',
  pleaseSelDifferentVisit: 'Please select a different visit',
  preparing: 'Preparing',
  visitScheduleManagement: 'Visit Schedule Management',
  profileCreated: 'Profile Created',
  ptpAppSettings: 'Participant App Settings',
  networkConnectionReminder: 'Network Connection Reminder',
  ptpOfflineDurationDesc:
    'How many days of consecutive offline app usage before the participant is prompted to connect to the network? Network is necessary for the system to retrieve data collected during offline app usage.',
  ptpOfflineDurationInputPretext: 'Send network connection reminder after',
  ptpOfflineDurationInputPosttext: 'consecutive day(s) of offline app usage.',
  ptpContactInformationCollection: 'Participant Contact Information Collection',
  ptpNoVisitScheduleAssigned:
    'This participant does not have a visit schedule assigned yet. Please confirm when and which visit schedule the participant should complete next.',
  ptpNoVisitScheduleCreated:
    'No visit schedule is created yet for this study. These fields will be enabled after visit schedule is created.',
  ptpSubjectId: 'Participant Subject ID',
  questionErrorNoPdf: 'Please upload a consent',
  removeWindow: 'remove window',
  repeat: 'Repeat',
  review: 'Review',
  rewards: 'Rewards',
  rewardSelection: 'Reward Selection',
  saved: '...Saved!',
  saveVisit: 'Save Visit',
  saveVisitSchedule: 'Save Visit Schedule',
  saving: 'Saving',
  searchEllipsis: 'Search...',
  security: 'Security',
  selectDate: 'Select date',
  selectDateRange: "Please select date range acceptable for this question's answer.",
  selectDateTimeRange: "Please select date and time range acceptable for this question's answer.",
  selectPlaceholder: 'Select...',
  selectTime: 'Select time',
  selectTimeRange: "Please select time range acceptable for this question's answer.",
  selfSignUpCode:
    'Participants can use this Study Sign-up code or the Site Code to create their accounts through our self sign-up website.',
  sendTo: 'Send to',
  show: 'Show',
  showPtpSubjectId: ' Show participants their own subject ID',
  sites: 'Sites',
  specialRewards: 'Special Rewards',
  specificDate: 'Specific date',
  studies: 'Studies',
  studyConfigurations: 'Study Configurations',
  studyInfoViewOnly: 'Study Information (View Only)',
  studyName: 'Study Name',
  subjectId: 'The Subject ID is a string of numbers and/or letters that you can assign when adding a new participant',
  submitADcf: 'Submit a DCF',
  surveySelectDisplayCheckbox: 'Display checkbox or radio buttons with the answer choices.',
  surveyIntroDescription: 'Introduction text will be given in a speech bubble by Humi.',
  surveyIntroDisplayGreeting: 'Display greeting in introduction.',
  surveyNRSOptOutPlaceholder: 'UI display answer',
  temporaryPassword: 'Temporary Password: ',
  temporaryPasswordCaption:
    'DCH does not deliver temporary password to the participant. Please connect with the participant offline to deliver the log in credentials.',
  temporaryPasswordNote: 'Please note: Temporary password will expire in ',
  timedCompletion: 'Timed completion',
  timedEntryForPtpsToEdit: 'to complete incomplete diary entries after deployment.',
  timedEntryInfoTip:
    'Participants will only be allowed to edit incomplete instruments. Past instruments entries that are completed cannot be edited.',
  timedEntryPtpsAlwaysAllowed: 'Participants are always allowed to finish incomplete instruments entries.',
  timedEntryPtpsAlwaysAllowedSub: 'Always allowed to complete past instruments entries',
  timedEntryPtpsAlwaysAllowedSubSuffix: 'to complete past instruments entries',
  timedEntryForPtpsToEditDiary: 'to complete incomplete diary entries after deployment.',
  timedEntryInfoTipDiary:
    'Participants will only be allowed to edit incomplete diaries. Past diary entries that are completed cannot be edited.',
  timedEntryPtpsAlwaysAllowedDiary: 'Participants are always allowed to finish incomplete diary entries.',
  timedEntryPtpsAlwaysAllowedSubDiary: 'Always allowed to complete past diary entries',
  timedEntryPtpsAlwaysAllowedSubSuffixDiary: 'to complete past diary entries',
  timeoutWindowSub: 'Timeout window for the instrument in minutes',
  to: 'to',
  toneOfVoice: 'Tone of voice',
  toneOfVoiceBody: 'When a participant completes an instrument, what tone should the app respond with?',
  training: 'Training',
  trainingConfigBody:
    'Access curated DCH Admin Panel training materials anytime on your dashboard. These materials will be available for users before the study-go-live for optimal onboarding experience.',
  trainingDocuments: 'Training Documents',
  trainingDocumentThumbnail: 'Document Display Thumbnail (Optional)',
  trainingUploadSuccess: 'Training successfully uploaded',
  trainingVideos: 'Training Videos',
  trainingVideoThumbnail: 'Video Display Thumbnail (Optional)',
  triggerAnotherItem: 'Trigger another item',
  unordered: 'Unordered',
  unorderedInstrumentsBody: 'The participant will be able to choose which instrument to complete first',
  unorderedInstrumentsHeading: 'Unordered Instruments',
  uploadDocument: 'Upload Document',
  uploading: 'Uploading',
  uploadNewTrainingdocument: 'Upload New Training Document',
  uploadNewTrainingvideo: 'Upload New Training Video',
  uploadVideo: 'Upload Video',
  userName: 'User Name',
  users: 'Users',
  video: 'Video',
  videoName: 'Video Name',
  virtualVisitClinro: 'Virtual Visit (ClinRo)',
  virtualVisitConfigBody: 'Virtual visit allows ClinRo users to connect with participants through mobile devices.',
  virtualVisits: 'Virtual Visits',
  visibility: 'Visibility',
  visit: 'visit',
  visitAfterMissedPosttext: 'after a visit is marked “missed”',
  visitConfiguration: 'Visit Configuration',
  visitDayErr: 'Visit day cannot be empty',
  visitDayNegErr: 'Visit day cannot be negative',
  visitDelFail: 'Visit failed to delete, please try again',
  visitDelSuccess: 'Visit deleted successfully',
  visitDisplayName: 'Visit Display Name for Participant',
  visitDisplayNameInApp: 'Visit name displayed in App',
  visitItemConfirmQuestion: 'Should this item deploy when the visit’s status is <bold>not updated</bold>?',
  visitItemConfirmQuestionNo:
    'No, do not deploy this item unless the visit’s occurrence is confirmed by a coordinator.',
  visitItemConfirmQuestionTooltip:
    'When “No” is selected, this item will not deploy to the participant unless a study team member confirms the occurrence of the visit tied to this item. When “Yes” is selected, this item will deploy to the participant even without a study team member’s confirmation of the visit’s occurrence.',
  visitItemConfirmQuestionYes:
    'Yes, deploy this item even when the visit’s occurrence is not confirmed by a coordinator.',
  visitItemEngagement: 'Engagement item triggered by visit',
  visitItemEngagementBody:
    'An item may be deployed relative to a visit. An item deployed with this option is available in the Carousel along with other study instruments and expiration can be configured below. Participants will receive in-app gems or rewards for completing their engagement item.',
  visitItemMissedVisit: 'Missed visit follow-up',
  visitItemMissedVisitBody: `A missed visit follow-up is an item that will deploy when a coordinator indicates that a participant has missed a visit. A visit can be marked "missed" on the Visit Scheduling page or the Participant's detail drawer.`,
  visitItemVisitSummary: 'Visit summary',
  visitItemVisitSummaryBody: `A visit summary provides context about a participant's visit before or after the visit occurs. An item deployed with this option is persistently available under the "Visits" tab in the participant app, and only one Visit Summary may be created per visit. Participants will not receive in-app gems or rewards for reviewing a pre-visit summary.`,
  visitMissed: 'No, this visit was missed.',
  visitName: 'Visit Name',
  visitNameErr: 'Visit name cannot be empty',
  visitOccurred: 'Yes, this visit occurred.',
  visitOnDayOf: 'On the day of the visit',
  visitOnDayOfMissed: 'Immediately when a visit is marked “missed” by the coordinator.',
  visitSameDayConfirmation: 'Please click confirm if this is correct. Click cancel to make changes to visit schedule.',
  visitSameDayPrompt: 'There are more than one visit scheduled on a same day of the study.',
  visitSavedFail: 'Visit failed to save, please try again',
  visitSavedSuccess: 'Visit saved successfully',
  visitSchedule: 'Visit Schedule',
  visitScheduleVistNameQuestion: "What is the visit's name?",
  visitScheduleJoinsStudy: 'joins study',
  visitScheduleLastVisit: 'last visit',
  visitScheduleCompletesVisit: 'completes visit...',
  visitScheduleChangeVisitDate: 'Change visit date to',
  visitScheduleCreateNewAdhoc: 'Create new adhoc visit',
  visitScheduleCreateNewVisit: 'Create new visit',
  visitScheduleCreateNewVisitSchedule: 'Create new visit schedule',
  visitScheduleCurrentlyScheduledDate: 'Currently scheduled date',
  visitScheduleEditAdhoc: 'Edit adhoc visit',
  visitScheduleEditVisit: 'Edit visit',
  visitScheduleEditVisitSchedule: 'Edit visit schedule',
  visitScheduleNoChange: 'No change',
  visitScheduleNoResultsPrevVisits: 'No Matching Schedule Previous Visits',
  visitScheduleProtocolDeviation:
    'This change would cause a protocol deviation. Please confirm to make this change or cancel this request to select another day.',
  visitSchedulePushNotificationNo:
    'Participant will receive a push notification about their updated visit schedule. Subsequent visits will not be affected by this change.',
  visitSchedulePushNotificationYes: 'Participant will receive a push notification about their updated visit schedule.',
  visitSchedulePushOut: 'Push out subsequent visits?',
  visitScheduleReschedule: 'Reschedule',
  visitScheduleRescheduleAVisit: 'Reschedule a visit',
  visitScheduleSavedSuccess: 'Visit schedule saved successfully',
  visitScheduleSaveVisit: 'Save Visit',
  visitScheduleScheduleNewVisit: 'Schedule New Visit',
  visitScheduleSelectedDayTolerance: 'Selected day is outside of visit window tolerance',
  visitScheduleUpdateStatus: 'Update status',
  visitScheduleUpdatingHeading: 'Updating visit schedule?',
  visitScheduleUpdatingBody:
    'We will also reschedule the deployment of visit related instruments and visit summary according to this change.',
  visitScheduleVisitsInSchedule: 'Visits in selected visit schedule',
  visitScheduleWhenShould: 'When should this visit occur?',
  visitScheduleWhoWillBeReq: 'Who will be required to complete this visit?',
  visitSupport: 'Visit Support',
  visitSupportConfigBody:
    'Visit support helps to manage pre-visit materials such as visit reminders and participant engagement tools.',
  visitTemplateName: 'Visit Template Name',
  visitWindowErr: 'Visit window cannot be empty',
  visitWindowNegErr: 'Visit window cannot be negative',
  visitWindowNaNErr: 'Visit window must be an Integer number',
  visitWindowInfo:
    'Scheduling a visit to another date would be within the protocol visit window. When rescheduling a visit outside of the approved Visit Schedule window may require a protocol deviation',
  visitWindow: 'visit tolerance window',
  whatTypeArticle: 'What type of deployment item is this?',
  whenAllComplete: 'When all complete',
  whenShouldDeployToPtp: 'When should this item <bold>deploy</bold> to the participant? ',
  whichVisitTrigger: "Which visit should trigger this instrument's deployment?",
  yes: 'Yes',
  site: 'Site',
  reassignToLeafSite: 'Reassign the participant to a leaf site',
  selectSite: 'Select site...',
  selectLocale: 'Select locale...',
  selectSiteFirst: 'Please select site first',
  consent_builder_title: 'Consent Builder',
  consent_builder_description:
    "Consent Builder allows admins to draft a consent form on the admin portal. You'll have the ability to copy and paste content, format text, configure which signatures are needed, and add comprehension questions.",
  pdf_upload_title: 'PDF Upload',
  pdf_upload_description:
    'PDF Upload allows admins to upload the content of the consent form in PDF format. Please note PDF cannot be edited in any way once it is uploaded.',
  fileName: 'File name',
  previewPdfFile: 'Preview PDF File',
  pleaseConfirmAmountOfTime: 'Please confirm the amount of time to count for this question.',
  count: 'Count',
  minutes: 'minutes',
  seconds: 'seconds',
  timer_countdown: 'Count down from given time frame',
  timer_countdown_tooltip:
    'The timer will count up from zero by default. When this toggle is on, the timer will count down to zero',
  timer_audible_alert: 'Add audible alert when the timer ends',
  timer_disable_pause: 'Disable pause, resume',
  timer_disable_pause_tooltip:
    'Participants can pause and resume the timer by default. When this toggle is enabled, Participants will only have the option to reset the timer. ',
  addNewTrigger: 'Add new trigger',
  allInstrumentsOption: 'Deploy this instrument when <bold>all</bold> of the conditions are met',
  announcements: 'Announcements',
  atLeastOneInstrumentOption: 'Deploy this instrument when <bold>at least one</bold> of the conditions is met',
  completedSentToSystem: 'Completed, sent to system',
  consent: 'Consent',
  deployAllConditions: 'Deploy when all of the conditions are met.',
  deployAtLeastOneCondition: 'Deploy when at least one of the conditions is met.',
  diary: 'Diary',
  files: 'Files',
  instToInstHowLongAfter: 'How long after condition(s) completion?',
  instToInstTriggerError: 'Please select another instrument',
  noAnnouncementStatus: 'No announcement status details available',
  noDiarytatus: 'No diary status details available',
  noInstrumentStatus: 'No instrument status details available',
  opened: 'Opened',
  openedAndRead: 'Opened and read',
  optOut: 'Opt Out',
  optOutDescription: 'Allow participants to opt out of answering the question with a numeric value.',
  payments: 'Payments',
  receivedByDevice: 'Received by device',
  seen: 'Seen',
  sentToDevice: 'Sent to device',
  siteName: 'Site Name:',
  status: 'Status',
  statusTimestamp: 'Status Timestamp',
  statusTooltipContent: '“Status Timestamp” displays participant device local time when timestamp was collected.',
  systemReceived: 'System received',
  track: 'Track',
  trackManagement: 'Track Management',
  visible: 'Visible',
  visits: 'Visits',
  instrumentWillDeployImmediately:
    'Note: instrument will deploy immediately if the selected time is earlier than the present time of the participant’s local time zone.',
  thisIdIsAvailable: 'This ID is available',
  thisIdIsTaken: 'This ID is already taken.',
  pleaseChooseAnother: 'Please choose another.',
  optional: 'Optional',
  test: 'TEST',
  submit: 'Submit',
  provideExampleImages: 'Provide example images',
  uploadImage: 'upload image',
  created: 'Created',
  study: 'study',
  filterApplied: 'filter applied',
  filtersApplied: 'filters applied',
  filterBy: 'filter by',
  filter: 'Filter',
  studyCapitalized: 'Study',
  clearAll: 'Clear all',
  apply: 'Apply',
  sortBy: 'Sort By:',
  studyCreateDate: 'Study Create Date',
  noResults: 'No Results',
  checkTheSpelling: 'Check the spelling or try a new search.',
  complete: 'Complete',
  awaitingPI: 'Awaiting PI',
  awaitingAdmin: 'Awaiting Admin',
  unopened: 'Unopened',
  declined: 'Declined',
  awaitingIw: 'Awaiting IW',
  awaitingAdditionalSignature: 'Awaiting additional signature',
  awaitingSignatureFromIw: 'Awaiting signature from impartial witness',
  awaitingSignatureFromPI: 'Awaiting signature from from investigator',
  invalidSigneeErr: 'Please select signee supported at the site receiving consent form.',
  invalidSigneePrompt: 'A configured signee is invalid, please either change the signee type or remove the signee.',
  requiredSigneeErr: 'At least one signee needs to be required to sign the consent form.',
  signatureItemDescription:
    'Select who will need to sign this signature box. You can reorder the signees and add additional signees as needed.',
  taskSaveErr: 'Could not save task to database',
  announcementSaveErr: 'Could not save announcement to database',
  displayMode: 'Display mode',
  carouselMode: 'Carousel mode',
  calendarMode: 'Calendar mode',
  carouselModeDescription:
    "Carousel Mode: Instrument is displayed in a rotating carousel format. For instrument featured on carousel, you'll find customizable UI properties to tailor the card's appearance to your liking.",
  calendarModeDescription:
    'Calendar Mode: This view integrates your instrument into calendar, allowing to track it based on specific dates and events. Instruments not displayed on the carousel are conveniently linked to buttons on both the map and calendar views.',
  participantInstrument: 'Participant Instrument',
  clinicianInstrument: 'Clinician Instrument',
  instrument: 'Instrument',
  article: 'Article',
  inReviewScreen: 'In-Review Screen',
  inReviewDescription: 'Display this question and participant response in review screen.',
  survey: 'Survey',
  saveResumeSubtitle: 'Save and resume',
  saveResumeQuestion:
    'Should participants be allowed to save their progress and resume within a configurable time frame?',
  saveResumeOption1: 'No, participants should not be able to save their progress and resume  ',
  saveResumeOption2: 'Yes, participant should be able to save their progress and resume within',
  allowReviewQuestion1: 'Should participants be allowed to review submitted entries within the app?',
  allowReviewOption1: 'Yes, participants can review past entries.',
  allowReviewOption2: 'No, participants should not review past entries.',
  allowReviewQuestion2: 'How many past entries should the participant see?',
  allowReviewAll: 'Show all entries',
  allowReviewPartial: 'Show entries from past',
  allowEditQuestion: 'Should participants be allowed to edit submitted entries within the app?',
  allowEditOption1: 'Yes, participant should be able to edit submitted entries for ',
  allowEditOption2: 'No, participants should not be able to edit submitted entries',
  pleaseConfigureVisualRepresentation: 'Please configure visual representation of food diary entry type',
  carbohydrates: 'Carbohydrates',
  fats: 'Fats',
  fiber: 'Fiber',
  proteins: 'Proteins',
  calories: 'Calories',
  maximumPhotoLimit: 'Maximum Photo Limit',
  flagsmithConfiguration: 'Flagsmith Configuration',
  flags: 'Flags',
  studyConfig: 'Study Config',
  dataInbox: 'Data Inbox',
  noDataInbox: 'No data inbox document is uploaded at this time',
  requestId: 'Request ID:',
  participantIds: 'Participant IDs:',
}

export default STRINGS
