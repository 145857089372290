import React, { useEffect, useState } from 'react'
import { Input } from 'components/UIElements'
import useFlagsmithFeature from 'utils/hooks/useFlagsmithFeature'
import { DEFAULT_INSTRUMENT_ARCHITECTURE_VERSION, FLAGSMITH_FEATURES, INSTRUMENT_TYPE_MAP } from 'utils/constants'
import STRINGS from 'utils/strings'

const InstrumentsViewModalContent = ({
  updateWizardTitle,
  updateWizardDisplayName,
  wizardTitle,
  wizardDisplayName,
  type,
  hasInstrumentUnification,
}) => {
  const [wizardTitleUI, setWizardTitleUI] = useState('')
  const [wizardDisplayNameUI, setWizardDisplayNameUI] = useState('')

  useEffect(() => {
    if (wizardTitle) {
      setWizardTitleUI(wizardTitle)
    }
    if (wizardDisplayName) {
      setWizardDisplayNameUI(wizardDisplayName)
    }
  }, [])

  const isSurvey = type === INSTRUMENT_TYPE_MAP.survey
  const isClinro = type === INSTRUMENT_TYPE_MAP.clinro
  const isDiary = type === INSTRUMENT_TYPE_MAP.diary
  const isArticle = type === INSTRUMENT_TYPE_MAP.article
  let instrumentType
  let header
  if (isSurvey) {
    header = hasInstrumentUnification ? STRINGS.participantInstrument : STRINGS.survey
    instrumentType = hasInstrumentUnification ? STRINGS.participantInstrument : STRINGS.survey
  } else if (isClinro) {
    header = STRINGS.clinicianInstrument
    instrumentType = STRINGS.instrument
  } else if (isDiary) {
    header = hasInstrumentUnification ? STRINGS.participantInstrument : STRINGS.diary
    instrumentType = hasInstrumentUnification ? STRINGS.participantInstrument : STRINGS.diary
  } else if (isArticle) {
    header = STRINGS.article
    instrumentType = STRINGS.article
  }
  return (
    <div>
      {isArticle && <h3>Upload Article</h3>}
      {!isArticle && <h3>{`Create New ${header}`}</h3>}
      <Input
        id='instrument-modal-platform-name'
        label={`${instrumentType} Name`}
        className='survey-title-input'
        autoFocus
        placeholder={hasInstrumentUnification ? 'Internal name' : `Internal ${instrumentType.toLowerCase()} name`}
        onChange={value => {
          updateWizardTitle(value)
          setWizardTitleUI(value)
        }}
        value={wizardTitleUI || ''}
        spellCheck
        charLimit={100}
      />
      <Input
        id='instrument-modal-display-name'
        label='Participant app Display Name'
        className='survey-display-input'
        placeholder={
          hasInstrumentUnification
            ? 'Name displayed to participants in Linkt'
            : `${instrumentType} name displayed to participants in Linkt`
        }
        onChange={value => {
          updateWizardDisplayName(value)
          setWizardDisplayNameUI(value)
        }}
        value={wizardDisplayNameUI || ''}
        spellCheck
        charLimit={100}
      />
    </div>
  )
}

export default InstrumentsViewModalContent
