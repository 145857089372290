import { NOTIFICATION_CONSTS as constants, patchStudy } from 'routes/Studies/routes/CreateStudy/modules/CreateStudy'

const updateAutoNotification = (key, value) => {
  return {
    type: constants.UPDATE_AUTO_NOTIFICATION,
    key,
    value,
  }
}

const saveNotification = (studyID, type, state, redirect) => {
  return (dispatch, getState) => {
    const { study } = getState()
    const { config } = study?.currentStudy
    const studyDiff = {
      auto_notifications: {
        [type]: state,
      },
      config: {
        ...config,
        auto_notification: {
          ...config.auto_notification,
          [type]: state,
        },
      },
    }
    dispatch(patchStudy({ studyId: studyID, studyDiff, diffIsConfig: true }))
  }
}

export const notificationActions = {
  updateAutoNotification,
  saveNotification,
}
