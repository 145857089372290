import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, PromptBar } from 'components/UIElements'
import { getFormattedDateString } from 'utils/time'
import { pluralize } from 'utils/misc'
import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE } from 'utils/constants'

import '../stylesheets/temporarypassword.scss'
import { dateDifferenceByMins } from '../../../../../../../../../utils/time'
import STRINGS from '../../../../../../../../../utils/strings'

const HOURS = 24

/**
 * Transform minutes into hours and minutes
 * @param {Number} time minutes to be parsed in hours and minutes
 * @returns Object { hours, mins, timeout } timeout is the minutes in miliseconds
 */
const parseTime = time => {
  const hours = parseInt(time / MINUTES_IN_HOUR) || 0
  const mins = time - hours * SECONDS_IN_MINUTE || 0
  const timeout = time * SECONDS_IN_MINUTE * 1000 || 0
  return {
    hours,
    mins,
    timeout,
  }
}

const TemporaryPassword = props => {
  const {
    isEmailless,
    lastUpdated,
    title,
    description,
    generateTempPassword,
    participant,
    studyID,
    tempPwdLoading,
  } = props
  const {
    id,
    is_temporary_password,
    last_login,
    temp_password_literal,
    temp_password_valid_until,
    username,
  } = participant

  const [temporaryPwd, setTemporaryPwd] = useState(null)
  const [time, setTime] = useState(null)

  const { hours = 0, mins = 0, timeout = 0 } = useMemo(() => parseTime(time), [time])

  const getTimeDifference = timeStamp => {
    return dateDifferenceByMins(new Date(timeStamp), new Date())
  }

  useEffect(() => {
    if (is_temporary_password) {
      const timeDifference = getTimeDifference(temp_password_valid_until)
      if (timeDifference > 0 || !temp_password_valid_until) {
        setTemporaryPwd(temp_password_literal)
        setTime(timeDifference)
      }
    }
    return () => {
      setTemporaryPwd(null)
      setTime(null)
    }
  }, [is_temporary_password, temp_password_literal, temp_password_valid_until])

  /**
   * effect for cleaning the TemporaryPwd after timeout occur
   */
  useEffect(() => {
    if (!timeout || timeout == 0 || !temporaryPwd || !temp_password_valid_until) return
    const passwordExpired = setTimeout(() => {
      setTemporaryPwd(null)
    }, timeout)

    return () => {
      clearTimeout(passwordExpired)
    }
  }, [timeout, temporaryPwd])

  /**
   * effect for countdown minutes
   */
  useEffect(() => {
    if (!timeout || !temporaryPwd || !temp_password_valid_until) return
    const interval = setInterval(() => {
      setTime(time - 1)
    }, SECONDS_IN_MINUTE * 1000)

    return () => clearInterval(interval)
  }, [timeout, temporaryPwd])

  const getTempPassword = () => {
    const success = response => {
      const { temporary_password, temp_password_valid_until: _temp_password_valid_until } = response
      setTemporaryPwd(temporary_password ?? '')
      const timeDifference = getTimeDifference(_temp_password_valid_until) ?? HOURS * MINUTES_IN_HOUR
      setTime(timeDifference)
    }
    generateTempPassword({ studyID, ptpID: id, successCallback: success })
  }

  const userName = username ?? id

  return (
    <Container className='temporary-password'>
      {(title || description) && (
        <div className='temporary-password-header'>
          {title && <h5>{title}</h5>}
          {description && <p>{description}</p>}
        </div>
      )}
      {lastUpdated && (
        <div className='flexed'>
          <h6 className='no-margin'>{STRINGS.accountLogin}</h6>
          <span>
            {STRINGS.lastLogin}
            {last_login ? getFormattedDateString(last_login) : 'N/A'}
          </span>
        </div>
      )}
      <div>
        <div className='flexed temporary-password-info'>
          <span>{STRINGS.userName}</span>
          <span>{`${userName || ''} (${STRINGS.participantId})`}</span>
        </div>
        {isEmailless && (
          <div className='flexed temporary-password-info'>
            <span>{STRINGS.password}</span>
            <Button
              className={`temporary-password-value${temporaryPwd ? ' temporary-password-generated' : ''}`}
              grey
              loading={tempPwdLoading}
              onClick={() => getTempPassword()}
              disabled={!!temporaryPwd}>
              {temporaryPwd ? (
                <>
                  {STRINGS.temporaryPassword}
                  <b>{temporaryPwd || temp_password_literal}</b>
                </>
              ) : (
                STRINGS.generateTemporaryPassword
              )}
            </Button>
          </div>
        )}
      </div>
      {temporaryPwd && temp_password_valid_until && (
        <div className='flexed'>
          <div />
          <PromptBar className='flexed temporary-password-promt no-margin' status='medium'>
            <p>
              <strong>
                {`${STRINGS.temporaryPasswordNote}${hours ? pluralize(hours, 'hour', 'hours') : ''}${
                  mins ? `, ${pluralize(mins, 'min', 'mins')}` : ''
                }. `}
              </strong>
              {STRINGS.temporaryPasswordCaption}
            </p>
          </PromptBar>
        </div>
      )}
    </Container>
  )
}

TemporaryPassword.defaultProps = {}

TemporaryPassword.propTypes = {
  isEmailless: PropTypes.bool,
  lastUpdated: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  generateTempPassword: PropTypes.func,
  participant: PropTypes.shape({
    id: PropTypes.number,
    is_temporary_password: PropTypes.bool,
    last_login: PropTypes.string,
    temp_password_literal: PropTypes.string,
    temp_password_valid_until: PropTypes.string,
    username: PropTypes.string,
  }),
  studyID: PropTypes.number,
  tempPwdLoading: PropTypes.bool,
}

export default TemporaryPassword
