import moment from 'moment'
import { combineReducers } from 'redux'
import { loadingActions } from 'store/loader'
import { actions as notyActions } from 'layouts/ErrorBox'
import request, { generateNotyMessage } from 'utils/request'
import STRINGS from 'utils/strings'
import { DATE_FORMAT_MAP, DEFAULT_VISIT_START_HOUR, DEFAULT_VISIT_START_MINUTE } from 'utils/constants'
import { pluralize } from 'utils/misc'
import { onRedirectVisit } from '../../VisitTemplatePage/utils/visitRedirection'
import { _fetchParticipants } from '../../../../../../Participants/routes/ParticipantsPage/modules/Participants'
import getPtpAdhocVisitErrors from '../utils/ptpAdhocVisitValidation'
import {
  PARTICIPANT_VISIT_TABLE_COLUMNS_WITH_DEVIATION_WARNING,
  PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP,
} from '../utils/participantVisitConstants'
import { getNewMetadata } from '../../VisitPage/utils/visitDayUtils'
import {
  formatSSVisits,
  CLEAR_PARTICIPANT_VISITS,
  SET_PARTICIPANT_VISITS,
  RESET_PARTICIPANT,
} from '../../../../../../Participants/routes/CreateParticipant/modules/CreateParticipant'
//
// Actions
//
const INIT_PARTICIPANT_VISIT = 'INIT_PARTICIPANT_VISIT'
const SET_ADHOC_VISIT = 'SET_ADHOC_VISIT'
const SET_VISIT_PTP = 'SET_VISIT_PTP'
const FETCH_VISIT_TEMPLATE_PTPS = 'FETCH_VISIT_TEMPLATE_PTPS'
const RESET_PARTICIPANTS = 'RESET_PARTICIPANTS'
const RESET_ADHOC_SCHEDULE = 'RESET_ADHOC_SCHEDULE'
const UPDATE_PARTICIPANT_VISITS = 'UPDATE_PARTICIPANT_VISITS'
const UPDATE_PARTICIPANT_VISIT_NAME = 'UPDATE_PARTICIPANT_VISIT_NAME'
const UPDATE_PARTICIPANT_VISIT_METADATA_KEY = 'UPDATE_PARTICIPANT_VISIT_METADATA_KEY'
const UPDATE_PARTICIPANT_VISIT_METADATA = 'UPDATE_PARTICIPANT_VISIT_METADATA'
const UPDATE_PARTICIPANT_DISPLAY_NAME = 'UPDATE_PARTICIPANT_DISPLAY_NAME'
const UPDATE_ORIGINAL_PARTICIPANT_VISIT = 'UPDATE_ORIGINAL_PARTICIPANT_VISIT'
const UPDATE_ADHOC_SCHEDULE_SELECTION = 'UPDATE_ADHOC_SCHEDULE_SELECTION'
const UPDATE_ADHOC_SCHEDULE = 'UPDATE_ADHOC_SCHEDULE'

// Error Actions
const CLEAR_PARTICIPANT_VISIT_ERRORS = 'CLEAR_PARTICIPANT_VISIT_ERRORS'
const ADD_PARTICIPANT_VISIT_ERRORS = 'ADD_PARTICIPANT_VISIT_ERRORS'

//
// Action Creators
//

const initPage = () => dispatch => {
  return dispatch({
    type: INIT_PARTICIPANT_VISIT,
  })
}

const fetchedVisitTemplatePtps = ptpList => dispatch => {
  return dispatch({
    type: FETCH_VISIT_TEMPLATE_PTPS,
    ptpList,
  })
}

export const setAdhocVisit = (payload, ssvpID) => {
  return {
    type: SET_ADHOC_VISIT,
    payload,
    ssvpID,
  }
}

const setVisitPtp = ({ participantVal, ptpId }) => {
  return {
    type: SET_VISIT_PTP,
    participantVal,
    ptpId,
  }
}

const updatePtpVisits = ({ participantVisit, dayChanged, pushSubsequent, dayDiff, participantVisitsMap }) => {
  return {
    type: UPDATE_PARTICIPANT_VISITS,
    dayChanged,
    dayDiff,
    participantVisit,
    pushSubsequent,
    participantVisitsMap,
  }
}

const updatePtpVisitName = text => {
  return { type: UPDATE_PARTICIPANT_VISIT_NAME, text }
}

const updatePtpVisitMetadataVal = ({ key, value }) => dispatch =>
  dispatch({ type: UPDATE_PARTICIPANT_VISIT_METADATA_KEY, key, value })

const updatePtpVisitMetadata = newKeyVals => dispatch =>
  dispatch({ type: UPDATE_PARTICIPANT_VISIT_METADATA, newKeyVals })

const updatePtpDisplayName = text => {
  return { type: UPDATE_PARTICIPANT_DISPLAY_NAME, text }
}

const updateAdhocScheduleSelection = key => {
  return { type: UPDATE_ADHOC_SCHEDULE_SELECTION, key }
}

const updateAdhocSchedule = ({ key, value, subkey }) => {
  return {
    type: UPDATE_ADHOC_SCHEDULE,
    key,
    subkey,
    value,
  }
}

const resetAdhocSchedule = () => {
  return {
    type: RESET_ADHOC_SCHEDULE,
  }
}

// Error Actions Creators
const addPtpVisitErrors = errors => {
  return { type: ADD_PARTICIPANT_VISIT_ERRORS, errors }
}

const clearPtpVisitErrors = () => {
  return { type: CLEAR_PARTICIPANT_VISIT_ERRORS }
}

//
// Utils
//
const initSpecificDate = () => ({
  isSelected: true,
  value: '',
})
const initDaysBeforeVisit = () => ({
  isSelected: false,
  days: 1,
  visit: {
    key: '',
    text: '',
    visitDatetime: '',
  },
  value: '',
})
const initData = key => (key === 'specificDate' ? initSpecificDate() : initDaysBeforeVisit())
const getAdhocVisitSchedule = () => ({
  specificDate: initData('specificDate'),
  daysBeforeVisit: initData(),
})
export const defaultParticipantVisitsState = {
  visitName: '',
  displayName: '',
  participantDropdownOptions: [],
  selectedParticipant: '',
  checkedPtp: {},
  adhocVisitSchedule: getAdhocVisitSchedule(),
  isAdhoc: false,
  visitId: '',
}

const _parsePtpSelectionList = async ptpsPayload => {
  const ptpIdArr = Object.keys(ptpsPayload)
  const resultArr = []
  ptpIdArr.forEach(ptpId => {
    const ptp = ptpsPayload[ptpId]
    const { id, fname, lname, opt_out } = ptp
    if (!opt_out) {
      const noPii = !fname && !lname
      const name = noPii ? `Participant ${ptpId}` : `${fname} ${lname}`
      resultArr.push({
        key: id,
        text: `${name}, ${id}`,
      })
    }
  })
  return resultArr
}

const formatParticipantVisits = (visits, isAdhocPage) => {
  return [...formatSSVisits(visits.ss_visits_participant), ...visits.ss_visits_participant_adhoc].map(visit => {
    const { is_adhoc, name, participant_visit_metadata, visit_datetime, ssvp_id, visit_day, visit_window } = visit
    const visitName = (participant_visit_metadata?.visit_name || name) ?? `Visit ${ssvp_id}`
    const visitDateTime = moment(visit_datetime)

    if (isAdhocPage) {
      return [
        {
          key: 'visitName',
          value: visitName,
          sortValue: visitName,
          visitId: ssvp_id,
          isAdhoc: is_adhoc,
          className: 'ptp-visit-name',
        },
        { key: 'visitDay', value: visit_day ?? 'N/A' },
        {
          key: 'scheduledDate',
          value: `${visitDateTime.format(DATE_FORMAT_MAP.main)}`,
          sortValue: visitDateTime.valueOf(),
        },
        {
          key: 'status',
          value: moment().isAfter(visitDateTime, 'day') ? 'Passed' : 'Scheduled',
          className: 'ptp-visit-status',
        },
      ]
    }

    const { new_visit_day, original_visit_datetime, original_visit_day } = participant_visit_metadata

    const originalDateTime = original_visit_datetime ? moment(original_visit_datetime) : visitDateTime

    const visitDay = new_visit_day ?? visit_day
    const originalDay = original_visit_day ?? visit_day

    return [
      {
        key: 'visitName',
        value: visitName,
        sortValue: visitName,
        visitId: ssvp_id,
        isAdhoc: is_adhoc,
        className: 'ptp-visit-name',
        visitDateTime,
      },
      {
        key: 'scheduledDay',
        value: `${visitDay !== null ? `Day ${visitDay}` : 'N/A'}, ${visitDateTime.format(DATE_FORMAT_MAP.main)}`,
        sortValue: visitDateTime.valueOf(),
      },
      {
        key: 'originalDay',
        value: `${originalDay !== null ? `Day ${originalDay}` : 'N/A'}, ${originalDateTime.format(
          DATE_FORMAT_MAP.main,
        )}`,
        sortValue: visitDateTime.valueOf(),
        className: 'ptp-visit-highlight',
      },
      {
        key: 'visitWindow',
        value:
          visit_window === 0
            ? 'N/A'
            : `-${visit_window || 0} to +${visit_window || 0} ${pluralize(visit_window, 'day', 'days', false)}`,
        sortValue: visit_window || 0,
      },
      {
        key: 'proposedDay',
        value: STRINGS.visitScheduleNoChange,
        className: 'ptp-visit-highlight',
      },
    ]
  })
}

const formatParticipantVisitsMap = visits => {
  const result = {}
  const { ss_visits_participant, ss_visits_participant_adhoc } = visits
  if (Object.values(ss_visits_participant).length === 0 && ss_visits_participant_adhoc.length === 0) {
    return result
  }
  if (Object.keys(ss_visits_participant).length > 0) {
    Object.values(Object.values(ss_visits_participant)[0]).forEach(ptpVisit => {
      const { ssvp_id } = ptpVisit
      result[ssvp_id] = ptpVisit
    })
  }
  ss_visits_participant_adhoc.forEach(adhocPtpVisit => {
    const { ssvp_id } = adhocPtpVisit
    result[ssvp_id] = adhocPtpVisit
  })

  return result
}

const updateParticipantVisits = ({
  list,
  participantVisit,
  dayChanged,
  pushSubsequent,
  dayDiff,
  participantVisitsMap,
}) => {
  const { visitId, visit_metadata = {}, adhocVisitSchedule } = participantVisit
  const { original_visit_day: mainOriginalVisitDay, new_visit_day, visit_window_deviation } = visit_metadata

  return list.map(item => {
    const visitIdIndex = PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.visitName.index

    const isCurrentVisit = item[visitIdIndex].visitId === visitId

    if (!isCurrentVisit) {
      const { visitId: currentVisitId } = item[visitIdIndex]

      const visitItem = participantVisitsMap[currentVisitId]

      const { visit_day, participant_visit_metadata, visit_datetime, visit_window, is_adhoc } = visitItem

      /**
       * Make no changes to the visit row item if the visit in the list is adhoc
       */
      if (is_adhoc) return item

      const {
        original_visit_day: _currentOriginalVisitDay,
        original_visit_datetime: _currentOriginalDatetime,
      } = participant_visit_metadata
      const currentOriginalVisitDay = _currentOriginalVisitDay ?? visit_day
      const currentOriginalDatetime = _currentOriginalDatetime ?? visit_datetime
      const isSubsequentVisit = currentOriginalVisitDay >= mainOriginalVisitDay

      if (isSubsequentVisit) {
        const updatedItem = item.map(el => {
          const { className = '' } = el

          if (PARTICIPANT_VISIT_TABLE_COLUMNS_WITH_DEVIATION_WARNING.includes(el.key)) {
            const newEl = { ...el }

            const deviated = Math.abs(dayDiff) > visit_window
            /**
             * If the current visit has a visit window deviation as a result of the new date
             * being outside of visit window, we will add a warn class to the className to
             * ensure the cell is highlighted red.
             */

            if (pushSubsequent) {
              if (deviated && dayChanged) {
                newEl.hasDeviation = deviated
                if (!className.includes('warn')) newEl.className = `${className} warn`
              } else if (className.includes('warn')) {
                newEl.className = className.replace(' warn', '')
              }
            } else {
              newEl.hasDeviation = false
              newEl.className = className.replace(' warn', '')
            }
            /**
             * The following condition, updates the value of the table cell to the newly
             * proposed day and date.
             */
            if (el.key === PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.key) {
              if (pushSubsequent && dayChanged) {
                const newDate = moment(currentOriginalDatetime)
                newDate.set({ hour: DEFAULT_VISIT_START_HOUR, minute: DEFAULT_VISIT_START_MINUTE })
                newDate.add(dayDiff, 'days')
                const newDateValue = newDate.format(DATE_FORMAT_MAP.main)
                newEl.value = `Day ${currentOriginalVisitDay + dayDiff}, ${newDateValue}`
                newEl.hasChange = true
                newEl.dayDiff = dayDiff
                newEl.newDateTimeString = newDate.format(DATE_FORMAT_MAP.datePickerWithFullTime)
                if (!newEl.className.includes('bold')) {
                  newEl.className = `${newEl.className} bold`
                }
              } else {
                newEl.value = STRINGS.visitScheduleNoChange
                newEl.hasChange = true
                newEl.dayDiff = 0
                newEl.newDateTimeString = ''
              }
              if (newEl.value === STRINGS.visitScheduleNoChange) {
                newEl.className = newEl.className.replace(' bold', '')
              }
            }
            return newEl
          }

          return el
        })
        return updatedItem
      }
    } else {
      const updatedItem = item.map(el => {
        const { className = '' } = el
        if (!className.includes('current-visit')) {
          const newEl = {
            ...el,
            className: `${className || ''} current-visit`,
          }
          return newEl
        }
        if (PARTICIPANT_VISIT_TABLE_COLUMNS_WITH_DEVIATION_WARNING.includes(el.key)) {
          const newEl = { ...el }
          /**
           * If the current visit has a visit window deviation as a result of the new date
           * being outside of visit window, we will add a warn class to the className to
           * ensure the cell is highlighted red.
           */
          newEl.hasDeviation = visit_window_deviation && dayChanged
          if (visit_window_deviation && dayChanged) {
            if (!className.includes('warn')) newEl.className = `${className} warn`
          } else if (className.includes('warn')) {
            newEl.className = className.replace(' warn', '')
          }
          /**
           * The following condition, updates the value of the table cell to the newly
           * proposed day and date.
           */
          if (el.key === PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.key) {
            if (dayChanged) {
              newEl.value = `Day ${new_visit_day}, ${moment(
                adhocVisitSchedule?.specificDate?.value,
                DATE_FORMAT_MAP.datePickerWithFullTime,
              ).format(DATE_FORMAT_MAP.main)}`
            } else {
              newEl.value = 'No change'
            }
          }
          return newEl
        }
        return el
      })
      return updatedItem
    }
    return item
  })
}

//
// API functions
//
export function fetchVisitSelectionPtps(studyId, isUpdate = false, ptpId) {
  return async dispatch => {
    dispatch(loadingActions.startLoader(true, 'visitorParticipant'))
    const payload = await dispatch(_fetchParticipants(studyId))

    _parsePtpSelectionList(payload).then(ptpList => {
      dispatch(fetchedVisitTemplatePtps(ptpList))
      if (isUpdate) {
        const participant = ptpList?.filter(ptp => ptp.key == ptpId)[0]
        dispatch(setVisitPtp({ participantVal: participant.text, ptpId }))
      }
      dispatch(loadingActions.stopLoader(true, 'visitorParticipant'))
    })
  }
}

const saveParticipantVisitRequest = ({ body, isUpdate, onSuccess, ptpId, ssvpID, studyID, isAdhoc }) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedules_visits${isUpdate ? `/${ssvpID}` : ''}/participants/${ptpId}`
  return dispatch(
    request({
      url,
      body,
      method: isUpdate ? 'PATCH' : 'POST',
      successMessage: `${isAdhoc ? 'Adhoc' : 'Participant'} Visit saved successfully`,
      failMessage: `${isAdhoc ? 'Adhoc' : 'Participant'} Visit failed to save, please try again`,
      success: onSuccess,
      hasSender: true,
    }),
  )
}

const onDeleteAdhocVisit = ({ ptpID, ssvpID, studyID }) => dispatch => {
  const url = `/control/studies/${studyID}/study_schedule_visits/${ssvpID}/participant/${ptpID}`
  const success = resJSON => {
    dispatch(onRedirectVisit({ resJSON, studyID }))
  }
  return dispatch(
    request({
      url,
      method: 'DELETE',
      successMessage: STRINGS.adhocVisitDel,
      success,
    }),
  )
}

//
// Util Functions
//

const generateUpdatedSubsequentVisitsForSave = ({ participantVisits, participantVisitsMap }) => {
  const result = []
  participantVisits.forEach(visit => {
    const metadataInfo = visit[PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.proposedDay.index]
    const { hasChange, dayDiff, newDateTimeString } = metadataInfo
    if (hasChange) {
      const visitInfo = visit[PARTICIPANT_VISIT_TABLE_KEY_NAME_MAP.visitName.index]
      const { visitId } = visitInfo
      const currentVisit = participantVisitsMap[visitId]
      const { participant_visit_metadata } = currentVisit
      const subsequentVisit = {}
      subsequentVisit.visit_id = visitId
      subsequentVisit.participant_visit_metadata = {
        ...participant_visit_metadata,
        ...getNewMetadata({
          subsequentVisit: currentVisit,
          dayDifference: dayDiff,
        }),
      }
      subsequentVisit.visit_datetime = newDateTimeString
      result.push(subsequentVisit)
    }
  })
  return result
}
const saveParticipantVisit = ({
  participantVisit,
  studyID,
  isUpdate,
  ssvpID,
  participantVisits,
  participantVisitsMap,
  pushSubsequent,
}) => dispatch => {
  const { adhocVisitSchedule, checkedPtp, displayName, visitName, visit_metadata = {}, isAdhoc } = participantVisit
  const visit_datetime = Object.keys(adhocVisitSchedule)
    .filter(key => adhocVisitSchedule[key].isSelected)
    .map(key => adhocVisitSchedule[key].value)[0]
  const defaultPtpAdhocVisitJSON = {
    is_adhoc: isAdhoc || !isUpdate,
    name: visitName,
    participant_visit_metadata: {
      ...visit_metadata,
      display_name: displayName,
      visit_name: visitName,
    },
    visit_datetime,
  }

  if (pushSubsequent) {
    const subsequentVisitsData = generateUpdatedSubsequentVisitsForSave({ participantVisits, participantVisitsMap })
    defaultPtpAdhocVisitJSON.subsequent = subsequentVisitsData
  }
  const ptpId = Object.keys(checkedPtp)[0]

  const onSuccess = resJSON => {
    dispatch({
      type: UPDATE_ORIGINAL_PARTICIPANT_VISIT,
      json: participantVisit,
    })
    return Promise.resolve(resJSON)
  }

  const body = JSON.stringify(defaultPtpAdhocVisitJSON)

  return dispatch(saveParticipantVisitRequest({ body, isUpdate, onSuccess, ptpId, ssvpID, studyID, isAdhoc }))
}

const onSaveParticipantVisit = ({ isUpdate, queryParams, redirectPath, ssvpID, studyID, pushSubsequent }) => (
  dispatch,
  getState,
) => {
  const { participantVisitReducer, subroute } = getState()
  const { participantVisit } = participantVisitReducer

  const errors = getPtpAdhocVisitErrors(participantVisit)
  const valid = !(Object.values(errors).filter(val => val).length >= 1)
  if (valid) {
    dispatch(clearPtpVisitErrors())
    dispatch(saveParticipantVisit({ studyID, ...participantVisitReducer, isUpdate, ssvpID, pushSubsequent })).then(
      resJSON => {
        dispatch(onRedirectVisit({ resJSON, redirectPath, studyID, queryParams, visitScheduleSubroute: subroute }))
      },
    )
  } else {
    dispatch(addPtpVisitErrors(errors))
    dispatch(
      notyActions.showError({
        text: generateNotyMessage('Please check errors before proceeding.', false),
      }),
    )
  }
}

const formatAdhocVisit = (visits, ssvpID) => {
  const participantVisits = Object.values(visits?.ss_visits_participant).reduce(
    (acc, item) => ({ ...acc, ...item }),
    {},
  )
  const participantVisitId = Object.keys(participantVisits).filter(key => participantVisits[key].ssvp_id === ssvpID)[0]
  const visit =
    visits?.ss_visits_participant_adhoc.filter(_visit => _visit.ssvp_id === ssvpID)[0] ||
    participantVisits[participantVisitId]

  if (visit) {
    const { name, participant_visit_metadata, visit_datetime, is_adhoc, ssvp_id, visit_day, visit_window } = visit
    const visitTime = `${moment.utc(visit_datetime).format(DATE_FORMAT_MAP.datePickerWithFullTime)}`
    const { display_name, visit_name } = participant_visit_metadata || {}
    const fields = {
      visitName: visit_name || name,
      displayName: display_name,
      adhocVisitSchedule: getAdhocVisitSchedule(),
      visit_metadata: participant_visit_metadata,
      isAdhoc: is_adhoc,
      visitId: ssvp_id,
      visitDay: visit_day,
      visitWindow: visit_window,
      visitDatetime: visit_datetime,
    }
    fields.adhocVisitSchedule.specificDate.value = visitTime

    return fields
  }
  return {}
}

//
// Reducers
//

export const participantVisit = (state = defaultParticipantVisitsState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case INIT_PARTICIPANT_VISIT:
      return { ...defaultParticipantVisitsState, adhocVisitSchedule: getAdhocVisitSchedule() }
    case SET_ADHOC_VISIT:
      return { ...newState, ...formatAdhocVisit(action.payload, action.ssvpID) }
    case SET_VISIT_PTP:
      return {
        ...newState,
        selectedParticipant: action.participantVal,
        checkedPtp: !action.ptpId ? {} : { [action.ptpId]: true },
      }
    case FETCH_VISIT_TEMPLATE_PTPS:
      return {
        ...newState,
        participantDropdownOptions: action.ptpList,
      }
    case RESET_PARTICIPANTS:
      return {
        ...newState,
        participantDropdownOptions: [],
      }
    case RESET_ADHOC_SCHEDULE:
      return { ...newState, adhocVisitSchedule: getAdhocVisitSchedule() }
    case UPDATE_PARTICIPANT_VISIT_NAME:
      return { ...newState, visitName: action.text }
    case UPDATE_PARTICIPANT_VISIT_METADATA_KEY:
      return { ...newState, visit_metadata: { ...newState.visit_metadata, [action.key]: action.value } }
    case UPDATE_PARTICIPANT_VISIT_METADATA:
      return { ...newState, visit_metadata: { ...newState.visit_metadata, ...action.newKeyVals } }
    case UPDATE_PARTICIPANT_DISPLAY_NAME:
      return { ...newState, displayName: action.text }
    case UPDATE_ADHOC_SCHEDULE_SELECTION: {
      const newAdhocVisitSchedule = {}
      Object.keys(newState.adhocVisitSchedule).map(adhocScheduleKey => {
        newAdhocVisitSchedule[adhocScheduleKey] = {
          ...initData(adhocScheduleKey),
          isSelected: adhocScheduleKey === action.key,
        }
      })
      return { ...newState, adhocVisitSchedule: newAdhocVisitSchedule }
    }
    case UPDATE_ADHOC_SCHEDULE: {
      const _adhocVisitSchedule = newState.adhocVisitSchedule
      if (action.subkey) {
        _adhocVisitSchedule[action.key][action.subkey] = action.value
      } else {
        _adhocVisitSchedule[action.key].value = action.value
      }
      return { ...newState, adhocVisitSchedule: _adhocVisitSchedule }
    }
    default:
      return state
  }
}

const participantVisitErrors = (state = {}, action) => {
  switch (action.type) {
    case INIT_PARTICIPANT_VISIT:
    case CLEAR_PARTICIPANT_VISIT_ERRORS:
      return {}
    case ADD_PARTICIPANT_VISIT_ERRORS:
      return action.errors
    default:
      return state
  }
}

const participantVisitsMap = (state = {}, action) => {
  switch (action.type) {
    case SET_PARTICIPANT_VISITS:
      return formatParticipantVisitsMap(action.visits)
    case CLEAR_PARTICIPANT_VISITS:
    case RESET_PARTICIPANT:
      return {}
    default:
      return state
  }
}

const participantVisits = (state = [], action) => {
  switch (action.type) {
    case SET_PARTICIPANT_VISITS:
      return formatParticipantVisits(action.visits, action.isAdhocPage)
    case CLEAR_PARTICIPANT_VISITS:
    case RESET_PARTICIPANT:
      return []
    case UPDATE_PARTICIPANT_VISITS: {
      const { dayChanged, pushSubsequent, dayDiff, participantVisitsMap: _participantVisitsMap } = action
      return updateParticipantVisits({
        list: state,
        participantVisit: action.participantVisit,
        dayChanged,
        pushSubsequent,
        dayDiff,
        participantVisitsMap: _participantVisitsMap,
      })
    }
    default:
      return state
  }
}

export default combineReducers({
  participantVisit,
  participantVisitErrors,
  participantVisits,
  participantVisitsMap,
})

export const participantVisitActions = {
  addPtpVisitErrors,
  clearPtpVisitErrors,
  initPage,
  onDeleteAdhocVisit,
  onSaveParticipantVisit,
  resetAdhocSchedule,
  setVisitPtp,
  updatePtpVisits,
  updatePtpVisitName,
  updatePtpVisitMetadata,
  updatePtpVisitMetadataVal,
  updatePtpDisplayName,
  updateAdhocScheduleSelection,
  updateAdhocSchedule,
}
